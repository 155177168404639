<template>
<div class="redTree" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.background.pictureUrl})`}">
  <div class="title"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.title.pictureUrl})`}"></div>
  <div class="goldCoin"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.goldCoin.pictureUrl})`}"></div>
  <div class="btn btn1"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.rulesBtn.pictureUrl})`}"></div>
  <div class="btn btn2" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.myPrize.pictureUrl})`}"></div>
  <div class="pitLarge"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.pitLarge.pictureUrl})`}"></div>
  <div class="treeLarge"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.treeLarge.pictureUrl})`}"></div>
  <div class="start"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redTree.start.pictureUrl})`}"></div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  }
}
</script>

<style lang="scss" scoped>
.redTree{
  height: 682px;
  background-size: 100% 100%;
  position: relative;
  *{
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
  }
  .title{
    width: 200px;
    height: 100px;
    right: 0px;
    left: 0;
    margin: auto;
    top: 50px;
  }
  .goldCoin{
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .btn{
    width: 50px;
    height: 80px;
    position: absolute;
    right: 20px;
    &.btn1{
      top: 60px;
    }
    &.btn2{
      top: 130px;
    }
  }
  .pitLarge{
    width: 150px;
    height: 70px;
    left: 0;
    right: 0;
    top: 430px;
    margin: auto;
  }
  .treeLarge{
    width: 250px;
    height: 550px;
    left: 70px;
    top: 160px;
  }
  .start{
    width: 200px;
    height: 60px;
    left: 0;
    right: 0;
    top: 520px;
    margin: auto;
  }
}
</style>
