<template>
  <!-- 营销活动编辑 -->
  <div class="activity-edit">
    <header>
      <edit-head
        :editField="false"
        saveDataType="market"
        ref="editHead"
        :activeName.sync="activeName"
      ></edit-head>
    </header>
    <div class="content">
      <!-- 左侧导航 -->
      <div class="active-nav">
        <el-scrollbar style="height: 100%">
          <ul>
            <li
              v-for="(item, index) in navList"
              :key="index"
              :class="{ active: currentNav == index }"
              @click="checkoutNav(item, index)"
            >
              {{ item.label }}
            </li>
          </ul>
        </el-scrollbar>
      </div>
      <!-- 中间内容 -->
      <div class="nav-show">
        <home-page ref="homePage" v-if="currentPage == 'homePage'"></home-page>
        <prize-details
          ref="prizeDetails"
          v-if="currentPage == 'prizeDetails'"
        ></prize-details>
        <prize
          ref="prize"
          v-if="
            currentPage == 'prizePage' ||
            currentPage == 'notWinning' ||
            currentPage == 'prizeAfter'
          "
          :currentPage="currentPage"
        ></prize>
        <img
          src="../../assets/img/marketing/u16510.png"
          alt=""
          v-if="currentPage == 'shareResults'"
        />
      </div>
      <!-- 右侧配置区域 -->
      <div class="setting">
        <el-scrollbar style="height: 100%">
          <el-tabs v-model="activeName">
            <el-tab-pane label="基础设置" name="first">
              <basic-setting ref="basicSetting"></basic-setting>
            </el-tab-pane>
            <el-tab-pane label="抽奖配置" name="second">
              <send-way ref="sendWay"></send-way>
            </el-tab-pane>
            <el-tab-pane label="奖项设置" name="third">
              <award-setting :templeType="templeType" ref="awardSetting"></award-setting>
            </el-tab-pane>
            <el-tab-pane label="高级设置" name="fourth">
              <senior-setting ref="seniorSetting"></senior-setting>
            </el-tab-pane>
            <el-tab-pane
              label="个性化设置"
              name="fifth"
              v-if="templeType == 5 || templeType == 9 || templeType == 10"
            >
              <personalized-Setting
                :templeType="templeType"
                ref="personalizedSetting"
              ></personalized-Setting>
            </el-tab-pane>
            <el-tab-pane label="监控与阀值" name="sixth"
                         v-if="templeType == 10 || systemCode == 'tai_woo_wxys'">
              <Monitor-Setting ref="monitorSetting"></Monitor-Setting>
            </el-tab-pane>
            <el-tab-pane label="添加海报图片" name="seventh" v-if="templeType == 15">
              <Upload-Report ref="uploadReport"></Upload-Report>
            </el-tab-pane>
          </el-tabs>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import EditHead from '@/components/EditHead.vue'
import BasicSetting from './BasicSetting.vue'
import SendWay from './SendWay.vue'
import AwardSetting from './AwardSetting.vue'
import SeniorSetting from './SeniorSetting.vue'
import PersonalizedSetting from './PersonalizedSetting.vue'
import MonitorSetting from './monitorSetting'
import UploadReport from './uploadReport'
import HomePage from './ActivePages/HomePage.vue'
import PrizeDetails from './ActivePages/PrizeDetails.vue'
import Prize from './ActivePages/Prize.vue'

import { getIdTemplateData, getIdActiveData, getIdBuyTemplate, getCardTheme } from '@/api/market'
import { mapState, mapMutations } from 'vuex'
import { TextToCode } from 'element-china-area-data'
import { getLocal } from '@/lib/local'
export default {
  components: {
    EditHead,
    BasicSetting,
    SendWay,
    AwardSetting,
    SeniorSetting,
    HomePage,
    PrizeDetails,
    Prize,
    PersonalizedSetting,
    MonitorSetting,
    UploadReport
  },
  data () {
    return {
      navList: [
        {
          label: '首页',
          name: 'homePage'
        },
        {
          label: '奖品详情',
          name: 'prizeDetails'
        },
        {
          label: '中奖页面',
          name: 'prizePage'
        },
        {
          label: '没中奖页',
          name: 'notWinning'
        },
        {
          label: '奖品派完页',
          name: 'prizeAfter'
        },
        {
          label: '分享效果',
          name: 'shareResults'
        }
      ],
      activeName: this.$route.query.level ? 'third' : 'first',
      currentPage: 'homePage',
      currentNav: 0,
      id: this.$route.query.id || '',
      activityId: this.$route.query.activityId || '',
      isShow: false,
      isBuy: this.$route.query.isBuy || ''
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData', 'systemCode']),
    templeType () {
      // this.marketingActivityData.activity.templeType && JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType !== ''
      return this.marketingActivityData.activity.templeType
    }
  },
  mounted () {
    this.resetData()
    if (!!getLocal() && sessionStorage.getItem('data')) {
      this.editActiveInit(JSON.parse(sessionStorage.getItem('data')))
      this.$refs.awardSetting.awardList = []
      for (let i = 0; i < JSON.parse(sessionStorage.getItem('data')).prize.length; i++) {
        this.$refs.awardSetting.awardList.push(this.$refs.awardSetting.awardList.length)
      }
    } else if (this.id) {
      this.init()
    }
    if (this.activityId) {
      this.activeInit()
      this.$refs.editHead.isPreview = false
    }
    if (this.$route.query.templeName) {
      this.marketingActivityData.activity.templeName = this.$route.query.templeName
    }
  },
  beforeDestroy () {
    this.$bus.$off('getData')
    this.$bus.$off('getType')
  },
  created () {
    this.$bus.$on('editActive', (activityId) => {
      this.activityId = activityId
      this.activeInit()
    })
  },
  methods: {
    ...mapMutations('market', ['setTemplate', 'setDefaultSharePictureUrl', 'setDefaultShareContent', 'editActiveInit',
      'resetData', 'editActiveInit', 'setShare', 'setMonitor', 'setThemeList', 'setShare', 'setDefaultLimit', 'setQuantityDay']),
    // 切换左侧tab
    checkoutNav (item, index) {
      this.currentPage = item.name
      this.currentNav = index
    },
    queryThemeList () { // 查询集卡主题
      getCardTheme().then(res => {
        if (res.code === 200) {
          this.setThemeList(res.data)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    // 获取模板编辑数据
    async init () {
      let res
      if (this.isBuy) {
        res = await getIdBuyTemplate({ id: this.id })
      } else {
        res = await getIdTemplateData({ id: this.id })
      }
      if (res.code == 200) {
        this.isShow = true
        const templateData = {
          templeId: res.data.id,
          templePage: res.data.page,
          templeType: res.data.type,
          name: JSON.parse(res.data.page).activityBase.name,
          description: JSON.parse(res.data.page).activityBase.description,
        }
        if (res.data.type == 16) {
          this.queryThemeList()
        }
        if ([19, 23, 24].includes(res.data.type)) {
          this.$refs.sendWay.userChance = 1
          this.setDefaultLimit()
        }
        this.setTemplate(templateData)
        // this.$refs.awardSetting.init()
        this.setDefaultSharePictureUrl(JSON.parse(res.data.page).activityBase.cover)
        this.setDefaultShareContent(templateData.description)
        if (res.data.type === 10) {
          this.$refs.awardSetting.awardList = []
          this.$refs.awardSetting.oldAwardNum = Object.keys(JSON.parse(res.data.page).drawPage.defaultPrize).length - 1
          for (let i = 0; i < Object.keys(JSON.parse(res.data.page).drawPage.defaultPrize).length - 1; i++) {
            this.$refs.awardSetting.awardList.push(this.$refs.awardSetting.awardList.length)
          }
        }
        if (res.data.type === 19) {
          this.$refs.awardSetting.awardList = [0]
        }
        this.$refs.awardSetting.init()
        if (res.data.type == 15) {
          this.$nextTick(() => {
            if(this.$refs.uploadReport){
              this.$refs.uploadReport.init()
            }
          })
        }
        if (res.data.type === 9 || res.data.type === 15) {
          this.setShare(true)
        }
        // this.setActivityMapCodeParamDTO([
        //   {
        //     code: '100000',
        //     name: "中华人民共和国"
        //   }
        // ])
      } else {
        this.$confirm(`${res.msg}请重新选择模板！`, '提示', {
          confirmButtonText: '确定',
          type: 'warning',
          showCancelButton: false,
          closeOnPressEscape: false,
          showClose: false,
          closeOnClickModal: false
        }).then(() => {
          this.$router.push('/MarketingActivities')
        })
      }
    },
    // 获取活动编辑数据
    async activeInit () {
      const res = await getIdActiveData({ id: this.activityId })
      if (res.code == 200) {
        this.isShow = true
        const activeData = {}
        activeData.activity = res.data.activity
        activeData.limit = res.data.limit
        activeData.map = res.data.map
        activeData.map.mapJson = JSON.parse(res.data.map.mapJson)
        activeData.prize = res.data.prize
        activeData.posterDTOList = res.data.posterDTOS
        activeData.activity.functionButton = JSON.parse(res.data.activity.functionButton)
        activeData.activity.shareData = JSON.parse(res.data.activity.shareData)
        activeData.activity.brandExposure = JSON.parse(res.data.activity.brandExposure)
        if (res.data.activity.templeType == 16) {
          this.queryThemeList()
        }
        if ([19, 23, 24].includes(res.data.activity.templeType)) {
          this.$refs.sendWay.userChance = 1
          this.setDefaultLimit()
        }
        // fix: 修复旧活动无法设置 2022-04-22
        const customJSON = JSON.parse(res.data.activity.custom)
        const newCustom = {
          productId: '',
          productName: '',
          thirdSystemCode: ''
        }
        activeData.activity.custom = Object.assign(newCustom, customJSON)

        //  --

        if (activeData.map.mapJson[0].code == 100000) {
          this.$refs.sendWay.region = 0
        } else {
          this.$refs.sendWay.region = 1
          const selectedOptions = []
          activeData.map.mapJson.forEach(item => {
            const itemList = []
            itemList.push(TextToCode[item.name.split(',')[0]].code)
            itemList.push(TextToCode[item.name.split(',')[0]][item.name.split(',')[1]].code)
            if (TextToCode[item.name.split(',')[0]][item.name.split(',')[1]][item.name.split(',')[2]]) {
              itemList.push(TextToCode[item.name.split(',')[0]][item.name.split(',')[1]][item.name.split(',')[2]].code)
            }
            selectedOptions.push(itemList)
          })
          // console.log('selectedOptions', selectedOptions)
          this.$refs.sendWay.selectedOptions = selectedOptions
        }
        this.$refs.sendWay.userDayWin = activeData.limit.userDayWin == -1 ? '' : activeData.limit.userDayWin
        this.$refs.sendWay.totalChance = activeData.limit.totalChance == -1 ? '' : activeData.limit.totalChance
        this.$refs.sendWay.userJoin = activeData.limit.userJoin == -1 ? '' : activeData.limit.userJoin
        this.$refs.sendWay.userChance = activeData.limit.userChance == -1 ? '' : activeData.limit.userChance
        // // let prizeNum = 3 - activeData.prize.length
        // // if(prizeNum != 0) {
        this.$refs.awardSetting.awardList = []
        if (res.data.activity.templeType == 15) {
          this.$nextTick(() => {
            this.$refs.uploadReport.awardList = []
            this.$refs.uploadReport.setReportImgUrl()
            for (let i = 0; i < res.data.posterDTOS.length; i++) {
              this.$refs.uploadReport.awardList.push(i)
            }
          })
        }
        // for (let i = 0; i < activeData.prize.length; i++) {
        //   this.$refs.awardSetting.awardList.push(this.$refs.awardSetting.awardList.length)

        // }
        activeData.prize.forEach(item => {
          this.$refs.awardSetting.awardList.push(this.$refs.awardSetting.awardList.length)
          item.imgUrl = JSON.parse(item.imgUrl)
        })
        // console.log('activeData', activeData)
        this.editActiveInit(JSON.parse(JSON.stringify(activeData)))
        if (res.data.activity.templeType && this.$route.query.activityId) { // 之前是res.data.activity.templeType == 10
          if (res.data.monitors.length) {
            const monitor = {}
            monitor.monitorMoney = res.data.monitors.some((item) => { // 判断是否有红包奖品
              return item.productType == 'redpacket'
            })
            monitor.amountCountWarn = res.data.monitors[0].amountCountWarn / 100 // 监控总金额
            monitor.amountDayCountWarn = res.data.monitors[0].amountDayCountWarn / 100 // 监控单日总金额
            monitor.monitorPrize = []
            res.data.monitors.forEach((item) => { // 需监控的奖品配置
              const { prizeNumWarn, inventoryWarn, amountPrizeWarn, populationWarn } = item
              if (prizeNumWarn || inventoryWarn || amountPrizeWarn || populationWarn) {
                const obj = {
                  prizeNumWarn: item.prizeNumWarn,
                  inventoryWarn: item.inventoryWarn,
                  amountPrizeWarn: item.amountPrizeWarn / 100,
                  populationWarn: item.populationWarn,
                  noticeEmail: item.noticeEmail,
                  activityId: item.activityId,
                  prizeId: item.prizeId,
                  id: item.id
                }
                monitor.monitorPrize.push(obj)
              }
            })
            monitor.monitorIndex = monitor.monitorPrize.map((item) => { // 需监控的奖品下标
              const { prizeNumWarn, inventoryWarn, amountPrizeWarn, populationWarn } = item
              if (prizeNumWarn || inventoryWarn || amountPrizeWarn || populationWarn) {
                return item.prizeId
              }
            })
            monitor.isOpenMonitor = (monitor.monitorIndex.length || monitor.amountCountWarn || monitor.amountDayCountWarn) ? 1 : 0 // 是否开启监控
            monitor.handle = [1]
            monitor.amountCountAlarm = res.data.monitors[0].amountCountAlarm / 100 // 阀值总金额
            monitor.amountDayCountAlarm = res.data.monitors[0].amountDayCountAlarm / 100 // 阀值单日总金额
            monitor.valvePrize = []
            res.data.monitors.forEach((item) => { // 需控制阀值的奖品配置
              const { prizeNumAlarm, inventoryAlarm, amountPrizeAlarm, populationAlarm } = item
              if (prizeNumAlarm || inventoryAlarm || amountPrizeAlarm || populationAlarm) {
                const obj = {
                  prizeNumAlarm: item.prizeNumAlarm,
                  inventoryAlarm: item.inventoryAlarm,
                  amountPrizeAlarm: item.amountPrizeAlarm / 100,
                  populationAlarm: item.populationAlarm,
                  noticeEmail: item.noticeEmail,
                  activityId: item.activityId,
                  prizeId: item.prizeId,
                  id: item.id
                }
                monitor.valvePrize.push(obj)
              }
            })
            monitor.valveIndex = monitor.valvePrize.map((item) => { // 需监控的奖品下标
              const { prizeNumAlarm, inventoryAlarm, amountPrizeAlarm, populationAlarm } = item
              if (prizeNumAlarm || inventoryAlarm || amountPrizeAlarm || populationAlarm) {
                return item.prizeId
              }
            })
            monitor.isOpenValve = (monitor.valveIndex.length || monitor.amountCountAlarm || monitor.amountDayCountAlarm) ? 1 : 0 // 是否开启阀值处理
            // 邮箱
            monitor.noticeEmail = res.data.monitors[0].noticeEmail // 邮箱
            this.$set(this.marketingActivityData, 'monitor', monitor)
            this.$set(this.marketingActivityData, 'monitors', res.data.monitors)
          } else {
            const monitor = {
              // 监控
              isOpenMonitor: 0, // 是否开启监控
              amountCountWarn: null, // 监控总金额
              amountDayCountWarn: null, // 监控单日总金额
              monitorPrize: [], // 需监控的奖品配置
              monitorIndex: [], // 需监控的奖品下标

              // 阀值
              isOpenValve: 0, // 是否开启阀值处理
              handle: [1], // 触发阀值处理方式
              amountCountAlarm: null, // 阀值总金额
              amountDayCountAlarm: null, // 阀值单日总金额
              valvePrize: [], // 需控制阀值的奖品配置
              valveIndex: [], // 需控制阀值的奖品下标
              // 邮箱
              noticeEmail: '' // 邮箱
            }
            this.$set(this.marketingActivityData, 'monitor', monitor)
          }
        }
        this.$refs.seniorSetting.setImgShare()
        this.$refs.awardSetting.setWeChatUrl()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-edit {
  height: 100%;
  .content {
    position: absolute;
    top: 64px;
    bottom: 0;
    display: flex;
    // 左侧导航
    .active-nav {
      width: 120px;
      background: #1d1e23;
      min-height: 800px;
      ul {
        li {
          width: 72px;
          height: 55px;
          line-height: 72px;
          text-align: center;
          border-bottom: 1px solid #2b2c2f;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #7e838e;
          margin-left: 24px;
          cursor: pointer;
        }
        .active {
          color: #ffffff;
        }
      }
    }
    // 中间展示区域
    .nav-show {
      display: flex;
      justify-content: center;
      width: 680px;
      background: #f4f4fb;
      padding: 50px 0;
      min-height: 800px;
      img {
        width: 375px;
        height: 667px;
      }
    }
    // 右侧配置区域
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .setting {
      width: 1120px;
      min-height: 800px;
      background-color: #1d1e23;
      /deep/ .el-tabs__header {
        margin: 0 0 42px;
      }
      /deep/ .el-tabs__item {
        height: 57px;
        line-height: 57px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #8b909a;
        padding: 0;
        padding-left: 65px;
      }
      /deep/ .el-tabs__nav-wrap::after {
        height: 1px;
        background: #2b2c2f;
      }
      /deep/ .el-tabs__active-bar {
        height: 2px;
        background: #ffffff;
      }
    }
  }
}
</style>
