var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "runForward",
      style: {
        backgroundImage: "url(" + _vm.runForward.background.pictureUrl + ")"
      }
    },
    [
      _c("div", { staticClass: "runForward-title" }, [
        _c("img", { attrs: { src: _vm.runForward.title.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "start" }, [
        _c("img", { attrs: { src: _vm.runForward.start.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "rules" }, [
        _c("img", {
          attrs: { src: _vm.runForward.rulesBtn.pictureUrl, alt: "" }
        })
      ]),
      _c("div", { staticClass: "rules prize" }, [
        _c("img", {
          attrs: { src: _vm.runForward.myPrizeBtn.pictureUrl, alt: "" }
        })
      ]),
      _c(
        "p",
        {
          staticClass: "chance",
          style: { color: _vm.paperWork.dayDrawChance.routineColor }
        },
        [
          _vm._v(" 今天还有 "),
          _c(
            "span",
            { style: { color: _vm.paperWork.dayDrawChance.numberColor } },
            [_vm._v(_vm._s(_vm.marketingActivityData.limit.userDayChance))]
          ),
          _vm._v(" 次抽奖机会 ")
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }