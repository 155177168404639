var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "monitor-setting" },
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.marketingActivityData.monitor,
            disabled: _vm.$route.query.id,
            "label-width": "220px"
          }
        },
        [
          _c("el-form-item", { attrs: { "label-width": "40px" } }, [
            _c("div", { staticClass: "lucky-draw" }, [_vm._v("监控配置")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启监控报警：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.monitor.isOpenMonitor,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.monitor,
                        "isOpenMonitor",
                        $$v
                      )
                    },
                    expression: "marketingActivityData.monitor.isOpenMonitor"
                  }
                },
                _vm._l(_vm.radioList, function(radio, index) {
                  return _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.$route.query.activityStatus == 0,
                        label: radio.value
                      }
                    },
                    [_vm._v(_vm._s(radio.label))]
                  )
                }),
                1
              ),
              _vm.$route.query.activityStatus == 0
                ? _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "danger" }
                    },
                    [_vm._v("活动发布后可配置监控")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.marketingActivityData.monitor.isOpenMonitor
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "报警设置：" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.changeMonitorPrize },
                        model: {
                          value: _vm.marketingActivityData.monitor.monitorIndex,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "monitorIndex",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.monitor.monitorIndex"
                        }
                      },
                      _vm._l(_vm.marketingActivityData.prize, function(
                        prize,
                        index
                      ) {
                        return _c(
                          "el-checkbox",
                          {
                            key: index,
                            attrs: {
                              disabled: !_vm.marketingActivityData.prize[index]
                                .name,
                              title: !_vm.marketingActivityData.prize[index]
                                .name
                                ? "您尚未配置奖项" + _vm.wordList[index]
                                : "",
                              label: prize.id
                            }
                          },
                          [_vm._v(" 奖项" + _vm._s(_vm.wordList[index]) + " ")]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._l(_vm.marketingActivityData.monitor.monitorIndex, function(
                  mI,
                  i
                ) {
                  return [
                    _vm._l(_vm.marketingActivityData.prize, function(
                      item,
                      index
                    ) {
                      return [
                        item.id == mI
                          ? [
                              _vm.marketingActivityData.monitor.monitorPrize
                                .length ==
                              _vm.marketingActivityData.monitor.monitorIndex
                                .length
                                ? _c(
                                    "el-form-item",
                                    {
                                      key: index,
                                      attrs: {
                                        label:
                                          "奖项" +
                                          _vm.wordList[index] +
                                          "各项阀值："
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "inputFLex" },
                                        [
                                          _vm._v(" 已发放奖品数量："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.monitorPrize[i]
                                                  .prizeNumWarn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.monitorPrize[i],
                                                  "prizeNumWarn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.monitorPrize[i].prizeNumWarn"
                                            }
                                          }),
                                          _vm._v(" 库存："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.monitorPrize[i]
                                                  .inventoryWarn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.monitorPrize[i],
                                                  "inventoryWarn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.monitorPrize[i].inventoryWarn"
                                            }
                                          }),
                                          item.productType == "redpacket"
                                            ? [
                                                _vm._v(" 总金额："),
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.marketingActivityData
                                                        .monitor.monitorPrize[i]
                                                        .amountPrizeWarn,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm
                                                          .marketingActivityData
                                                          .monitor.monitorPrize[
                                                          i
                                                        ],
                                                        "amountPrizeWarn",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "marketingActivityData.monitor.monitorPrize[i].amountPrizeWarn"
                                                  }
                                                })
                                              ]
                                            : _vm._e(),
                                          _vm._v(" 中奖人数："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.monitorPrize[i]
                                                  .populationWarn,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.monitorPrize[i],
                                                  "populationWarn",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.monitorPrize[i].populationWarn"
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    })
                  ]
                }),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "总金额：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          oninput:
                            "value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                          placeholder: "请输入总金额"
                        },
                        model: {
                          value:
                            _vm.marketingActivityData.monitor.amountCountWarn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "amountCountWarn",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.monitor.amountCountWarn"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单日总金额：" } },
                    [
                      _c("el-input", {
                        attrs: {
                          oninput:
                            "value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')",
                          placeholder: "请输入单日总金额"
                        },
                        model: {
                          value:
                            _vm.marketingActivityData.monitor
                              .amountDayCountWarn,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "amountDayCountWarn",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.monitor.amountDayCountWarn"
                        }
                      })
                    ],
                    1
                  )
                ]
              ]
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "40px" } }, [
            _c("div", { staticClass: "lucky-draw" }, [_vm._v("阀值配置")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "是否开启阈值处理：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.monitor.isOpenValve,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.monitor,
                        "isOpenValve",
                        $$v
                      )
                    },
                    expression: "marketingActivityData.monitor.isOpenValve"
                  }
                },
                _vm._l(_vm.radioList, function(radio, index) {
                  return _c(
                    "el-radio",
                    {
                      attrs: {
                        disabled: _vm.$route.query.activityStatus == 0,
                        label: radio.value
                      }
                    },
                    [_vm._v(_vm._s(radio.label))]
                  )
                }),
                1
              ),
              _vm.$route.query.activityStatus == 0
                ? _c(
                    "el-tag",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: { type: "danger" }
                    },
                    [_vm._v("活动发布后可配置监控")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.marketingActivityData.monitor.isOpenValve
            ? [
                _c(
                  "el-form-item",
                  { attrs: { label: "触发阀值处理：" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: _vm.marketingActivityData.monitor.handle,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "handle",
                              $$v
                            )
                          },
                          expression: "marketingActivityData.monitor.handle"
                        }
                      },
                      [
                        _c(
                          "el-checkbox",
                          { attrs: { label: 1, title: "暂不可选" } },
                          [_vm._v("暂停抽奖")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "报警设置：" } },
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        on: { change: _vm.changeValvePrize },
                        model: {
                          value: _vm.marketingActivityData.monitor.valveIndex,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "valveIndex",
                              $$v
                            )
                          },
                          expression: "marketingActivityData.monitor.valveIndex"
                        }
                      },
                      _vm._l(_vm.marketingActivityData.prize, function(
                        prize,
                        index
                      ) {
                        return _c(
                          "el-checkbox",
                          {
                            key: index,
                            attrs: {
                              disabled: !_vm.marketingActivityData.prize[index]
                                .name,
                              title: !_vm.marketingActivityData.prize[index]
                                .name
                                ? "您尚未配置奖项" + _vm.wordList[index]
                                : "",
                              label: prize.id
                            }
                          },
                          [_vm._v(" 奖项" + _vm._s(_vm.wordList[index]) + " ")]
                        )
                      }),
                      1
                    )
                  ],
                  1
                ),
                _vm._l(_vm.marketingActivityData.monitor.valveIndex, function(
                  mI,
                  i
                ) {
                  return [
                    _vm._l(_vm.marketingActivityData.prize, function(
                      item,
                      index
                    ) {
                      return [
                        item.id == mI
                          ? [
                              _vm.marketingActivityData.monitor.valvePrize
                                .length ==
                              _vm.marketingActivityData.monitor.valveIndex
                                .length
                                ? _c(
                                    "el-form-item",
                                    {
                                      key: index + 8,
                                      attrs: {
                                        label:
                                          "奖项" +
                                          _vm.wordList[index] +
                                          "各项阀值："
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "inputFLex" },
                                        [
                                          _vm._v(" 已发放奖品数量："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.valvePrize[i]
                                                  .prizeNumAlarm,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.valvePrize[i],
                                                  "prizeNumAlarm",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.valvePrize[i].prizeNumAlarm"
                                            }
                                          }),
                                          _vm._v(" 库存："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.valvePrize[i]
                                                  .inventoryAlarm,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.valvePrize[i],
                                                  "inventoryAlarm",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.valvePrize[i].inventoryAlarm"
                                            }
                                          }),
                                          item.productType == "redpacket"
                                            ? [
                                                _vm._v(" 总金额："),
                                                _c("el-input", {
                                                  attrs: {
                                                    oninput:
                                                      "value=value.replace(/^\\D*(\\d*(?:\\.\\d{0,2})?).*$/g, '$1')"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.marketingActivityData
                                                        .monitor.valvePrize[i]
                                                        .amountPrizeAlarm,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm
                                                          .marketingActivityData
                                                          .monitor.valvePrize[
                                                          i
                                                        ],
                                                        "amountPrizeAlarm",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "marketingActivityData.monitor.valvePrize[i].amountPrizeAlarm"
                                                  }
                                                })
                                              ]
                                            : _vm._e(),
                                          _vm._v(" 中奖人数："),
                                          _c("el-input", {
                                            attrs: {
                                              oninput:
                                                "value=value.replace(/^0|[^0-9]/g, '')"
                                            },
                                            model: {
                                              value:
                                                _vm.marketingActivityData
                                                  .monitor.valvePrize[i]
                                                  .populationAlarm,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.marketingActivityData
                                                    .monitor.valvePrize[i],
                                                  "populationAlarm",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "marketingActivityData.monitor.valvePrize[i].populationAlarm"
                                            }
                                          })
                                        ],
                                        2
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          : _vm._e()
                      ]
                    })
                  ]
                }),
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "总金额：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入总金额" },
                        model: {
                          value:
                            _vm.marketingActivityData.monitor.amountCountAlarm,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "amountCountAlarm",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.monitor.amountCountAlarm"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单日总金额：" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入单日总金额" },
                        model: {
                          value:
                            _vm.marketingActivityData.monitor
                              .amountDayCountAlarm,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.monitor,
                              "amountDayCountAlarm",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.monitor.amountDayCountAlarm"
                        }
                      })
                    ],
                    1
                  )
                ]
              ]
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "40px" } }, [
            _c("div", { staticClass: "lucky-draw" }, [_vm._v("通知邮箱")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "通知邮箱：" } },
            [
              _c("el-input", {
                attrs: {
                  type: "text",
                  disabled: _vm.$route.query.activityStatus == 0,
                  placeholder: "请输入邮箱"
                },
                model: {
                  value: _vm.marketingActivityData.monitor.noticeEmail,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.monitor,
                      "noticeEmail",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.monitor.noticeEmail"
                }
              }),
              _vm.$route.query.activityStatus == 0
                ? _c("el-tag", { attrs: { type: "danger" } }, [
                    _vm._v("活动发布后可配置监控")
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }