<template>
  <!-- 基础设置 -->
  <div class="basic-setting">
    <el-form ref="form" :model="marketingActivityData.activity" label-width="220px" :rules="rules">
      <el-form-item label="活动标题：" prop="name">
        <el-input v-model="marketingActivityData.activity.name" class="title" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item v-if="marketingActivityData.activity.templeType == 16" label="集卡活动主题：" prop="themeId">
        <el-select v-model="marketingActivityData.activity.themeId" placeholder="请选择">
          <el-option
            v-for="item in themeList"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时间：" :required="true">
        <el-col :span="11">
          <el-form-item prop="beginTime">
            <el-date-picker
            type="datetime"
            placeholder="选择开始时间"
            v-model="marketingActivityData.activity.beginTime"
            :picker-options="startDatePicker"
            style="width: 100%"
            class="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">
            <span class="time-text">至</span>
        </el-col>
        <el-col :span="11">
          <el-form-item prop="endTime">
            <el-date-picker
            type="datetime"
            placeholder="选择结束时间"
            v-model="marketingActivityData.activity.endTime"
            :picker-options="endDatePicker"
            style="width: 100%"
            class="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="参与人数：">
        <el-radio-group v-model="marketingActivityData.activity.isJoinPeopleShow">
          <el-radio :label="false">隐藏</el-radio>
          <el-radio :label="true">显示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="获取玩家号码：">
        <el-radio-group v-model="marketingActivityData.activity.isGetPhone">
          <el-radio :label="false">关闭</el-radio>
          <el-radio :label="true">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="活动说明：" prop="description">
        <el-input
          type="textarea"
          v-model="marketingActivityData.activity.description"
          placeholder="请输入活动说明"
          resize="none"
          maxlength="200"
        ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data () {
    // var validatePass = (rule, value, callback) => {
    //   if (!!value == '') {
    //     callback(new Error('请选择活动结束时间'));
    //   } else if (!!value && new Date(value).getTime() - new Date(this.marketingActivityData.activity.beginTime).getTime() < 600000) {
    //     callback(new Error('活动结束时间须在开始时间十分钟后'));
    //   }
    // }
    return {
      rules: {
        'name': [
          { required: true, message: '请输入活动标题', trigger: 'blur' },
        ],
        'themeId': [
          { required: true, message: '请选择集卡主题', trigger: 'blur' },
        ],
        'beginTime': [
          { required: true, message: '请选择活动开始时间', trigger: 'change' },
        ],
        'endTime': [
          // { required: true, validator: validatePass, trigger: 'change' },
          { required: true, message: '请选择活动结束时间', trigger: 'change' },
        ],
        'description': [
          { required: true, message: '请输入活动说明', trigger: 'blur' },
        ]
      },
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate(),
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData', 'themeList'])
  },
  created () {
    const that = this
    this.$bus.$on('validateFirst', () => {
      that.$refs.form.validate((valid) => {
        that.setBasicValidate(valid)
        if (valid) {
          return true
        } else {
          return false
        }
      })
    })
  },
  methods: {
    ...mapMutations('market', ['setBasicValidate']),
    beginDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.marketingActivityData.activity.endTime) { // 如果结束时间不为空，则小于结束时间
            return new Date(self.marketingActivityData.activity.endTime).getTime() < time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
    processDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.marketingActivityData.activity.beginTime) { // 如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.marketingActivityData.activity.beginTime).getTime() > time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.basic-setting {
  /deep/ .el-form-item__label {
    padding: 0 32px 0 0;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color:#606266;
  }
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 198px;
    height: 34px;
  }
  /deep/ .el-col-11 {
      width: 198px
  }
  /deep/ .el-col-2 {
      width: auto;
  }
  .time {
    width: 198px !important;
  }
  /deep/ .title .el-input__inner {
    width: 830px;
  }
  .time-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin: 0 18px;
  }
  /deep/ .el-textarea__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 124px;
    border-radius: 4px;
  }
  .number {
    width: 62px;
  }
  /deep/ .number .el-input__inner {
    width: 62px;
    height: 30px;
    background: #1d1e23;
    &:focus {
      border: 2px solid #2468f2;
    }
  }
  .limit {
    margin-right: 8px;
  }
  .limit-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 9px;
  }
}
</style>
