<template>
  <!-- 抽奖模式 -->
  <div class="drawMode" v-if="marketingActivityData.activity.templePage">
    <!-- 开宝箱 -->
    <div class="openTreasureChest" v-if="marketingActivityData.activity.templeType == 0 || JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 0"
      :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.openTreasureChest.background.pictureUrl})`}">
      <div class="read" v-if="JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 0">
        <div class="read-text" :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor}">
          <p>抽奖规则：</p>
          <p>首先阅读文章，才能获得1次抽奖机会！</p>
          <p>再邀请{{ marketingActivityData.activity.custom.inviteNum }}名好友组队，可额外获得{{ marketingActivityData.activity.custom.chance }}次机会！</p>
        </div>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.openTreasureChest.readBtn.pictureUrl" alt="">
      </div>
      <div class="openTreasureChest-title" v-else>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.openTreasureChest.title.pictureUrl" alt="">
      </div>
      <div class="openTreasureChest-box">
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.openTreasureChest.treasureBox.pictureUrl" alt="">
      </div>
    </div>
    <!-- 九宫格 -->
    <div class="nineBoxGrid" v-else-if="marketingActivityData.activity.templeType == 1 || JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 1"
      :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.background.pictureUrl})`}">
      <div class="read" v-if="JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 1">
        <div class="read-text" :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor}">
          <p>抽奖规则：</p>
          <p>首先阅读文章，才能获得1次抽奖机会！</p>
          <p>再邀请{{ marketingActivityData.activity.custom.inviteNum }}名好友组队，可额外获得{{ marketingActivityData.activity.custom.chance }}次机会！</p>
        </div>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.readBtn.pictureUrl" alt="">
      </div>
      <div class="nineBoxGrid-title" v-else>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.title.pictureUrl" alt="">
      </div>
      <ul class="nineBoxGridPrize" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.nineBoxBackground.pictureUrl})`}">
        <li class="prize-item" v-for="(item, index) in nineBoxGridData[marketingActivityData.prize.length]" :key="index"
          :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.prizeBackground.pictureUrl})`}">
          <img :src="marketingActivityData.prize[item.img].imgUrl.defaultImg" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'default'" alt="">
          <img :src="marketingActivityData.prize[item.img].imgUrl.productImg" v-else-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'product'" alt="">
          <img :src="marketingActivityData.prize[item.img].imgUrl.uploadImg" v-else-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'upload'" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.nineBoxGrid.button.pictureUrl" v-else-if="item.img == ''" alt="" class="nineBoxGrid-button">
          <!-- <p v-if="item.label">{{ item.label }}</p> -->
        </li>
      </ul>
    </div>
    <!-- 砸金蛋 -->
    <div class="smashGoldEgg" v-else-if="marketingActivityData.activity.templeType == 2 || JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 2"
      :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.smashGoldEgg.background.pictureUrl})`}">
      <div class="read" v-if="JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 2">
        <div class="read-text" :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor}">
          <p>抽奖规则：</p>
          <p>首先阅读文章，才能获得1次抽奖机会！</p>
          <p>再邀请{{ marketingActivityData.activity.custom.inviteNum }}名好友组队，可额外获得{{ marketingActivityData.activity.custom.chance }}次机会！</p>
        </div>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.smashGoldEgg.readBtn.pictureUrl" alt="">
      </div>
      <div class="smashGoldEgg-title" v-else>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.smashGoldEgg.title.pictureUrl" alt="">
      </div>
      <ul class="smashGoldEgg-box">
        <li v-for="item in 3" :key="item" class="smashGoldEgg-item">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.smashGoldEgg.goldEgg.pictureUrl" alt="">
        </li>
      </ul>
    </div>
    <!-- 大转盘 -->
    <div class="bigTurnTable" v-else-if="marketingActivityData.activity.templeType == 3 || JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 3"
      :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.background.pictureUrl})`}">
      <div class="read" v-if="JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === 3">
        <div class="read-text" :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor}">
          <p>抽奖规则：</p>
          <p>首先阅读文章，才能获得1次抽奖机会！</p>
          <p>再邀请{{ marketingActivityData.activity.custom.inviteNum }}名好友组队，可额外获得{{ marketingActivityData.activity.custom.chance }}次机会！</p>
        </div>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.readBtn.pictureUrl" alt="">
      </div>
      <div class="bigTurnTable-title" v-else>
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.title.pictureUrl" alt="">
      </div>
      <div class="bigTurnTablePrize" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.turntable.pictureUrl})`}">
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.button.pictureUrl" alt="" class="start">
        <div v-if="marketingActivityData.prize.length == 1 || marketingActivityData.prize.length == 2" class="prizeNum1">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line1">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line2">
          <ul class="prize">
            <li v-for="(item, index) in bigTurnTableData[marketingActivityData.prize.length]" :key="index" class="prize-item">
              <img :src="marketingActivityData.prize[item.img].imgUrl.defaultImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'default'" class="prize-img">
              <img :src="marketingActivityData.prize[item.img].imgUrl.productImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'product'" class="prize-img">
              <img :src="marketingActivityData.prize[item.img].imgUrl.uploadImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'upload'" class="prize-img">
              <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
              <!-- <p v-if="item.label">{{ item.label }}</p> -->
            </li>
          </ul>
        </div>
        <div v-if="marketingActivityData.prize.length == 3 || marketingActivityData.prize.length == 4" class="prizeNum2">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line1">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line2">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line3">
          <ul class="prize">
            <li v-for="(item, index) in bigTurnTableData[marketingActivityData.prize.length]" :key="index" class="prize-item">
              <img :src="marketingActivityData.prize[item.img].imgUrl.defaultImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'default'">
              <img :src="marketingActivityData.prize[item.img].imgUrl.productImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'product'">
              <img :src="marketingActivityData.prize[item.img].imgUrl.uploadImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'upload'">
              <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
              <!-- <p v-if="item.label">{{ item.label }}</p> -->
            </li>
          </ul>
        </div>
        <div v-if="marketingActivityData.prize.length == 5 || marketingActivityData.prize.length == 6" class="prizeNum3">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line1">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line2">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line3">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigTurnTable.line.pictureUrl" alt="" class="line4">
          <ul class="prize">
            <li v-for="(item, index) in bigTurnTableData[marketingActivityData.prize.length]" :key="index" class="prize-item">
              <img :src="marketingActivityData.prize[item.img].imgUrl.defaultImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'default'">
              <img :src="marketingActivityData.prize[item.img].imgUrl.productImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'product'">
              <img :src="marketingActivityData.prize[item.img].imgUrl.uploadImg" alt="" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'upload'">
              <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
              <!-- <p v-if="item.label">{{ item.label }}</p> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 小兔兔奔月 -->
    <rushes v-else-if="marketingActivityData.activity.templeType == 4"></rushes>
    <!-- 红旗护卫 -->
    <red-flag-guard v-else-if="marketingActivityData.activity.templeType == 5"></red-flag-guard>
    <!-- 画金蛋 -->
    <paint-golden-egg v-else-if="marketingActivityData.activity.templeType == 6"></paint-golden-egg>
    <!-- 幸运大翻转 -->
    <big-reverse v-else-if="marketingActivityData.activity.templeType == 7"></big-reverse>
    <!-- 二维码九宫格 -->
    <div class="codeNineBoxGrid" v-else-if="marketingActivityData.activity.templeType == 10"
      :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.codeNineBoxGrid.background.pictureUrl})`}">
      <ul class="codeNineBoxGridPrize">
        <li class="prize-item" v-for="(item, index) in nineBoxGridData[marketingActivityData.prize.length]" :key="index"
          :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.codeNineBoxGrid.prizeBackground.pictureUrl})`}">
          <img :src="marketingActivityData.prize[item.img].imgUrl.defaultImg" v-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'default'" alt="">
          <img :src="marketingActivityData.prize[item.img].imgUrl.productImg" v-else-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'product'" alt="">
          <img :src="marketingActivityData.prize[item.img].imgUrl.uploadImg" v-else-if="marketingActivityData.prize[item.img] && marketingActivityData.prize[item.img].imgUrl.type == 'upload'" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.codeNineBoxGrid.button.pictureUrl" v-else-if="item.img == ''" alt="" class="nineBoxGrid-button">
        </li>
      </ul>
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.codeNineBoxGrid.nineBoxBackground.pictureUrl" alt="" class="light">
      <div class="tips">
        <div class="tips-left">
          温馨提示
        </div>
        <div class="tips-text">
          <p>每位用户每日扫码验真可领取?次抽奖机会。</p>
          <p>{{ marketingActivityData.activity.custom.tips }}</p>
        </div>
      </div>
    </div>
    <!-- 刮刮乐 -->
    <scratch-ticket v-else-if="marketingActivityData.activity.templeType == 11"></scratch-ticket>
    <!-- 投壶 -->
    <throw-pot v-else-if="marketingActivityData.activity.templeType == 12"></throw-pot>
    <!-- 潮前看 -->
    <run-forward v-else-if="marketingActivityData.activity.templeType == 13"></run-forward>
    <!-- 红包雨 -->
    <redpacket-rain v-else-if="marketingActivityData.activity.templeType == 14"></redpacket-rain>
    <!-- 活动分享 -->
    <activity-share v-else-if="marketingActivityData.activity.templeType == 15"></activity-share>
    <!-- 集卡活动 -->
    <Collect-Crad v-else-if="marketingActivityData.activity.templeType == 16"></Collect-Crad>
    <!-- 大富翁 -->
    <Monopoly v-else-if="marketingActivityData.activity.templeType == 17"></Monopoly>
    <!-- 拼拼乐 -->
    <Jigsaw v-else-if="marketingActivityData.activity.templeType == 18"></Jigsaw>
    <!-- 竞猜 -->
    <Match v-else-if="marketingActivityData.activity.templeType == 19"></Match>
    <!-- 答题 -->
    <Answer v-else-if="marketingActivityData.activity.templeType == 20"></Answer>
    <!-- 养兔子-->
    <MyPets v-else-if="marketingActivityData.activity.templeType == 21"></MyPets>
    <!-- 回家路-->
    <GoHome v-else-if="marketingActivityData.activity.templeType == 22"></GoHome>
    <!-- 红包树-->
    <RedTree v-else-if="marketingActivityData.activity.templeType == 23"></RedTree>
    <!-- 现场签到-->
    <Sign v-else-if="marketingActivityData.activity.templeType == 24"></Sign>
    <!-- 滚蛋吧负能量-->
    <GoDieUnlucky v-else-if="marketingActivityData.activity.templeType == 25"></GoDieUnlucky>
    <!-- 最美集赞 -->
    <GiveThumbUp v-else-if="marketingActivityData.activity.templeType == 26"></GiveThumbUp>
    <!-- 扭蛋 -->
    <RotateEgg v-else-if="marketingActivityData.activity.templeType == 27"></RotateEgg>
    <!-- 南北大比拼 -->
    <Dumpling v-else-if="marketingActivityData.activity.templeType == 28"></Dumpling>
    <!-- 数字谜盘 -->
    <NumberList v-else-if="marketingActivityData.activity.templeType == 29"></NumberList>
    <!-- 游泳竞赛 -->
    <SwimmingMatch v-else-if="marketingActivityData.activity.templeType == 30"></SwimmingMatch>
    <!-- 接力跑竞赛 -->
    <RunMatch v-else-if="marketingActivityData.activity.templeType == 31"></RunMatch>
    <!-- 抓娃娃机 -->
    <PrizeClaw v-else-if="marketingActivityData.activity.templeType == 32"></PrizeClaw>
    <!-- 点球大赛 -->
    <BallMatch v-else-if="marketingActivityData.activity.templeType == 33"></BallMatch>
    <!-- 票票抽奖 -->
    <Ticket v-else-if="marketingActivityData.activity.templeType == 34"></Ticket>
    <!-- 赛龙舟 -->
    <DragonBoat v-else-if="marketingActivityData.activity.templeType == 37"></DragonBoat>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Rushes from './Rushes'
import RedFlagGuard from './RedFlagGuard'
import PaintGoldenEgg from './PaintGoldenEgg'
import BigReverse from './BigReverse'
import ScratchTicket from './ScratchTicket'
import ThrowPot from './ThrowPot'
import RunForward from './RunForward'
import RedpacketRain from './redpacketRain'
import ActivityShare from './activityShare'
import CollectCrad from './collectCard'
import Monopoly from './monopoly'
import Jigsaw from './jigsaw'
import Match from './match'
import Answer from './answer'
import MyPets from './myPets'
import GoHome from './goHome'
import RedTree from './redTree'
import Sign from './sign'
import GoDieUnlucky from './goDieUnlucky'
import GiveThumbUp from './giveThumbUp'
import RotateEgg from './rotateEgg'
import Dumpling from './dumpling'
import NumberList from './numberList'
import SwimmingMatch from './swimmingMatch'
import RunMatch from './runMatch'
import BallMatch from './ballMatch'
import PrizeClaw from './prizeClaw'
import Ticket from './ticket'
import DragonBoat from './dragonBoat'
export default {
  components: {
    Rushes,
    PaintGoldenEgg,
    RedFlagGuard,
    BigReverse,
    ScratchTicket,
    ThrowPot,
    RunForward,
    RedpacketRain,
    ActivityShare,
    CollectCrad,
    Monopoly,
    Jigsaw,
    Match,
    Answer,
    MyPets,
    GoHome,
    RedTree,
    Sign,
    GoDieUnlucky,
    GiveThumbUp,
    RotateEgg,
    Dumpling,
    NumberList,
    SwimmingMatch,
    RunMatch,
    BallMatch,
    PrizeClaw,
    Ticket,
    DragonBoat
  },
  data () {
    return {
      nineBoxGridData: {
        1: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: '',
          }, {
            img: 0,
            label: '一等奖'
          }, {
            img: 'xx',
            label: '谢谢参与'
          }, {
            img: 0,
            label: '一等奖'
          }, {
            img: 'xx',
            label: '谢谢参与'
          }
        ],
        2: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: '',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        3: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        4: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: '',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        5: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        6: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        7: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: '',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 6,
            label: '七等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        8: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: '',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 6,
            label: '七等奖',
          }, {
            img: 7,
            label: '八等奖',
          },
        ],
      },
      bigTurnTableData: {
        1: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 0,
            label: '一等奖',
          },{
            img: 0,
            label: '一等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        2: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        3: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 2,
            label: '三等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },{
            img: 1,
            label: '二等奖',
          },
          {
            img: 2,
            label: '三等奖',
          },
        ],
        4: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 3,
            label: '四等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },{
            img: 2,
            label: '三等奖',
          },
          {
            img: 3,
            label: '四等奖',
          },
        ],
        5: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 2,
            label: '三等奖',
          },{
            img: 4,
            label: '五等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },
          {
            img: 3,
            label: '四等奖',
          },{
            img: 4,
            label: '五等奖',
          },
          {
            img: 3,
            label: '四等奖',
          }
        ],
        6: [
          {
            img: 0,
            label: '一等奖',
          },{
            img: 1,
            label: '二等奖',
          },{
            img: 2,
            label: '三等奖',
          },{
            img: 3,
            label: '四等奖',
          },{
            img: 'xx',
            label: '谢谢参与',
          },
          {
            img: 5,
            label: '六等奖',
          },{
            img: 4,
            label: '五等奖',
          },
          {
            img: 5,
            label: '六等奖',
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  mounted () {
    // console.log('this.marketingActivityData:', this.marketingActivityData)
  }
}
</script>

<style lang="scss" scoped>
  .drawMode {
    .read {
      position: absolute;
      top: 40px;
      text-align: center;
      width: 100%;
      img {
        width: 50%;
      }
      .read-text {
        font-weight: 700;
        white-space: pre-wrap;
        margin-bottom: 10px;
      }
    }
    .openTreasureChest,
    .smashGoldEgg,
    .nineBoxGrid,
    .bigTurnTable {
      position: relative;
      width: 100%;
      height: 660px;
      background-size: 100% 100%;
      margin-top: 22px;
    }
    .bigTurnTable-title,
    .nineBoxGrid-title,
    .smashGoldEgg-title {
      width: 370px;
      img {
        width: 100%;
      }
    }
    .bigTurnTable-title {
      img {
        margin-top: 20px;
      }
    }
    .openTreasureChest-title {
      width: 370px;
      text-align: center;
      img {
        width: 80%;
      }
    }
    .openTreasureChest-box {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translateX(-50%);
      width: 100%;
      img {
        width: 100%;
      }
    }
    .nineBoxGridPrize,
    .codeNineBoxGridPrize {
      position: absolute;
      left: 50%;
      top: 43%;
      width: 320px;
      height: 300px;
      display: flex;
      flex-wrap: wrap;
      transform: translateX(-50%);
      background-size: 100% 100%;
      justify-content: space-around;
      align-content: space-around;
      padding: 20px;
      .prize-item {
        width: 86px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #fff;
        background-size: 100% 100%;
        // margin: 5px 0 0 5px;
        img {
          width: 60px;
          height: 60px;
        }
        &:nth-child(5) {
          background: none !important;
        }
        .nineBoxGrid-button {
          width: 86px;
          height: 80px;
        }
      }
    }
    .bigTurnTablePrize {
      position: absolute;
      left: 50%;
      top: 32%;
      width: 320px;
      height: 320px;
      transform: translateX(-50%);
      background-size: 100% 100%;
      .start {
        width: 60px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
      }
      .line1,
      .line2,
      .line3,
      .line4 {
        height: 260px;
        width: 2px;
      }
      .prizeNum1 {
        position: relative;
        .line1 {
          position: absolute;
          left: 50%;
          top: 11%;
          transform: translate(-50%);
        }
        .line2 {
          position: absolute;
          top: 10%;
          right: 50%;
          transform: rotate(90deg);
        }
        .prize {
          display: flex;
          flex-wrap: wrap;
          width: 320px;
          height: 320px;
          .prize-item {
            width: 50%;
            height: 50%;
            text-align: center;
            font-size: 14px;
            color: #fff;
            img {
              width: 70px;
              height: 70px;
              margin-top: 80px;
            }
            &:nth-child(1) {
              transform: rotate(-45deg);
            }
            &:nth-child(2) {
              transform: rotate(45deg);
            }
            &:nth-child(3) {
              transform: rotate(-135deg);
            }
            &:nth-child(4 ) {
              transform: rotate(135deg);
            }
          }
        }
      }
      .prizeNum2 {
        .line1 {
          position: absolute;
          left: 50%;
          top: 11%;
          transform: translate(-50%);
        }
        .line2 {
          position: absolute;
          left: 160px;
          top: 34px;
          transform: translate(-50%);
          transform: rotate(60deg);
        }
        .line3 {
          position: absolute;
          left: 160px;
          top: 34px;
          transform: translate(-50%);
          transform: rotate(120deg);
        }
        .prize {
          .prize-item {
            position: absolute;
            z-index: 999;
            font-size: 14px;
            color: #fff;
            img {
              width: 60px;
              height: 60px;
            }
            &:nth-child(1) {
              top: 54px;
              left: 86px;
              transform: rotate(-30deg);
            }
            &:nth-child(2) {
              top: 54px;
              right: 86px;
              transform: rotate(30deg);
            }
            &:nth-child(3) {
              top: 164px;
              right: 44px;
              transform: translateY(-50%) rotate(90deg);
            }
            &:nth-child(4) {
              bottom: 50px;
              right: 88px;
              transform: rotate(150deg);
            }
            &:nth-child(5) {
              bottom: 50px;
              left: 88px;
              transform: rotate(210deg);
            }
            &:nth-child(6) {
              top: 164px;
              left: 44px;
              transform: translateY(-50%) rotate(-90deg);
            }
          }
        }
      }
      .prizeNum3 {
        .line1 {
          position: absolute;
          left: 50%;
          top: 11%;
          transform: translate(-50%);
        }
        .line2 {
          position: absolute;
          left: 160px;
          top: 30px;
          transform: translate(-50%);
          transform: rotate(45deg);
        }
        .line3 {
          position: absolute;
          top: 50%;
          right: 160px;
          transform: translateY(-50%) rotate(90deg);
        }
        .line4 {
          position: absolute;
          left: 160px;
          top: 30px;
          transform: translate(-50%);
          transform: rotate(135deg);
        }
        .prize {
          .prize-item {
            position: absolute;
            z-index: 999;
            font-size: 12px;
            color: #fff;
            text-align: center;
            img {
              width: 50px;
              height: 50px;
            }
            &:nth-child(1) {
              top: 50px;
              left: 100px;
              transform: rotate(-22.5deg);
            }
            &:nth-child(2) {
              top: 50px;
              right: 100px;
              transform: rotate(22.5deg);
            }
            &:nth-child(3) {
              top: 100px;
              right: 50px;
              transform: rotate(67.5deg);
            }
            &:nth-child(4) {
              top: 168px;
              right: 50px;
              transform: rotate(112.5deg);
            }
            &:nth-child(5) {
              bottom: 52px;
              right: 100px;
              transform: rotate(157.5deg);
            }
            &:nth-child(6) {
              bottom: 52px;
              left: 100px;
              transform: rotate(-157.5deg);
            }
            &:nth-child(7) {
              top: 168px;
              left: 50px;
              transform: rotate(-112.5deg);
            }
            &:nth-child(8) {
              top: 100px;
              left: 50px;
              transform: rotate(-67.5deg);
            }
          }
        }
      }
    }
    .smashGoldEgg-box {
      position: absolute;
      left: 50%;
      top: 20%;
      transform: translateX(-50%);
      width: 100%;
      padding: 0 20px;
      display: flex;
      flex-wrap: wrap;
      margin-top: 30%;
      .smashGoldEgg-item {
        width: 40%;
        img {
          width: 132px;
        }
        &:nth-child(2) {
          margin-left: 20%;
        }
        &:nth-child(3) {
          position: absolute;
          top: 70%;
          left: 50%;
          transform: translateX(-50%);
          width: 132px;
        }
      }
    }
    // 二维码九宫格
    .codeNineBoxGrid {
      position: relative;
      width: 100%;
      background-size: cover;
      margin-top: 22px;
      height: 602px;
      .light {
        position: absolute;
        width: 335px;
        top: 40%;
        left: 50%;
        transform: translateX(-50%);
      }
      .tips {
        position: absolute;
        bottom: 0;
        left: 10%;
        font-size: 12px;
        display: flex;
        color: #df3636;
        .tips-left {
          width: 28px;
          font-weight:700;
          font-size: 14px;
          transform: scale(0.8);
        }
        .tips-text {
          transform: scale(0.9);
          padding-top: 4px;
          p {
            width: 270px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
    .codeNineBoxGridPrize {
      top: 36%;
    }
  }
</style>
