<template>
  <div class="bigReverse" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.bigReverse.background.pictureUrl})`}">
      <div class="bigReverse-title">
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigReverse.title.pictureUrl" alt="">
      </div>
      <ul class="bigReversePrize">
        <li class="prize-item" v-for="(item, index) in bigReverseData[marketingActivityData.prize.length]" :key="index"
          :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.bigReverse.prizeBackground.pictureUrl})`}">
          <img :src="JSON.parse(marketingActivityData.prize[item.img].prizePictureUrl).url" v-if="marketingActivityData.prize[item.img]" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.defaultPrize.welcome.pictureUrl" v-else-if="item.img == 'xx'" alt="">
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.bigReverse.button.pictureUrl" v-else-if="item.img == ''" alt="" class="bigReverse-button">
          <!-- <p v-if="item.label">{{ item.label }}</p> -->
        </li>
      </ul>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
  data () {
    return {
      bigReverseData: {
        1: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: '',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        2: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: '',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        3: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        4: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: '',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        5: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
        6: [
          {
            img: 0,
            label: '一等奖',
          }, {
            img: 1,
            label: '二等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 4,
            label: '五等奖',
          }, {
            img: '',
          }, {
            img: 2,
            label: '三等奖',
          }, {
            img: 3,
            label: '四等奖',
          }, {
            img: 5,
            label: '六等奖',
          }, {
            img: 'xx',
            label: '谢谢参与',
          },
        ],
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.bigReverse {
  position: relative;
  width: 100%;
  height: 660px;
  background-size: 100% 100%;
  margin-top: 22px;
  .bigReverse-title {
    width: 370px;
    img {
      width: 100%;
    }
  }
  .bigReversePrize {
    position: absolute;
    left: 50%;
    top: 43%;
    width: 320px;
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    transform: translateX(-50%);
    background-size: 100% 100%;
    justify-content: space-around;
    align-content: space-around;
    padding: 20px;
    .prize-item {
      width: 80px;
      height: 86px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      color: #fff;
      background-size: 100% 100%;
      // margin: 5px 0 0 5px;
      img {
        width: 60px;
        height: 60px;
      }
      &:nth-child(5) {
        background: none !important;
      }
      .bigReverse-button {
        width: 80px;
        height: 86px;
      }
    }
  }
}
</style>