<template>
  <div class="personalizedSetting">
    <el-form
      ref="form"
      :model="marketingActivityData.activity"
      label-width="220px"
      :rules="rules"
      v-if="templeType == 10"
    >
      <!-- 二维码九宫格 -->
      <el-form-item label="活动规则：" prop="custom.rules">
        <el-input
          type="textarea"
          v-model="marketingActivityData.activity.custom.rules"
          placeholder="请输入活动规则"
          resize="none"
          maxlength="2500"
        ></el-input>
      </el-form-item>
      <el-form-item label="温馨提示：" prop="custom.tips">
        <el-input
          v-model="marketingActivityData.activity.custom.tips"
          maxlength="200"
          placeholder="请输入温馨提示"
        ></el-input>
      </el-form-item>
      <el-form-item label="中奖消息显示数量：" prop="custom.winShowNum">
        <el-input
          v-model="marketingActivityData.activity.custom.winShowNum"
          maxlength="8"
          oninput="value=value.replace(/[^\d]/g,'')"
          placeholder="请输入中奖消息显示数量"
        ></el-input>
      </el-form-item>

        <el-form-item label="中额外的奖品：" prop="custom.productId">
          <el-input
            v-model="marketingActivityData.activity.custom.productId"
            class="ext-name"
            disabled
            v-show="false"
          ></el-input>
           <el-input
            v-model="marketingActivityData.activity.custom.productName"
            class="ext-name"
            disabled
          ></el-input>
          <el-button type="primary" size="mini" @click="prizeCenter">选择奖品</el-button>
          <el-button type="default" size="mini" @click="clearSelectPrize">清除</el-button>
      </el-form-item>

        <el-form-item label="中烟平台标识：" prop="custom.thirdSystemCode">
        <el-input
          v-model="marketingActivityData.activity.custom.thirdSystemCode"
          maxlength="200"
          placeholder=""
        ></el-input>
      </el-form-item>
    </el-form>
    <el-form
      v-if="templeType == 9"
      ref="form"
      :model="marketingActivityData.activity"
      label-width="220px"
      :rules="rules"
    >
      <el-form-item label-width="58px">
        <div class="basic-options">抽奖限制</div>
      </el-form-item>
      <el-form-item label="文章链接：" prop="custom.url">
        <el-input
          v-model="custom.url"
          maxlength="50"
          placeholder="请输入文章链接，如：https://mp.weixin.qq.com/xxx"
        ></el-input>
      </el-form-item>
      <el-form-item label="抽奖规则：">
        <div class="draw-rules">
          <span class="limit-text">每邀请</span>
          <el-input
            v-model="custom.inviteNum"
            maxlength="8"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
          <span class="limit-text">名好友参与阅读，额外获得</span>
          <el-input
            v-model="custom.chance"
            maxlength="8"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
          <span class="limit-text">次抽奖机会</span>
        </div>
      </el-form-item>
    </el-form>
    <el-form
      v-if="templeType == 5"
      ref="form"
      :model="marketingActivityData.activity"
      label-width="220px"
      class="hoiseflag"
    >
      <el-form-item label-width="58px">
        <div class="basic-options">抽奖限制</div>
      </el-form-item>
      <el-form-item
        class="hoiseflag-item"
        label="每人答题总次数"
        prop="custom.userAnswerChance"
      >
        <el-input
          @change="customChangeEvent"
          controls-position="right"
          v-model.number="custom.userAnswerChance"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="hoiseflag-item"
        label="每人每天答题总次数"
        prop="custom.userDayAnswerChance"
      >
        <el-input
          @change="customChangeEvent"
          controls-position="right"
          v-model.number="custom.userDayAnswerChance"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="hoiseflag-item"
        label="答题正确指标数"
        prop="custom.rightAnswerChance"
      >
        <el-input
          @change="customChangeEvent"
          controls-position="right"
          v-model.number="custom.rightAnswerChance"
          maxlength="10"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="hoiseflag-item"
        label="H5用户答题数"
        prop="custom.questionNum"
      >
        <el-input
          @change="customChangeEvent"
          controls-position="right"
          v-model.number="custom.questionNum"
          maxlength="10"
        ></el-input>
      </el-form-item>
    </el-form>

   <!-- 奖品中心 设置额外中的奖品-->
    <prize-center ref="prizeCenter"  :extAward="1"></prize-center>
  </div>
</template>

<script>
import PrizeCenter from './PrizeCenter.vue'
import { mapState, mapMutations } from 'vuex'
import _ from 'lodash'
import { getLocal } from '@/lib/local'

export default {
  components: {
    PrizeCenter
  },
  props: {
    templeType: {
      type: Number
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  methods: {
    ...mapMutations('market', ['setCustom']),
    customChangeEvent () {
      if (this.custom.userAnswerChance < this.custom.userDayAnswerChance) {
        this.custom.userAnswerChance = this.custom.userDayAnswerChance
      }
      if (this.custom.questionNum < this.custom.rightAnswerChance) {
        this.custom.questionNum = this.custom.rightAnswerChance
      }
    },
    // 选择奖品中心奖品
    prizeCenter () {
      this.$bus.$emit('getType')
      if (getLocal()) this.$refs.prizeCenter.visible = true
    },
    /**
     * 清除已经添加额外奖品的逻辑
     */
    clearSelectPrize () {
      // console.log(this)
      // console.log(this.marketingActivityData.activity)
      this.custom.productId = ''
      this.custom.productName = ''
      this.marketingActivityData.activity.custom.productId = ''
      this.marketingActivityData.activity.custom.productName = ''
    }
  },
  created () {
    this.custom = { ...this.custom, ..._.cloneDeep(this.marketingActivityData.activity.custom) }
    console.log(this.custom)
  },
  watch: {
    custom: {
      handler: function (nv, ov) {
        this.setCustom(_.cloneDeep(nv))
      },
      deep: true
    }
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入文章链接'))
      } else {
        var reg = /^(http(s)?:\/\/mp.weixin.qq.com\/).*$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的微信公众号文章链接'))
        }
      }
    }
    return {
      custom: { // 个性化设置json
        url: '',
        inviteNum: 2,
        chance: 1,
        rules: '',
        tips: '',
        winShowNum: 10,
        userAnswerChance: 0,
        userDayAnswerChance: 0,
        rightAnswerChance: 1,
        questionNum: 1
      },
      form: {},
      articleLink: '',
      invite: '',
      number: '',
      rules: {
        'custom.url': [
          { required: true, validator: validatePass, trigger: 'blur' }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.personalizedSetting {
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 34px;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
  .basic-options {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
  /deep/ .el-upload--picture-card {
    width: 50px;
    height: 50px;
    line-height: 60px;
    background-color: transparent;
  }
  .limit-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606266;
  }
  .draw-rules {
    display: flex;
    /deep/ .el-input__inner {
      width: 100%;
    }
    .el-input {
      width: 120px;
    }
  }
  /deep/ .el-textarea__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 124px;
    border-radius: 4px;
  }
  .hoiseflag {
    display: inline-block;

    width: 100%;
    /deep/ .el-input__inner {
      width: 100%;
    }
    .el-input {
      width: 120px;
    }
  }

   /deep/ .ext-name.el-input {
        display: inline-block;
        width: 200px;
        margin-right: 10px;
      .el-input__inner {
        width: 100%;
      }
    }

}
.hoiseflag-item {
  float: left;
  width: 50%;
}
</style>
