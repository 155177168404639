var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "throwPot",
      style: {
        backgroundImage: "url(" + _vm.throwPot.background.pictureUrl + ")"
      }
    },
    [
      _c("div", { staticClass: "throwPot-title" }, [
        _c("img", { attrs: { src: _vm.throwPot.title.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "pot" }, [
        _c("img", { attrs: { src: _vm.throwPot.pot.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "arrowMiss" }, [
        _c("img", { attrs: { src: _vm.throwPot.arrow.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "bow" }, [
        _c("img", { attrs: { src: _vm.throwPot.bow.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "start" }, [
        _c("img", { attrs: { src: _vm.throwPot.start.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "chance" }, [
        _c("span", { staticClass: "chance-text num" }, [_vm._v("3次")]),
        _c("span", { staticClass: "chance-text" }, [_vm._v("投壶机会")]),
        _c("img", { attrs: { src: _vm.throwPot.chance.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "rules" }, [
        _c("img", { attrs: { src: _vm.throwPot.rulesBtn.pictureUrl, alt: "" } })
      ]),
      _c("div", { staticClass: "rules myPrizeBtn" }, [
        _c("img", {
          attrs: { src: _vm.throwPot.myPrizeBtn.pictureUrl, alt: "" }
        })
      ]),
      _c("div", { staticClass: "arrow" }, [
        _c("img", { attrs: { src: _vm.throwPot.arrow.pictureUrl, alt: "" } })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }