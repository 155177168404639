<template>
  <!-- 红旗护卫 -->
  <div class="redFlagGuard" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redFlagGuard.background.pictureUrl})`}">
    <div class="redFlagGuard-title">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.redFlagGuard.title.pictureUrl" alt="">
    </div>
    <div class="redFlagGuard-start">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.redFlagGuard.buttonBgc.pictureUrl" alt="">
      <span class="toAnswer">开始答题</span>
    </div>
    <div class="redFlagGuard-secret">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.redFlagGuard.secret.pictureUrl" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
}
</script>

<style lang="scss" scoped>
  .redFlagGuard {
    position: relative;
    width: 100%;
    height: 615px;
    background-size: 100% 100%;
    margin-top: 22px;
    .redFlagGuard-title {
      width: 370px;
      text-align: center;
      img {
        width: 90%;
        margin-top: 50px;
      }
    }
    .redFlagGuard-start {
      width: 370px;
      text-align: center;
      img {
        width: 50%;
        margin-top: 100px;
      }
      .toAnswer {
        position: absolute;
        top: 315px;
        left: 50%;
        transform: translateX(-50%);
        color: #faf8c4;
        font-weight: 700;
        font-size: 20px;
      }
    }
    .redFlagGuard-secret {
      width: 375px;
      margin: 50px 0 0 20px;
      img {
        width: 60px;
      }
    }
  }
</style>