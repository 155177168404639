<template>
<div class="dumpling">
  <div class="title" :style="{backgroundImage:`url(${dumpling.title.pictureUrl})`}"></div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  },
  data () {
    return {
      dumpling: {}
    }
  },
  created () {
    this.dumpling = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.dumpling
  }
}
</script>

<style lang="scss" scoped>
.dumpling{
  height: 682px;
  background-image: url("../../../assets/img/marketing/dumpling/pic.png");
  //background: linear-gradient(
  //    to right,
  //    #1F87CF 0%,
  //    #1F87CF 50%,
  //    #EF5055 50%,
  //    #EF5055 100%
  //);
  background-size: 100% 100%;
  position: relative;

}
</style>
