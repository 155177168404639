<template>
  <!-- 奖项设置 -->
  <div class="award-setting">
    <div class="award-list">
        <ul>
            <li v-for="(item, index) in awardList" :key="index" @click="checkoutAward(index)" :class="{'active': index == awardNum}">奖项{{ wordList[index] }}</li>
        </ul>
        <div class="add-btn">
            <i class="el-icon-minus" @click="sub"></i>
            <i class="el-icon-plus" @click="add"></i>
        </div>
    </div>
    <el-form ref="form" :model="marketingActivityData.prize[awardNum]" label-width="220px" :rules="rules">
      <el-form-item label-width="58px">
        <div class="basic-options">基本选项·奖项{{ wordList[awardNum] }}</div>
      </el-form-item>
      <el-form-item
        label="奖项等级："
        prop="rank"
       >
        <el-input :placeholder="wordList[awardNum] + '等奖'" disabled></el-input>
      </el-form-item>
      <el-form-item label="奖项类型：">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].type" :disabled="!!marketingActivityData.prize[0].activityId && marketingActivityData.activity.isPublish">
          <el-radio :label="0">自建奖品</el-radio>
          <el-radio :label="1">奖品中心</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="奖项名称："
        prop="name"
        :required="true"
      >
        <!-- <span style="color: #606266; margin-right: 10px">{{ marketingActivityData.prize[awardNum].name }}</span> -->
        <el-input disabled v-model="marketingActivityData.prize[awardNum].name" class="name"></el-input>
        <!-- 2022-04-12  -->
        <!-- <el-button type="primary" v-if="marketingActivityData.prize[awardNum].type == 0" size="mini" @click="selfPrize" :disabled="!!marketingActivityData.prize[0].activityId && marketingActivityData.activity.isPublish">选择自建奖品</el-button>
        <el-button type="primary" v-else size="mini" @click="prizeCenter" :disabled="!!marketingActivityData.prize[0].activityId && marketingActivityData.activity.isPublish">选择奖品</el-button> -->
        <el-button type="primary" v-if="marketingActivityData.prize[awardNum].type == 0" size="mini" @click="selfPrize">选择自建奖品</el-button>
        <el-button type="primary" v-else size="mini" @click="prizeCenter" >选择奖品</el-button>
      </el-form-item>
      <el-form-item
        label="奖项数量："
        prop="quantity"
      >
        <el-input class="chanceOf" v-model="marketingActivityData.prize[awardNum].quantity" :disabled="marketingActivityData.activity.isPublish" placeholder="请输入奖项数量" maxlength="8" @input="inventory"></el-input>
        <!--  已发放数量  -->
        <span v-if="marketingActivityData.prize[awardNum].issuedQty != null" style="color: #606266">已发放{{marketingActivityData.prize[awardNum].issuedQty}}</span>
        <div class="error_tips" v-if="marketingActivityData.prize[awardNum].quantity != '' && isShow">奖项数量大于库存数量</div>
        <div class="error_tips" v-else-if="marketingActivityData.prize[awardNum].quantity < 1 && marketingActivityData.prize[awardNum].quantity != ''">请输入大于零的奖项数量</div>
      </el-form-item>
      <el-form-item label="修改奖项数量" v-if="marketingActivityData.activity.isPublish">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].incChange" @change="computedIncQty">
          <el-radio :label="1">加</el-radio>
          <el-radio :label="-1">减</el-radio>
          <el-input v-if="marketingActivityData.prize[awardNum].incChange" class="chanceOf" v-model="marketingActivityData.prize[awardNum].incQtyNum"
                    oninput="value=value.replace(/^(0+)|[^\d]+/g,'')" @blur="computedIncQty"
                    :placeholder="`请输入要${marketingActivityData.prize[awardNum].incChange == 1 ? '增加' : '减少'}的奖项数量`" maxlength="8"></el-input>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="每日中奖：" v-show="marketingActivityData.activity.templeType != 19" prop="quantityDay">
            <el-input
           class="chanceOf"
              v-model="marketingActivityData.prize[awardNum].quantityDay"
              placeholder="请输入每日中奖数量"
          oninput="value=value.replace(/\D|^-/g,'')"
              maxlength="50"
            ></el-input>
          <!-- <span style="color: #606266"> 当前剩余{{0}}</span> -->
          <span v-if="marketingActivityData.prize[awardNum].quantityDayPool != null"  style="color: #606266"> 当前剩余{{marketingActivityData.prize[awardNum].quantityDayPool}}</span>
      </el-form-item>
      <el-form-item
        label="中奖率："
        prop="rate"
        v-show="marketingActivityData.activity.templeType != 19"
      >
        <el-input v-model="marketingActivityData.prize[awardNum].rate" class="chanceOf" oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}" maxlength="8"></el-input><span style="color: #606266"> %</span>
      </el-form-item>
      <el-form-item label="首抽必中：">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].isFirstWin" @change="isFirstWin">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="贵重奖品：">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].isPrecious">
          <el-radio :label="false">否</el-radio>
          <el-radio :label="true">是</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="奖项图片：">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].imgUrl.type">
          <el-radio label="default">系统默认</el-radio>
          <el-radio label="product">奖品图片</el-radio>
          <el-radio label="upload">上传图片</el-radio>
        </el-radio-group>
      </el-form-item>
  <!--    <el-form-item label="均匀放奖：">
        <span class="limit-text">每间隔</span>
        <el-input v-model="marketingActivityData.prize[awardNum].poolRule.intervalTime"></el-input>
        <span class="limit-text">分钟放奖</span>
        <el-input v-model="marketingActivityData.prize[awardNum].poolRule.quantity"></el-input>
        <span class="limit-text">份</span>
      </el-form-item> -->
      <el-form-item v-if="marketingActivityData.prize[awardNum].imgUrl.type == 'upload'" label="">
        <upload-com @addImg="addImgPrize" showImg="showImg" ref="uploadCom"></upload-com>
      </el-form-item>
      <el-form-item label-width="58px">
        <div class="basic-options">兑换方式·奖项{{ wordList[awardNum] }}</div>
      </el-form-item>
      <el-form-item label="兑换方式：" v-if="marketingActivityData.prize[awardNum].type == 0">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].exchangeType">
          <el-radio :label="0">线下兑换</el-radio>
          <el-radio :label="1">客服兑换</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="操作提示："
        v-if="marketingActivityData.prize[awardNum].type == 0"
        prop="operationTips"
      >
        <el-input
          v-model="marketingActivityData.prize[awardNum].operationTips"
          placeholder="请输入操作提示，如：xxxx"
          maxlength="50"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="兑换地址："
        v-if="marketingActivityData.prize[awardNum].type == 0 && marketingActivityData.prize[awardNum].exchangeType == 0"
        prop="exchangeAddress"
      >
        <el-input
          v-model="marketingActivityData.prize[awardNum].exchangeAddress"
          maxlength="200"
          placeholder="请输入兑换详细地址"
        ></el-input>
      </el-form-item>
      <el-form-item
        v-if="marketingActivityData.prize[awardNum].exchangeType == 1"
        label="客服微信："
        prop="wechatUrl"
      >
        <upload-com @addImg="addWechatUrl" showImg="showImg" ref="uploadCom1"></upload-com>
      </el-form-item>
      <el-form-item
        label="兑换时间："
        :required="true"
      >
        <el-col :span="11">
          <el-form-item
            prop="exchangeBeginTime"
          >
            <el-date-picker
              type="datetime"
              placeholder="选择开始时间"
              v-model="marketingActivityData.prize[awardNum].exchangeBeginTime"
              :picker-options="startDatePicker"
              style="width: 100%"
              class="time"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :span="2">
          <span class="time-text">至</span>
        </el-col>
        <el-col :span="11">
          <el-form-item
            prop="exchangeEndTime"
          >
            <el-date-picker
              type="datetime"
              placeholder="选择结束时间"
              v-model="marketingActivityData.prize[awardNum].exchangeEndTime"
              :picker-options="endDatePicker"
              style="width: 100%"
              class="time"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item label="兑换须知：">
        <el-input
          type="textarea"
          v-model="marketingActivityData.prize[awardNum].exchangeTips"
          placeholder="请输入兑换说明"
          resize="none"
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item label="兑奖通知：" v-if="marketingActivityData.prize[awardNum].exchangeType == 0">
        <el-radio-group v-model="marketingActivityData.prize[awardNum].exchangeNotice">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <!-- 自建奖品 -->
    <self-built-prize ref="selfBuiltPrize" :awardNum="awardNum"></self-built-prize>
    <!-- 奖品中心 -->
    <prize-center ref="prizeCenter" :awardNum="awardNum"></prize-center>
  </div>
</template>

<script>
import SelfBuiltPrize from './SelfBuiltPrize.vue'
import PrizeCenter from './PrizeCenter.vue'
import { mapState, mapMutations } from 'vuex'
import UploadCom from '@/components/upload/uploadCom.vue'
import { editDelPrize } from '@/api/market'
import { getLocal } from '@/lib/local'

const award = {
  exchangeAddress: '',
  exchangeBeginTime: '',
  exchangeEndTime: '',
  exchangeNotice: 0,
  exchangeTips: '',
  productId: '',
  isFirstWin: false,
  isPrecious: false,
  operationTips: '',
  poolRule: {
    intervalTime: '',
    quantity: ''
  },
  //   [
  //   {
  //     beginTime: '',
  //     endTime: '',
  //     intervalTime: '',
  //     qty: 0,
  //     quantity: '',
  //     ruleId: ''
  //   }
  // ],

  rate: 0,
  exchangeType: 0,
  name: '',
  quantity: 1,
  quantityDay: '', // 每日中奖数量
  imgUrl: {
    defaultImg: '',
    productImg: '',
    type: 'default', // 奖品图片类型（default默认,product奖品,upload上传）
    uploadImg: ''
  },
  rank: 0,
  type: 0,
  wechatUrl: ''
}
export default {
  components: {
    SelfBuiltPrize,
    PrizeCenter,
    UploadCom
  },
  // props: {
  //   templeType: {
  //     type: Number
  //   }
  // },
  data () {
    return {
      awardNum: this.$route.query.level || 0,
      awardList: [0, 1, 2],
      oldAwardNum: '',
      wordList: ['一', '二', '三', '四', '五', '六', '七', '八'],
      prize: [],
      rules: {
        'rank': [
          { required: true, message: '请输入奖项等级', trigger: 'blur' }
        ],
        'name': [
          { required: true, message: '请输入奖项名称', trigger: 'change' }
        ],
        'quantity': [
          { required: true, message: '请输入奖项数量', trigger: 'change' }
        ],
        'quantityDay': [
          { required: true, message: '请输入每日中奖数量', trigger: 'change' }
        ],
        'rate': [
          { required: true, message: '请输入中奖率', trigger: 'blur' }
        ],
        'operationTips': [
          { required: true, message: '请输入操作提示', trigger: 'blur' }
        ],
        'exchangeAddress': [
          { required: true, message: '请输入兑换地址', trigger: 'blur' }
        ],
        'exchangeBeginTime': [
          { required: true, message: '请选择兑换开始时间', trigger: 'change' }
        ],
        'exchangeEndTime': [
          { required: true, message: '请选择兑换结束时间', trigger: 'change' }
        ],
        'wechatUrl': [
          { required: true, message: '请上传客服微信二维码', trigger: 'change' }
        ]
      },
      startDatePicker: this.beginDate(),
      endDatePicker: this.processDate(),
      isWin: false,
      isShow: false
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  beforeDestroy () {
    this.$bus.$off('setImgUrl')
  },
  created () {
    const that = this
    this.$bus.$on('validateThree', () => {
      this.$refs.form.validate((valid) => {
        that.setAwardValidate(valid)
        if (valid) {
          return true
        } else {
          return false
        }
      })
    })
  },
  methods: {
    ...mapMutations('market', ['addPrize', 'subPrize', 'setPrizeImg', 'resetPrize', 'setPrizedata',
      'setServiceWeChat', 'setFirstWin', 'setActivityMapCodeParamDTO', 'setAwardValidate', 'setQuantityDay']),
    // 初始化数据
    init () {
      this.resetPrize()
      this.setActivityMapCodeParamDTO([
        {
          code: '100000',
          name: '中华人民共和国'
        }
      ])
      for (let i = 0; i < this.awardList.length; i++) {
        const index = i + 1
        award.rank = index
        award.imgUrl.defaultImg = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.defaultPrize['defaultPrize' + index].pictureUrl
        this.addPrize(JSON.parse(JSON.stringify(award)))
      }
    },
    // 校验库存
    inventory () {
      this.marketingActivityData.prize[this.awardNum].quantity = this.marketingActivityData.prize[this.awardNum].quantity.replace(/[^\d]/g, '')
      if (sessionStorage.getItem(`prize_${this.awardNum}`)) {
        this.isShow = parseInt(this.marketingActivityData.prize[this.awardNum].quantity) <= parseInt(sessionStorage.getItem(`prize_${this.awardNum}`)) ? false : true
      }
      if (this.marketingActivityData.activity.templeType === 19) {
        this.setQuantityDay()
      }
    },
    // 选择自建奖品
    selfPrize () {
      this.$bus.$emit('getData')
      if (!!getLocal()) this.$refs.selfBuiltPrize.visible = true
    },
    // 选择奖品中心奖品
    prizeCenter () {
      this.$bus.$emit('getType')
      if (!!getLocal()) this.$refs.prizeCenter.visible = true
    },
    // 首抽必中
    isFirstWin (val) {
      if (val) {
        this.setFirstWin(this.awardNum)
      }
    },
    // 奖项选择
    checkoutAward (index) {
      this.awardNum = index
      if (sessionStorage.getItem(`prize_${this.awardNum}`)) {
        this.isShow = parseInt(this.marketingActivityData.prize[this.awardNum].quantity) <= parseInt(sessionStorage.getItem(`prize_${this.awardNum}`)) ? false : true
      }
      if (this.$refs.uploadCom) {
        this.$refs.uploadCom.url = this.marketingActivityData.prize[this.awardNum].imgUrl.uploadImg
      }
      if (this.$refs.uploadCom1) {
        this.$refs.uploadCom1.url = this.marketingActivityData.prize[this.awardNum].wechatUrl
      }
    },
    // 减奖项
    sub () {
      if (this.marketingActivityData.prize[0].activityId && this.marketingActivityData.activity.isPublish) {
        this.$message('已发布活动禁止修改奖项数量')
      } else {
        if (this.awardList.length > 1) {
          if (this.marketingActivityData.prize[this.awardList.length - 1].id) {
            this.$confirm('确定删除该奖项吗？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async () => {
              const res = await editDelPrize({
                activityId: this.marketingActivityData.prize[this.awardList.length - 1].activityId,
                id: this.marketingActivityData.prize[this.awardList.length - 1].id
              })
              if (res.code == 200) {
                this.$message({
                  message: '删除成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.awardList.pop()
                    this.subPrize()
                    if (!this.awardList[this.awardNum] && this.awardNum != 0) {
                      this.awardNum = this.awardList.length - 1
                    }
                  }
                })
              }
            }).catch(() => {})
          } else {
            this.awardList.pop()
            this.subPrize()
            if (!this.awardList[this.awardNum] && this.awardNum != 0) {
              this.awardNum = this.awardList.length - 1
            }
          }
        } else {
          this.$message('最少保留一个奖项')
        }
      }
    },
    // 加奖项
    add () {
      if (this.marketingActivityData.activity.templeType === 19) {
        return
      }
      if (this.marketingActivityData.prize[0].activityId && this.marketingActivityData.activity.isPublish) {
        this.$message('已发布活动禁止修改奖项数量')
      } else {
        if ((this.marketingActivityData.activity.templeType != 10 && this.awardList.length < 6) || (this.marketingActivityData.activity.templeType == 10 && this.awardList.length < this.oldAwardNum)) {
          this.awardList.push(this.awardList.length)
          award.rank = this.awardList.length
          award.imgUrl.defaultImg = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.defaultPrize['defaultPrize' + this.awardList.length].pictureUrl
          if (this.marketingActivityData.prize[0].activityId) {
            award.activityId = this.marketingActivityData.prize[0].activityId
          }
          this.addPrize(JSON.parse(JSON.stringify(award)))
        } else {
          this.$message('已达奖项上限')
        }
      }
    },
    // 奖品图片
    addImgPrize (data) {
      this.setPrizeImg({ imgUrl: data.fileUrl, type: 'upload', index: this.awardNum})
    },
    // 客服微信
    addWechatUrl (data) {
      this.setServiceWeChat({ pictureUrl: data.fileUrl, index: this.awardNum })
    },
    beginDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.marketingActivityData.prize[self.awardNum].exchangeEndTime) { // 如果结束时间不为空，则小于结束时间
            return new Date(self.marketingActivityData.prize[self.awardNum].exchangeEndTime).getTime() < time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
    processDate () {
      const self = this
      return {
        disabledDate (time) {
          if (self.marketingActivityData.prize[self.awardNum].exchangeBeginTime) { // 如果开始时间不为空，则结束时间大于开始时间
            return new Date(self.marketingActivityData.prize[self.awardNum].exchangeBeginTime).getTime() > time.getTime()
          } else {
            // return time.getTime() > Date.now()//开始时间不选时，结束时间最大值小于等于当天
          }
        }
      }
    },
    // 客服微信图片回显
    setWeChatUrl () {
      this.$nextTick(() => {
        if (this.$refs.uploadCom) {
          this.$refs.uploadCom.url = this.marketingActivityData.prize[this.awardNum].imgUrl.uploadImg
        }
        if (this.$refs.uploadCom1) {
          this.$refs.uploadCom1.url = this.marketingActivityData.prize[this.awardNum].wechatUrl && this.marketingActivityData.prize[this.awardNum].wechatUrl != 0 ? this.marketingActivityData.prize[this.awardNum].wechatUrl : ''
        }
      })
    },
    computedIncQty () {
      // if (this.marketingActivityData.prize[this.awardNum].incQtyNum == 0) {
      //   this.$message.warning('请输入大于0的整数')
      //   return
      // }
      if (this.marketingActivityData.prize[this.awardNum].incChange == -1) {
        if (this.marketingActivityData.prize[this.awardNum].incQtyNum > this.marketingActivityData.prize[this.awardNum].quantity){
          this.$message.warning('减去数量不能大于当前奖项总数量！')
          this.marketingActivityData.prize[this.awardNum].incQtyNum = null
          return
        }
      }
      this.marketingActivityData.prize[this.awardNum].incQty = this.marketingActivityData.prize[this.awardNum].incChange * this.marketingActivityData.prize[this.awardNum].incQtyNum
    }
  }
}
</script>

<style lang="scss" scoped>
  .award-setting {
    .error_tips {
      color: #F56C6C;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
      top: 100%;
      left: 0;
    }
    .limit-text{
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #606266;
      margin: 0 9px;
      &+ /deep/ .el-input{
        width: 150px;
        .el-input__inner{
          width: 100%;
        }
      }
    }
    .award-list {
        height: 45px;
        background-color: #232427;
        margin: 0 74px 0 58px;
        ul {
            float: left;
            // display: flex;
            width: 92%;
            // overflow-x: scroll;
            // overflow-y: hidden;
            // white-space: nowrap;
            li {
                float: left;
                width: 112px;
                height: 45px;
                line-height: 45px;
                text-align: center;
                background: #353944;
                border: 1px solid #353944;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #7E838E;
                cursor: pointer;
            }
            .active {
                width: 112px;
                height: 45px;
                background: #1D1E23;
                border: 1px solid #353944;
                font-weight: bold;
                color: #FFFFFF;
            }
        }
        .add-btn {
            float: right;
            height: 45px;
            line-height: 45px;
            i {
                border: 1px dashed #7E838E;
                font-size: 18px;
                padding: 2px;
                color: #7E838E;
                margin-right: 14px;
                cursor: pointer;
            }
        }
    }
  /deep/ .el-tabs--card {
    padding-left: 58px;
  }
  /deep/ .el-tabs__item {
    padding-left: 0 !important;
    width: 112px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7b7e86;
    background: #353944;
    border: 1px solid #353944;
  }
  .basic-options {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 22px;
    color: #8c939d;
    width: 96px;
    height: 96px;
    line-height: 96px;
    text-align: center;
    background: #1d1e23;
    border: 2px solid #2b2c2f;
  }
  .avatar {
    width: 96px;
    height: 96px;
    display: block;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
  /deep/ .el-form-item__label {
    padding: 0 32px 0 0;
  }
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 34px;
  }
  /deep/ .el-select .el-input__inner {
    width: auto;
  }
  /deep/ .el-col-11 {
    width: 198px;
  }
  /deep/ .el-col-2 {
    width: auto;
  }
  .time {
    width: 198px !important;
  }
  /deep/ .time .el-input__inner {
      width: 198px;
  }
  .time-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin: 0 18px;
  }
  /deep/ .el-textarea__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 118px;
    border-radius: 4px;
  }
  .chanceOf {
    width: 300px;
  }
  /deep/ .chanceOf .el-input__inner {
    width: 100%;
  }

  /deep/ .name.el-input {
    display: inline-block;
    width: 100px;
  }
  /deep/ .name .el-input__inner {
    width: 100%;
    border: none;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
</style>
