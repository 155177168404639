<template>
  <div class="match" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.match.background.pictureUrl})`}">
    <div style="height: 420px"></div>
    <div class="btn btn1" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.match.rulesBtn.pictureUrl})`}"></div>
    <div class="btn btn2" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.match.myPrize.pictureUrl})`}"></div>
    <div class="entry" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.match.matchChampion.pictureUrl})`}"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.match{
  height: 682px;
  background-size: 100% 100%;
  position: relative;
  .btn{
    width: 80px;
    height: 32px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 40px;
    &.btn1{
      left: 0;
    }
    &.btn2{
      right: 0;
    }
  }
  .entry{
    width: 300px;
    height: 82px;
    margin: auto;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
}
</style>
