var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "numberList",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .numberList.background.pictureUrl +
          ")"
      }
    },
    [
      _c("div", { staticStyle: { height: "420px" } }),
      _c("div", {
        staticClass: "title",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .numberList.title.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "btn rulesBtn",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .numberList.rulesBtn.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "btn myPrize",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .numberList.myPrize.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "start",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .numberList.start.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "scene4",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .numberList.scene4.pictureUrl +
            ")"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }