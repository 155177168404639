var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "giveThumbUp",
      style: {
        backgroundImage: "url(" + _vm.giveThumbUp.background.pictureUrl + ")"
      }
    },
    [
      _c("div", {
        staticClass: "title",
        style: {
          backgroundImage: "url(" + _vm.giveThumbUp.title.pictureUrl + ")"
        }
      }),
      _c("div", {
        staticClass: "rulesBtn",
        style: {
          backgroundImage: "url(" + _vm.giveThumbUp.rulesBtn.pictureUrl + ")"
        }
      }),
      _c("div", {
        staticClass: "myPrize",
        style: {
          backgroundImage: "url(" + _vm.giveThumbUp.myPrize.pictureUrl + ")"
        }
      }),
      _c("div", {
        staticClass: "uploadPhoto",
        style: {
          backgroundImage: "url(" + _vm.giveThumbUp.uploadPhoto.pictureUrl + ")"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }