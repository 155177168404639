<template>
  <!-- 奖品详情页 -->
  <div class="prizeDetails">
    <img src="../../../assets/img/marketing/title.png" alt="" width="100%" class="titleImg">
    <div class="title-name">
      <i class="el-icon-close"></i>
      <span>{{ marketingActivityData.activity.name }}</span>
      <i class="el-icon-more"></i>
    </div>
    <div class="prize-content">
      <div class="content">
          <div style="text-align: center">
            <p>{{ marketingActivityData.activity.companyName }}</p>
            <div class="prizeImg">
              <img :src="marketingActivityData.prize[0].imgUrl.defaultImg" v-if="marketingActivityData.prize[0].imgUrl.type == 'default'" alt="">
              <img :src="marketingActivityData.prize[0].imgUrl.productImg" v-else-if="marketingActivityData.prize[0].imgUrl.type == 'product'" alt="">
              <img :src="marketingActivityData.prize[0].imgUrl.uploadImg" v-else-if="marketingActivityData.prize[0].imgUrl.type == 'upload'" alt="">
            </div>
            <h3>{{ marketingActivityData.prize[0].name }}</h3>
            <p>兑奖期限：<span v-if="marketingActivityData.prize[0].exchangeEndTime">{{ marketingActivityData.prize[0].exchangeBeginTime }} 至 {{ marketingActivityData.prize[0].exchangeEndTime }}</span></p>
            <h2>兑奖码：888888</h2>
            <p class="text">{{ marketingActivityData.prize[0].operationTips }}</p>
          </div>
          <h4>兑奖详情</h4>
          <p>兑奖期限：<span v-if="marketingActivityData.prize[0].exchangeEndTime">{{ marketingActivityData.prize[0].exchangeBeginTime }} 至 {{ marketingActivityData.prize[0].exchangeEndTime }}</span></p>
          <p>兑奖地址：{{ marketingActivityData.prize[0].exchangeAddress }}</p>
          <p class="note">兑奖须知：{{ marketingActivityData.prize[0].exchangeTips }}</p>
          <h4>客服电话：<span class="isOpen">{{ marketingActivityData.activity.telephone }}</span></h4>
          <h4>自定义按钮</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
}
</script>

<style lang="scss" scoped>
  .prizeDetails {
    position: relative;
    width: 375px;
    height: 717px;
    overflow: auto;
    background-color: #9a2844;
    img {
      width: 100%;
    }
    .prize-content {
      width: 100%;
      height: 89%;
      padding: 40px 10px 20px 10px;
      .content {
        height: 100%;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px;
        p {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666;
          margin: 10px 0;
        }
        h2 {
          font-size: 22px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #FF3C00;
        }
        .prizeImg {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          margin: 0 0 10px 39%;
          border: 1px solid #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 45px;
            height: 45px;
          }
        }
        .text {
          padding-bottom: 20px;
          border-bottom: 1px dashed #ccc;
        }
        h4 {
          margin: 10px 0;
          .isOpen {
            color: #666;
            font-weight: 400;
          }
        }
      }
    }
    // .rightImg {
    //   position: relative;
    //   float: left;
    //   width: 100%;
    //   height: 80%;
    //   .contentImg {
    //     width: 100%;
    //     height: 100%;
    //     margin-top: 21px;
    //   }
      .titleImg {
        width: 100%;
      }
      .title-name {
        position: absolute;
        top: 4%;
        left: 0;
        width: 100%;
        height: 30px;
        display: flex;
        font-size: 12px;
        align-items: center;
        background-color: #f2f2f2;
        span {
          flex: 1;
          text-align: center;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
    // }
    .note {
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }
  }
</style>