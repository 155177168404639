var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.marketingActivityData.activity.templePage
    ? _c(
        "div",
        { staticClass: "drawMode" },
        [
          _vm.marketingActivityData.activity.templeType == 0 ||
          JSON.parse(_vm.marketingActivityData.activity.templePage).activityBase
            .relateTempleType === 0
            ? _c(
                "div",
                {
                  staticClass: "openTreasureChest",
                  style: {
                    backgroundImage:
                      "url(" +
                      JSON.parse(_vm.marketingActivityData.activity.templePage)
                        .drawPage.openTreasureChest.background.pictureUrl +
                      ")"
                  }
                },
                [
                  JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .activityBase.relateTempleType === 0
                    ? _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          {
                            staticClass: "read-text",
                            style: {
                              color: JSON.parse(
                                _vm.marketingActivityData.activity.templePage
                              ).paperWork.totalDrawChance.routineColor
                            }
                          },
                          [
                            _c("p", [_vm._v("抽奖规则：")]),
                            _c("p", [
                              _vm._v("首先阅读文章，才能获得1次抽奖机会！")
                            ]),
                            _c("p", [
                              _vm._v(
                                "再邀请" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .inviteNum
                                  ) +
                                  "名好友组队，可额外获得" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .chance
                                  ) +
                                  "次机会！"
                              )
                            ])
                          ]
                        ),
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.openTreasureChest.readBtn.pictureUrl,
                            alt: ""
                          }
                        })
                      ])
                    : _c("div", { staticClass: "openTreasureChest-title" }, [
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.openTreasureChest.title.pictureUrl,
                            alt: ""
                          }
                        })
                      ]),
                  _c("div", { staticClass: "openTreasureChest-box" }, [
                    _c("img", {
                      attrs: {
                        src: JSON.parse(
                          _vm.marketingActivityData.activity.templePage
                        ).drawPage.openTreasureChest.treasureBox.pictureUrl,
                        alt: ""
                      }
                    })
                  ])
                ]
              )
            : _vm.marketingActivityData.activity.templeType == 1 ||
              JSON.parse(_vm.marketingActivityData.activity.templePage)
                .activityBase.relateTempleType === 1
            ? _c(
                "div",
                {
                  staticClass: "nineBoxGrid",
                  style: {
                    backgroundImage:
                      "url(" +
                      JSON.parse(_vm.marketingActivityData.activity.templePage)
                        .drawPage.nineBoxGrid.background.pictureUrl +
                      ")"
                  }
                },
                [
                  JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .activityBase.relateTempleType === 1
                    ? _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          {
                            staticClass: "read-text",
                            style: {
                              color: JSON.parse(
                                _vm.marketingActivityData.activity.templePage
                              ).paperWork.totalDrawChance.routineColor
                            }
                          },
                          [
                            _c("p", [_vm._v("抽奖规则：")]),
                            _c("p", [
                              _vm._v("首先阅读文章，才能获得1次抽奖机会！")
                            ]),
                            _c("p", [
                              _vm._v(
                                "再邀请" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .inviteNum
                                  ) +
                                  "名好友组队，可额外获得" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .chance
                                  ) +
                                  "次机会！"
                              )
                            ])
                          ]
                        ),
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.nineBoxGrid.readBtn.pictureUrl,
                            alt: ""
                          }
                        })
                      ])
                    : _c("div", { staticClass: "nineBoxGrid-title" }, [
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.nineBoxGrid.title.pictureUrl,
                            alt: ""
                          }
                        })
                      ]),
                  _c(
                    "ul",
                    {
                      staticClass: "nineBoxGridPrize",
                      style: {
                        backgroundImage:
                          "url(" +
                          JSON.parse(
                            _vm.marketingActivityData.activity.templePage
                          ).drawPage.nineBoxGrid.nineBoxBackground.pictureUrl +
                          ")"
                      }
                    },
                    _vm._l(
                      _vm.nineBoxGridData[
                        _vm.marketingActivityData.prize.length
                      ],
                      function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "prize-item",
                            style: {
                              backgroundImage:
                                "url(" +
                                JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.nineBoxGrid.prizeBackground
                                  .pictureUrl +
                                ")"
                            }
                          },
                          [
                            _vm.marketingActivityData.prize[item.img] &&
                            _vm.marketingActivityData.prize[item.img].imgUrl
                              .type == "default"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.defaultImg,
                                    alt: ""
                                  }
                                })
                              : _vm.marketingActivityData.prize[item.img] &&
                                _vm.marketingActivityData.prize[item.img].imgUrl
                                  .type == "product"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.productImg,
                                    alt: ""
                                  }
                                })
                              : _vm.marketingActivityData.prize[item.img] &&
                                _vm.marketingActivityData.prize[item.img].imgUrl
                                  .type == "upload"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.uploadImg,
                                    alt: ""
                                  }
                                })
                              : item.img == "xx"
                              ? _c("img", {
                                  attrs: {
                                    src: JSON.parse(
                                      _vm.marketingActivityData.activity
                                        .templePage
                                    ).drawPage.defaultPrize.welcome.pictureUrl,
                                    alt: ""
                                  }
                                })
                              : item.img == ""
                              ? _c("img", {
                                  staticClass: "nineBoxGrid-button",
                                  attrs: {
                                    src: JSON.parse(
                                      _vm.marketingActivityData.activity
                                        .templePage
                                    ).drawPage.nineBoxGrid.button.pictureUrl,
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      }
                    ),
                    0
                  )
                ]
              )
            : _vm.marketingActivityData.activity.templeType == 2 ||
              JSON.parse(_vm.marketingActivityData.activity.templePage)
                .activityBase.relateTempleType === 2
            ? _c(
                "div",
                {
                  staticClass: "smashGoldEgg",
                  style: {
                    backgroundImage:
                      "url(" +
                      JSON.parse(_vm.marketingActivityData.activity.templePage)
                        .drawPage.smashGoldEgg.background.pictureUrl +
                      ")"
                  }
                },
                [
                  JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .activityBase.relateTempleType === 2
                    ? _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          {
                            staticClass: "read-text",
                            style: {
                              color: JSON.parse(
                                _vm.marketingActivityData.activity.templePage
                              ).paperWork.totalDrawChance.routineColor
                            }
                          },
                          [
                            _c("p", [_vm._v("抽奖规则：")]),
                            _c("p", [
                              _vm._v("首先阅读文章，才能获得1次抽奖机会！")
                            ]),
                            _c("p", [
                              _vm._v(
                                "再邀请" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .inviteNum
                                  ) +
                                  "名好友组队，可额外获得" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .chance
                                  ) +
                                  "次机会！"
                              )
                            ])
                          ]
                        ),
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.smashGoldEgg.readBtn.pictureUrl,
                            alt: ""
                          }
                        })
                      ])
                    : _c("div", { staticClass: "smashGoldEgg-title" }, [
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.smashGoldEgg.title.pictureUrl,
                            alt: ""
                          }
                        })
                      ]),
                  _c(
                    "ul",
                    { staticClass: "smashGoldEgg-box" },
                    _vm._l(3, function(item) {
                      return _c(
                        "li",
                        { key: item, staticClass: "smashGoldEgg-item" },
                        [
                          _c("img", {
                            attrs: {
                              src: JSON.parse(
                                _vm.marketingActivityData.activity.templePage
                              ).drawPage.smashGoldEgg.goldEgg.pictureUrl,
                              alt: ""
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            : _vm.marketingActivityData.activity.templeType == 3 ||
              JSON.parse(_vm.marketingActivityData.activity.templePage)
                .activityBase.relateTempleType === 3
            ? _c(
                "div",
                {
                  staticClass: "bigTurnTable",
                  style: {
                    backgroundImage:
                      "url(" +
                      JSON.parse(_vm.marketingActivityData.activity.templePage)
                        .drawPage.bigTurnTable.background.pictureUrl +
                      ")"
                  }
                },
                [
                  JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .activityBase.relateTempleType === 3
                    ? _c("div", { staticClass: "read" }, [
                        _c(
                          "div",
                          {
                            staticClass: "read-text",
                            style: {
                              color: JSON.parse(
                                _vm.marketingActivityData.activity.templePage
                              ).paperWork.totalDrawChance.routineColor
                            }
                          },
                          [
                            _c("p", [_vm._v("抽奖规则：")]),
                            _c("p", [
                              _vm._v("首先阅读文章，才能获得1次抽奖机会！")
                            ]),
                            _c("p", [
                              _vm._v(
                                "再邀请" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .inviteNum
                                  ) +
                                  "名好友组队，可额外获得" +
                                  _vm._s(
                                    _vm.marketingActivityData.activity.custom
                                      .chance
                                  ) +
                                  "次机会！"
                              )
                            ])
                          ]
                        ),
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.bigTurnTable.readBtn.pictureUrl,
                            alt: ""
                          }
                        })
                      ])
                    : _c("div", { staticClass: "bigTurnTable-title" }, [
                        _c("img", {
                          attrs: {
                            src: JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).drawPage.bigTurnTable.title.pictureUrl,
                            alt: ""
                          }
                        })
                      ]),
                  _c(
                    "div",
                    {
                      staticClass: "bigTurnTablePrize",
                      style: {
                        backgroundImage:
                          "url(" +
                          JSON.parse(
                            _vm.marketingActivityData.activity.templePage
                          ).drawPage.bigTurnTable.turntable.pictureUrl +
                          ")"
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "start",
                        attrs: {
                          src: JSON.parse(
                            _vm.marketingActivityData.activity.templePage
                          ).drawPage.bigTurnTable.button.pictureUrl,
                          alt: ""
                        }
                      }),
                      _vm.marketingActivityData.prize.length == 1 ||
                      _vm.marketingActivityData.prize.length == 2
                        ? _c("div", { staticClass: "prizeNum1" }, [
                            _c("img", {
                              staticClass: "line1",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line2",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c(
                              "ul",
                              { staticClass: "prize" },
                              _vm._l(
                                _vm.bigTurnTableData[
                                  _vm.marketingActivityData.prize.length
                                ],
                                function(item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "prize-item" },
                                    [
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "default"
                                        ? _c("img", {
                                            staticClass: "prize-img",
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.defaultImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "product"
                                        ? _c("img", {
                                            staticClass: "prize-img",
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.productImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "upload"
                                        ? _c("img", {
                                            staticClass: "prize-img",
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.uploadImg,
                                              alt: ""
                                            }
                                          })
                                        : item.img == "xx"
                                        ? _c("img", {
                                            attrs: {
                                              src: JSON.parse(
                                                _vm.marketingActivityData
                                                  .activity.templePage
                                              ).drawPage.defaultPrize.welcome
                                                .pictureUrl,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm.marketingActivityData.prize.length == 3 ||
                      _vm.marketingActivityData.prize.length == 4
                        ? _c("div", { staticClass: "prizeNum2" }, [
                            _c("img", {
                              staticClass: "line1",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line2",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line3",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c(
                              "ul",
                              { staticClass: "prize" },
                              _vm._l(
                                _vm.bigTurnTableData[
                                  _vm.marketingActivityData.prize.length
                                ],
                                function(item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "prize-item" },
                                    [
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "default"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.defaultImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "product"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.productImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "upload"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.uploadImg,
                                              alt: ""
                                            }
                                          })
                                        : item.img == "xx"
                                        ? _c("img", {
                                            attrs: {
                                              src: JSON.parse(
                                                _vm.marketingActivityData
                                                  .activity.templePage
                                              ).drawPage.defaultPrize.welcome
                                                .pictureUrl,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        : _vm._e(),
                      _vm.marketingActivityData.prize.length == 5 ||
                      _vm.marketingActivityData.prize.length == 6
                        ? _c("div", { staticClass: "prizeNum3" }, [
                            _c("img", {
                              staticClass: "line1",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line2",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line3",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c("img", {
                              staticClass: "line4",
                              attrs: {
                                src: JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.bigTurnTable.line.pictureUrl,
                                alt: ""
                              }
                            }),
                            _c(
                              "ul",
                              { staticClass: "prize" },
                              _vm._l(
                                _vm.bigTurnTableData[
                                  _vm.marketingActivityData.prize.length
                                ],
                                function(item, index) {
                                  return _c(
                                    "li",
                                    { key: index, staticClass: "prize-item" },
                                    [
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "default"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.defaultImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "product"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.productImg,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e(),
                                      _vm.marketingActivityData.prize[
                                        item.img
                                      ] &&
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.type == "upload"
                                        ? _c("img", {
                                            attrs: {
                                              src:
                                                _vm.marketingActivityData.prize[
                                                  item.img
                                                ].imgUrl.uploadImg,
                                              alt: ""
                                            }
                                          })
                                        : item.img == "xx"
                                        ? _c("img", {
                                            attrs: {
                                              src: JSON.parse(
                                                _vm.marketingActivityData
                                                  .activity.templePage
                                              ).drawPage.defaultPrize.welcome
                                                .pictureUrl,
                                              alt: ""
                                            }
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm.marketingActivityData.activity.templeType == 4
            ? _c("rushes")
            : _vm.marketingActivityData.activity.templeType == 5
            ? _c("red-flag-guard")
            : _vm.marketingActivityData.activity.templeType == 6
            ? _c("paint-golden-egg")
            : _vm.marketingActivityData.activity.templeType == 7
            ? _c("big-reverse")
            : _vm.marketingActivityData.activity.templeType == 10
            ? _c(
                "div",
                {
                  staticClass: "codeNineBoxGrid",
                  style: {
                    backgroundImage:
                      "url(" +
                      JSON.parse(_vm.marketingActivityData.activity.templePage)
                        .drawPage.codeNineBoxGrid.background.pictureUrl +
                      ")"
                  }
                },
                [
                  _c(
                    "ul",
                    { staticClass: "codeNineBoxGridPrize" },
                    _vm._l(
                      _vm.nineBoxGridData[
                        _vm.marketingActivityData.prize.length
                      ],
                      function(item, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            staticClass: "prize-item",
                            style: {
                              backgroundImage:
                                "url(" +
                                JSON.parse(
                                  _vm.marketingActivityData.activity.templePage
                                ).drawPage.codeNineBoxGrid.prizeBackground
                                  .pictureUrl +
                                ")"
                            }
                          },
                          [
                            _vm.marketingActivityData.prize[item.img] &&
                            _vm.marketingActivityData.prize[item.img].imgUrl
                              .type == "default"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.defaultImg,
                                    alt: ""
                                  }
                                })
                              : _vm.marketingActivityData.prize[item.img] &&
                                _vm.marketingActivityData.prize[item.img].imgUrl
                                  .type == "product"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.productImg,
                                    alt: ""
                                  }
                                })
                              : _vm.marketingActivityData.prize[item.img] &&
                                _vm.marketingActivityData.prize[item.img].imgUrl
                                  .type == "upload"
                              ? _c("img", {
                                  attrs: {
                                    src:
                                      _vm.marketingActivityData.prize[item.img]
                                        .imgUrl.uploadImg,
                                    alt: ""
                                  }
                                })
                              : item.img == "xx"
                              ? _c("img", {
                                  attrs: {
                                    src: JSON.parse(
                                      _vm.marketingActivityData.activity
                                        .templePage
                                    ).drawPage.defaultPrize.welcome.pictureUrl,
                                    alt: ""
                                  }
                                })
                              : item.img == ""
                              ? _c("img", {
                                  staticClass: "nineBoxGrid-button",
                                  attrs: {
                                    src: JSON.parse(
                                      _vm.marketingActivityData.activity
                                        .templePage
                                    ).drawPage.codeNineBoxGrid.button
                                      .pictureUrl,
                                    alt: ""
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      }
                    ),
                    0
                  ),
                  _c("img", {
                    staticClass: "light",
                    attrs: {
                      src: JSON.parse(
                        _vm.marketingActivityData.activity.templePage
                      ).drawPage.codeNineBoxGrid.nineBoxBackground.pictureUrl,
                      alt: ""
                    }
                  }),
                  _c("div", { staticClass: "tips" }, [
                    _c("div", { staticClass: "tips-left" }, [
                      _vm._v(" 温馨提示 ")
                    ]),
                    _c("div", { staticClass: "tips-text" }, [
                      _c("p", [
                        _vm._v("每位用户每日扫码验真可领取?次抽奖机会。")
                      ]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.marketingActivityData.activity.custom.tips)
                        )
                      ])
                    ])
                  ])
                ]
              )
            : _vm.marketingActivityData.activity.templeType == 11
            ? _c("scratch-ticket")
            : _vm.marketingActivityData.activity.templeType == 12
            ? _c("throw-pot")
            : _vm.marketingActivityData.activity.templeType == 13
            ? _c("run-forward")
            : _vm.marketingActivityData.activity.templeType == 14
            ? _c("redpacket-rain")
            : _vm.marketingActivityData.activity.templeType == 15
            ? _c("activity-share")
            : _vm.marketingActivityData.activity.templeType == 16
            ? _c("Collect-Crad")
            : _vm.marketingActivityData.activity.templeType == 17
            ? _c("Monopoly")
            : _vm.marketingActivityData.activity.templeType == 18
            ? _c("Jigsaw")
            : _vm.marketingActivityData.activity.templeType == 19
            ? _c("Match")
            : _vm.marketingActivityData.activity.templeType == 20
            ? _c("Answer")
            : _vm.marketingActivityData.activity.templeType == 21
            ? _c("MyPets")
            : _vm.marketingActivityData.activity.templeType == 22
            ? _c("GoHome")
            : _vm.marketingActivityData.activity.templeType == 23
            ? _c("RedTree")
            : _vm.marketingActivityData.activity.templeType == 24
            ? _c("Sign")
            : _vm.marketingActivityData.activity.templeType == 25
            ? _c("GoDieUnlucky")
            : _vm.marketingActivityData.activity.templeType == 26
            ? _c("GiveThumbUp")
            : _vm.marketingActivityData.activity.templeType == 27
            ? _c("RotateEgg")
            : _vm.marketingActivityData.activity.templeType == 28
            ? _c("Dumpling")
            : _vm.marketingActivityData.activity.templeType == 29
            ? _c("NumberList")
            : _vm.marketingActivityData.activity.templeType == 30
            ? _c("SwimmingMatch")
            : _vm.marketingActivityData.activity.templeType == 31
            ? _c("RunMatch")
            : _vm.marketingActivityData.activity.templeType == 32
            ? _c("PrizeClaw")
            : _vm.marketingActivityData.activity.templeType == 33
            ? _c("BallMatch")
            : _vm.marketingActivityData.activity.templeType == 34
            ? _c("Ticket")
            : _vm.marketingActivityData.activity.templeType == 37
            ? _c("DragonBoat")
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }