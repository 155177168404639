var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "send-way" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.marketingActivityData.limit,
            disabled: [19, 23, 24].includes(
              _vm.marketingActivityData.activity.templeType
            ),
            "label-width": "220px"
          }
        },
        [
          [19, 23, 24].includes(_vm.marketingActivityData.activity.templeType)
            ? _c(
                "el-tag",
                {
                  staticStyle: { "margin-left": "40px" },
                  attrs: { type: "danger" }
                },
                [_vm._v("此模板类型不支持下列配置")]
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "40px" } }, [
            _c("div", { staticClass: "lucky-draw" }, [_vm._v("抽奖限制")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "参与人数限制：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.limit.userJoin,
                    callback: function($$v) {
                      _vm.$set(_vm.marketingActivityData.limit, "userJoin", $$v)
                    },
                    expression: "marketingActivityData.limit.userJoin"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("不限制")]),
                  _c(
                    "el-radio",
                    { staticClass: "limit", attrs: { label: _vm.userJoin } },
                    [_vm._v("限制")]
                  ),
                  _vm.marketingActivityData.limit.userJoin != -1
                    ? _c("el-input", {
                        attrs: {
                          maxlength: "8",
                          oninput: "value=value.replace(/[^\\d]/g,'')"
                        },
                        on: {
                          input: function($event) {
                            _vm.marketingActivityData.limit.userJoin =
                              _vm.userJoin
                          }
                        },
                        model: {
                          value: _vm.userJoin,
                          callback: function($$v) {
                            _vm.userJoin = $$v
                          },
                          expression: "userJoin"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.marketingActivityData.limit.userJoin != -1
                ? _c("span", { staticClass: "limit-text" }, [_vm._v("人")])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "可参与地区：", prop: "region" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.region,
                    callback: function($$v) {
                      _vm.region = $$v
                    },
                    expression: "region"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("全部")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("部分")]),
                  _vm.region == 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              _vm.visible = true
                            }
                          }
                        },
                        [_vm._v("设置")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm.marketingActivityData.activity.templeType != 9 &&
          _vm.marketingActivityData.activity.templeType != 5
            ? [
                _c("el-form-item", { attrs: { "label-width": "40px" } }, [
                  _c("div", { staticClass: "lucky-draw" }, [_vm._v("抽奖次数")])
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "活动总抽奖机会：" } },
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: _vm.marketingActivityData.limit.totalChance,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.limit,
                              "totalChance",
                              $$v
                            )
                          },
                          expression: "marketingActivityData.limit.totalChance"
                        }
                      },
                      [
                        _c("el-radio", { attrs: { label: -1 } }, [
                          _vm._v("不限制")
                        ]),
                        _c("el-radio", { attrs: { label: _vm.totalChance } }, [
                          _vm._v("限制")
                        ])
                      ],
                      1
                    ),
                    _vm.marketingActivityData.limit.totalChance != -1
                      ? _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("span", { staticClass: "limit-text" }, [
                              _vm._v(" 本次活动最多有")
                            ]),
                            _c("el-input", {
                              attrs: {
                                maxlength: "8",
                                oninput: "value=value.replace(/[^\\d]/g,'')"
                              },
                              on: {
                                input: function($event) {
                                  _vm.marketingActivityData.limit.totalChance =
                                    _vm.totalChance
                                }
                              },
                              model: {
                                value: _vm.totalChance,
                                callback: function($$v) {
                                  _vm.totalChance = $$v
                                },
                                expression: "totalChance"
                              }
                            }),
                            _c("span", { staticClass: "limit-text" }, [
                              _vm._v("次抽奖机会")
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                ),
                [10, 21, 22].includes(
                  _vm.marketingActivityData.activity.templeType
                ) == false && _vm.systemCode != "tai_woo_wxys"
                  ? _c(
                      "el-form-item",
                      { attrs: { label: "每人总抽奖机会：" } },
                      [
                        _c(
                          "el-radio-group",
                          {
                            model: {
                              value: _vm.marketingActivityData.limit.userChance,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.marketingActivityData.limit,
                                  "userChance",
                                  $$v
                                )
                              },
                              expression:
                                "marketingActivityData.limit.userChance"
                            }
                          },
                          [
                            _c("el-radio", { attrs: { label: -1 } }, [
                              _vm._v("不限制")
                            ]),
                            _c(
                              "el-radio",
                              { attrs: { label: _vm.userChance } },
                              [_vm._v("限制")]
                            )
                          ],
                          1
                        ),
                        _vm.marketingActivityData.limit.userChance != -1
                          ? _c(
                              "div",
                              { staticStyle: { display: "inline-block" } },
                              [
                                _c("span", { staticClass: "limit-text" }, [
                                  _vm._v(" 每人最多有")
                                ]),
                                _c("el-input", {
                                  attrs: {
                                    maxlength: "8",
                                    oninput: "value=value.replace(/[^\\d]/g,'')"
                                  },
                                  on: {
                                    input: function($event) {
                                      _vm.marketingActivityData.limit.userChance =
                                        _vm.userChance
                                    }
                                  },
                                  model: {
                                    value: _vm.userChance,
                                    callback: function($$v) {
                                      _vm.userChance = $$v
                                    },
                                    expression: "userChance"
                                  }
                                }),
                                _c("span", { staticClass: "limit-text" }, [
                                  _vm._v("次抽奖机会")
                                ])
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "el-form-item",
                  { attrs: { label: "每人每日抽奖机会：" } },
                  [
                    _c("span", { staticClass: "limit-text" }, [
                      _vm._v("每人每日有")
                    ]),
                    _c("el-input", {
                      attrs: {
                        maxlength: "8",
                        oninput: "value=value.replace(/[^\\d]/g,'')"
                      },
                      model: {
                        value: _vm.marketingActivityData.limit.userDayChance,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.limit,
                            "userDayChance",
                            $$v
                          )
                        },
                        expression: "marketingActivityData.limit.userDayChance"
                      }
                    }),
                    _c("span", { staticClass: "limit-text" }, [
                      _vm._v("次抽奖机会")
                    ])
                  ],
                  1
                )
              ]
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "40px" } }, [
            _c("div", { staticClass: "lucky-draw" }, [_vm._v("中奖次数")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "每人中奖次数：" } },
            [
              _c("span", { staticClass: "limit-text" }, [
                _vm._v("每人最多可中奖")
              ]),
              _c("el-input", {
                attrs: {
                  maxlength: "8",
                  oninput: "value=value.replace(/[^\\d]/g,'')"
                },
                model: {
                  value: _vm.marketingActivityData.limit.userWin,
                  callback: function($$v) {
                    _vm.$set(_vm.marketingActivityData.limit, "userWin", $$v)
                  },
                  expression: "marketingActivityData.limit.userWin"
                }
              }),
              _c("span", { staticClass: "limit-text" }, [_vm._v("次")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "每人每日中奖次数：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.limit.userDayWin,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.limit,
                        "userDayWin",
                        $$v
                      )
                    },
                    expression: "marketingActivityData.limit.userDayWin"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: -1 } }, [_vm._v("不限制")]),
                  _c("el-radio", { attrs: { label: _vm.userDayWin } }, [
                    _vm._v("限制")
                  ])
                ],
                1
              ),
              _vm.marketingActivityData.limit.userDayWin != -1
                ? _c(
                    "div",
                    { staticStyle: { display: "inline-block" } },
                    [
                      _c("span", { staticClass: "limit-text" }, [
                        _vm._v(" 每人每日最多可中奖")
                      ]),
                      _c("el-input", {
                        attrs: {
                          maxlength: "8",
                          oninput: "value=value.replace(/[^\\d]/g,'')"
                        },
                        on: {
                          input: function($event) {
                            _vm.marketingActivityData.limit.userDayWin =
                              _vm.userDayWin
                          }
                        },
                        model: {
                          value: _vm.userDayWin,
                          callback: function($$v) {
                            _vm.userDayWin = $$v
                          },
                          expression: "userDayWin"
                        }
                      }),
                      _c("span", { staticClass: "limit-text" }, [_vm._v("次")])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.systemCode == "tai_woo_wxys"
            ? [
                _vm.marketingActivityData.activity.templeType != 16
                  ? [
                      _c("el-form-item", { attrs: { "label-width": "40px" } }, [
                        _c("div", { staticClass: "lucky-draw" }, [
                          _vm._v("扫码抽奖次数")
                        ])
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "扫码抽奖次数：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              maxlength: "8",
                              oninput: "value=value.replace(/[^\\d]/g,'')"
                            },
                            model: {
                              value:
                                _vm.marketingActivityData.limit.barcodeChance,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.marketingActivityData.limit,
                                  "barcodeChance",
                                  $$v
                                )
                              },
                              expression:
                                "marketingActivityData.limit.barcodeChance"
                            }
                          }),
                          _c("span", { staticClass: "limit-text" }, [
                            _vm._v("次")
                          ])
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm.marketingActivityData.activity.templeType == 21
                  ? [
                      _c("el-form-item", { attrs: { "label-width": "40px" } }, [
                        _c("div", { staticClass: "lucky-draw" }, [
                          _vm._v("扫码获得萝卜")
                        ])
                      ]),
                      _c(
                        "el-form-item",
                        { attrs: { label: "获得萝卜根数：" } },
                        [
                          _c("el-input", {
                            attrs: {
                              oninput: "value=value.replace(/[^\\d]/g,'')"
                            },
                            model: {
                              value:
                                _vm.marketingActivityData.limit.barcodeChance,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.marketingActivityData.limit,
                                  "barcodeChance",
                                  $$v
                                )
                              },
                              expression:
                                "marketingActivityData.limit.barcodeChance"
                            }
                          }),
                          _c("span", { staticClass: "limit-text" }, [
                            _vm._v("根")
                          ])
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.marketingActivityData.activity.templeType == 16
            ? [
                _c("el-form-item", { attrs: { "label-width": "40px" } }, [
                  _c("div", { staticClass: "lucky-draw" }, [
                    _vm._v("扫码获得卡片")
                  ])
                ]),
                _c(
                  "el-form-item",
                  { attrs: { label: "获得卡片张数：" } },
                  [
                    _c("el-input", {
                      attrs: { oninput: "value=value.replace(/[^\\d]/g,'')" },
                      model: {
                        value: _vm.marketingActivityData.limit.barcodeChance,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.limit,
                            "barcodeChance",
                            $$v
                          )
                        },
                        expression: "marketingActivityData.limit.barcodeChance"
                      }
                    }),
                    _c("span", { staticClass: "limit-text" }, [_vm._v("张")])
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          staticClass: "city",
          attrs: {
            title: "选择可参与地区",
            visible: _vm.visible,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "cityContent" }, [
            _c("div", { staticClass: "select" }, [
              _c("h3", [_vm._v("选择地区")]),
              _c(
                "div",
                { staticClass: "selectCity" },
                [
                  _c("el-cascader", {
                    attrs: {
                      size: "large",
                      options: _vm.options,
                      "collapse-tags": "",
                      props: { multiple: true }
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "select" }, [
              _c("h3", [_vm._v("可参与地区")]),
              _c(
                "div",
                { staticClass: "selectCity" },
                [
                  _c("el-cascader", {
                    staticClass: "showCity",
                    attrs: {
                      size: "large",
                      options: _vm.options,
                      props: { multiple: true },
                      "collapse-tags": ""
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.selectedOptions,
                      callback: function($$v) {
                        _vm.selectedOptions = $$v
                      },
                      expression: "selectedOptions"
                    }
                  })
                ],
                1
              )
            ])
          ]),
          _c(
            "el-button",
            {
              staticClass: "saveCity",
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.visible = false
                }
              }
            },
            [_vm._v("保存")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }