var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "prizeDetails" }, [
    _c("img", {
      staticClass: "titleImg",
      attrs: {
        src: require("../../../assets/img/marketing/title.png"),
        alt: "",
        width: "100%"
      }
    }),
    _c("div", { staticClass: "title-name" }, [
      _c("i", { staticClass: "el-icon-close" }),
      _c("span", [_vm._v(_vm._s(_vm.marketingActivityData.activity.name))]),
      _c("i", { staticClass: "el-icon-more" })
    ]),
    _c("div", { staticClass: "prize-content" }, [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticStyle: { "text-align": "center" } }, [
          _c("p", [
            _vm._v(_vm._s(_vm.marketingActivityData.activity.companyName))
          ]),
          _c("div", { staticClass: "prizeImg" }, [
            _vm.marketingActivityData.prize[0].imgUrl.type == "default"
              ? _c("img", {
                  attrs: {
                    src: _vm.marketingActivityData.prize[0].imgUrl.defaultImg,
                    alt: ""
                  }
                })
              : _vm.marketingActivityData.prize[0].imgUrl.type == "product"
              ? _c("img", {
                  attrs: {
                    src: _vm.marketingActivityData.prize[0].imgUrl.productImg,
                    alt: ""
                  }
                })
              : _vm.marketingActivityData.prize[0].imgUrl.type == "upload"
              ? _c("img", {
                  attrs: {
                    src: _vm.marketingActivityData.prize[0].imgUrl.uploadImg,
                    alt: ""
                  }
                })
              : _vm._e()
          ]),
          _c("h3", [_vm._v(_vm._s(_vm.marketingActivityData.prize[0].name))]),
          _c("p", [
            _vm._v("兑奖期限："),
            _vm.marketingActivityData.prize[0].exchangeEndTime
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.marketingActivityData.prize[0].exchangeBeginTime
                    ) +
                      " 至 " +
                      _vm._s(_vm.marketingActivityData.prize[0].exchangeEndTime)
                  )
                ])
              : _vm._e()
          ]),
          _c("h2", [_vm._v("兑奖码：888888")]),
          _c("p", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.marketingActivityData.prize[0].operationTips))
          ])
        ]),
        _c("h4", [_vm._v("兑奖详情")]),
        _c("p", [
          _vm._v("兑奖期限："),
          _vm.marketingActivityData.prize[0].exchangeEndTime
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.marketingActivityData.prize[0].exchangeBeginTime) +
                    " 至 " +
                    _vm._s(_vm.marketingActivityData.prize[0].exchangeEndTime)
                )
              ])
            : _vm._e()
        ]),
        _c("p", [
          _vm._v(
            "兑奖地址：" +
              _vm._s(_vm.marketingActivityData.prize[0].exchangeAddress)
          )
        ]),
        _c("p", { staticClass: "note" }, [
          _vm._v(
            "兑奖须知：" +
              _vm._s(_vm.marketingActivityData.prize[0].exchangeTips)
          )
        ]),
        _c("h4", [
          _vm._v("客服电话："),
          _c("span", { staticClass: "isOpen" }, [
            _vm._v(_vm._s(_vm.marketingActivityData.activity.telephone))
          ])
        ]),
        _c("h4", [_vm._v("自定义按钮")])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }