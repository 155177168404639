<template>
  <!-- 自建奖品 -->
  <div class="selfBuiltPrize">
    <el-dialog title="自建奖品" :visible.sync="visible" width="60%" class="prizeList">
      <div class="tableShow">
        <el-table
          :data="dataList"
          border
          v-loading="loading"
          :header-cell-style="{background: '#f5f7fa', width: '64px', fontWeight: '400', color: '#000000'}"
          style="width: 100%;">
          <el-table-column
            prop="name"
            header-align="center"
            align="center"
            label="奖品名称">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="奖品图片">
            <template slot-scope="scope">
              <img :src="scope.row.img" alt="" v-if="scope.row.img" style="width:50px; height: 50px">
            </template>
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="创建时间">
            <template slot-scope="scope">
              <span style="font-size: 12px">{{ scope.row.createTime }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="stock.usableStock"
            header-align="center"
            align="center"
            label="剩余库存"
            width="120">
          </el-table-column>
          <el-table-column
            prop="stock.lockupStock"
            header-align="center"
            align="center"
            label="冻结数量"
            width="120">
          </el-table-column>
          <el-table-column
            prop="stock.usableStock"
            header-align="center"
            align="center"
            label="可用数量"
            width="120">
          </el-table-column>
          <el-table-column
            header-align="center"
            align="center"
            label="状态"
            width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">启用</span>
              <span v-else-if="scope.row.status == 0">禁用</span>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            header-align="center"
            align="center"
            width="200"
            label="操作">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="setPrize(scope.row)" :disabled="!!marketingActivityData.prize[0].activityId && marketingActivityData.activity.isPublish || scope.row.status == 0">设置奖品</el-button>
              <el-button type="text" size="small" @click="getIdData(scope.row.id)">编辑</el-button>
              <el-button type="text" size="small" v-if="scope.row.status == 0" @click="setStatus(scope.row)">启用</el-button>
              <el-button type="text" size="small" v-else-if="scope.row.status == 1" @click="setStatus(scope.row)" style="color: red">禁用</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="btn">
        <el-button type="primary" @click="addData">新增奖品</el-button>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalPage">
        </el-pagination>
      </div>
    </el-dialog>
    <!-- 新增奖品 -->
    <el-dialog title="新增奖品" :visible.sync="isVisible" width="30%">
      <el-form :model="form" ref="form" label-width="120px" :rules="rules">
        <el-form-item label="奖品名称：" prop="name">
          <el-input placeholder="输入奖品名称" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="库存：" prop="stock.usableStock">
          <el-input placeholder="输入奖品数量" v-model="form.stock.usableStock" oninput="value=value.replace(/[^\d]/g,'')" maxlength="8"></el-input>
        </el-form-item>
        <el-form-item label="奖品图片：" prop="img" ref="img">
          <!-- <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            list-type="picture-card"
            :on-success="uploadAwardSuccess"
            :before-upload="awardBeforeUpload"
          >
            <img v-if="form.img" :src="form.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload> -->
          <upload-com @addImg="addImgEvent"  showImg="showImg" ref="uploadCom"></upload-com>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelfPrize, addSelfPrize, getIdSelfPrize, updateSelfPrize, setSelfDisable, setSelfEnable } from '@/api/market'
import UploadCom from '@/components/upload/uploadCom.vue'
import { addImg } from '@/api/myDesign'
import { mapState, mapMutations } from 'vuex'

export default {
  components: {
    UploadCom
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  props: ['awardNum'],
  data () {
    return {
      pageIndex: 1,
      pageSize: 5,
      totalPage: 0,
      visible: false,
      isVisible: false,
      dataList: [],
      loading: true,
      id: '',
      form: {
        img: '',
        name: '',
        stock: {
          usableStock: ''
        }
      },
      rules: {
        name: [
          { required: true, message: '请输入奖品名称', trigger: 'blur' }
        ],
        'stock.usableStock': [
          { required: true, message: '请输入库存数量', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传图片', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    this.$bus.$on('getData', () => {
      this.getData()
    })
  },
  watch: {
    isVisible (newVal) {
      if (!newVal) {
        this.form = {
          img: '',
          name: '',
          stock: {
            usableStock: ''
          }
        }
        this.$refs.uploadCom.url = ''
        this.$refs.form.resetFields()
      }
    }
  },
  methods: {
    ...mapMutations('market', ['setPrizePictureUrl', 'setPrizedata', 'setPrizeImg']),
    // 获取奖品数据
    async getData () {
      const res = await getSelfPrize({ pageNo: this.pageIndex, pageSize: this.pageSize })
      if (res.code == 200) {
        this.dataList = res.data.list
        this.totalPage = res.data.total
        this.loading = false
      }
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getData()
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getData()
    },
    // 文件上传
    addImgEvent (data) {
      this.form.img = data.fileUrl
      this.$refs.img.clearValidate()
    },
    // 根据id查询
    async getIdData (id) {
      this.isVisible = true
      const res = await getIdSelfPrize({ id })
      if (res.code == 200) {
        this.id = id
        this.form = res.data
        this.$refs.uploadCom.url = res.data.img
        this.form.stock.stockChange = this.form.stock.usableStock
      }
    },
    // 新增
    addData () {
      this.id = ''
      this.isVisible = true
    },
    // 提交
    async submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res
          if (this.id) {
            this.form.stock.stockChange = this.form.stock.usableStock - this.form.stock.stockChange
            res = await updateSelfPrize({ id: this.id, ...this.form })
          } else {
            res = await addSelfPrize(this.form)
          }
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.isVisible = false
            this.pageIndex = 1
            this.getData()
          }
        }
      })
    },
    // 修改状态
    async setStatus (data) {
      let res
      if (data.status == 1) {
        res = await setSelfDisable({ id: data.id })
      } else {
        res = await setSelfEnable({ id: data.id })
      }
      if (res.code == 200) {
        this.$message.success('状态修改成功')
        this.getData()
      }
    },
    // 设置奖品
    setPrize (data) {
      sessionStorage.setItem(`prize_${this.awardNum}`, JSON.stringify(data.stock.usableStock))
      // console.log({ ...data, index: this.awardNum })
      this.setPrizedata({ ...data, index: this.awardNum })
      this.setPrizeImg({ imgUrl: data.img, type: 'product', index: this.awardNum })
      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .selfBuiltPrize {
    .prizeList {
      /deep/ .el-dialog {
        min-width: 1000px;
      }
    }
    /deep/ .el-input__inner {
      background-color: #fff !important;
      width: 100% !important;
      border: 1px solid #DCDFE6 !important;
      color: #606266 !important;
    }
    .giftPage {
      height: 32px;
      line-height: 32px;
      /deep/ .el-input__inner {
        background-color: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        width: 100% !important;
        height: 28px;
      }
    }
    .tableShow {
      height: 500px;
      color: #000;
    }
    /deep/ .el-dialog__title {
      width: 93px;
      height: 25px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      line-height: 58px;
    }
    .btn {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
  }
</style>
