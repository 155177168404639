<template>
  <div class="edit-head">
    <!-- <img src="../assets/img/messagepush.png" class="header-logo" />
    <div class="logo-text">
      <div class="header-title">无限立方</div>
      <div class="header-describe">让设计 印刷 营销更简单</div>
    </div> -->
    <div class="logo" @click="$router.push('/')">
      <img src="../assets/img/editLogo.png" alt="" />
    </div>

    <!-- 保存、下载 -->
    <div class="previewSave">
      <div class="save">
        <div class="save-btn" @click="save('save')">
          <i class="iconfont icon-166991 icon-size"></i>
          <div class="save-text">保存</div>
        </div>
      </div>

      <div class="printing" v-if="isPreview">
        <span class="printing-text" @click="preview">预览和发布</span>
      </div>
    </div>

    <!-- 预览发布 -->
    <el-dialog
      title="预览和发布"
      :visible.sync="visible"
      width="40%"
      class="preview"
    >
      <div class="rightImg">
        <img
          src="../assets/img/marketing/title.png"
          alt=""
          width="100%"
          class="titleImg"
        />
        <div class="title-name">
          <i class="el-icon-close"></i>
          <span>{{ marketingActivityData.activity.name }}</span>
          <i class="el-icon-more"></i>
        </div>
        <img
          :src="
            JSON.parse(marketingActivityData.activity.templePage).activityBase
              .cover
          "
          alt=""
          class="contentImg"
          v-if="marketingActivityData.activity.templePage"
        />
        <div class="codeImg">
          <div class="tryActive">
            <active-qrcode
              :url="activityUrl"
              class="codeUrl"
              codeSize="180"
            ></active-qrcode>
            <p>扫二维码体验活动</p>
          </div>
        </div>
      </div>
      <div class="leftCode">
        <div class="code">
          <div class="codeImg">
            <active-qrcode
              :url="activityUrl"
              class="codeUrl"
              codeSize="180"
            ></active-qrcode>
            <p>
              微信扫一扫
              <el-button type="text" @click="downloadCode"
                >下载二维码</el-button
              >
            </p>
          </div>
        </div>
        <div class="code-text">
          <p>活动连接</p>
          <el-input class="activeUrl" v-model="activityUrl"></el-input>
          <el-button
            v-clipboard:error="onError"
            v-clipboard:copy="activityUrl"
            v-clipboard:success="onCopy"
            >复制</el-button
          >
          <div class="btn">
            <el-button type="primary" @click="publish">
              <i class="el-icon-s-promotion"></i>
              马上发布
            </el-button>
            <el-button @click="visible = false">
              <i class="el-icon-edit-outline"></i>
              继续编辑
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="确认发布" :visible.sync="visible3" width="25%">
      <p>
        活动开始时间:
        {{ marketingActivityData.activity.beginTime }}
      </p>
      <p class="activeTime">
        活动结束时间:
        {{ marketingActivityData.activity.endTime }}
      </p>
      <p style="margin-left: 28px">
        活动名称:
        {{ marketingActivityData.activity.name }}
      </p>
      <p class="activeTips">发布后奖项类型和生成券号将不能修改</p>
      <div class="activeBtn">
        <el-button type="primary" @click="getInfo">确认发布</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-dialog>

    <!-- 绑定公众号 -->
    <el-dialog title="绑定公众号" :visible.sync="visible1" width="20%">
      <div style="text-align: center; border: 1px solid #ccc">
        <div
          style="
            width: 200px;
            height: 200px;
            text-align: center;
            display: inline-block;
          "
        >
          <img
            :src="qrcodeUrl"
            alt=""
            class="qrcode"
            v-if="qrcodeUrl"
            @click="refresh"
            width="200px"
          />
        </div>
        <p>微信扫一扫</p>
        <h6 style="margin: 10px 0">请先绑定无限立方公众号</h6>
        <p>绑定后，可在微信中收取活动关键信息（参与人数、奖项数据）等</p>
      </div>
    </el-dialog>
    <!-- 绑定手机 -->
    <el-dialog title="绑定手机" :visible.sync="visible2" width="28%" center="">
      <p style="margin-bottom: 20px">
        为了您的账号安全，请绑定手机，同时该手机号也可以作为登录账号使用
      </p>
      <el-form :model="form" ref="form" :rules="rules">
        <el-form-item prop="phone">
          <el-input
            v-model="form.phone"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-col :span="18">
            <el-form-item prop="code">
              <el-input
                v-model="form.code"
                placeholder="请输入短信验证码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item>
              <el-button
                type="primary"
                @click="sendCode"
                v-if="isSend"
                class="sendCode"
                style="width: 100%"
                >发送验证码</el-button
              >
              <el-button
                type="primary"
                class="sendCode"
                disabled
                v-else
                style="width: 100%"
                >{{ this.countdown + "s" }}</el-button
              >
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item>
          <div style="text-align: center">
            <el-button
              type="primary"
              size="medium"
              class="toPhoneLogin"
              @click="toBind"
              @keydown="toBind"
              >立即绑定</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 模板付费 -->
    <el-dialog :visible.sync="visiblePay" :show-close="false" class="pay">
      <div class="pay-header">
        <div class="pay-title">购买付费活动模板</div>
        <div class="pay-tips">一次购买，永久使用</div>
        <div class="cancel" @click="visiblePay = false">
          <i class="el-icon-close"></i>
        </div>
      </div>
      <div class="pay-info" v-if="marketingActivityData.activity.templePage">
        <ul class="pay-text">
          <li class="pay-item">
            <div class="pay-key">模板类型</div>
            <div class="pay-value">{{ typeList[marketingActivityData.activity.templeType] }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">模板名称</div>
            <div class="pay-value">{{ marketingActivityData.activity.name }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">模板金额</div>
            <div class="pay-value">{{ parseFloat(JSON.parse(marketingActivityData.activity.templePage).activityBase.sellingPrice).toFixed(2) + '元' }}</div>
          </li>
          <li class="pay-item">
            <div class="pay-key">优惠卷</div>
            <div class="pay-value">
              <span class="pay-coupon">无</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </li>
          <li class="pay-item">
            <div class="pay-real">实付金额</div>
            <div class="pay-num">{{ parseFloat(paymentInfo.payAmount).toFixed(2) + '元' }}</div>
          </li>
        </ul>
      </div>
      <div class="code">
        <!-- <p>请使用{{ current ? '支付宝' : '微信' }}扫码支付</p> -->
        <p>请使用微信扫码支付</p>
        <div class="code-content">
          <active-qrcode :url="paymentInfo.codeUrl" codeSize="136"></active-qrcode>
        </div>
        <ul class="btn">
          <li class="btn-item" v-for="(item, index) in payWayList" :key="index" @click="setPayWay(index)" :class="{active: index == current}">{{ item }}</li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import { dataSave, updateStatus, updateActiveData, getIdActiveData, isPay, payInfo, payStatus, isApproval, startApproval, process2Publish } from '@/api/market'
import ActiveQrcode from '@/components/ActiveQrcode.vue'
import { getPublicUrl, getPhoneCode } from '@/api/login'
import { getUserInfo, bindPhone, bindWx } from '@/api/userInfo'
import { getRoleIds, downLoadFile } from '@/lib/util'
import { getLocal } from '@/lib/local'
import Big from 'big.js'
import MathUtil from '@/lib/MathUtil'
let loading
export default {
  props: ['editField', 'saveDataType'],
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'))
      } else {
        var reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的手机号'))
        }
      }
    }
    var validateCode = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入短信验证码'))
      } else {
        var reg = /^\d{6}$/
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的短信验证码'))
        }
      }
    }
    return {
      isPreview: true,
      showLayers: false,
      showAlign: false,
      visible: false,
      visible1: false,
      visible2: false,
      visible3: false,
      visiblePay: false,
      isSend: true,
      uuid: '',
      qrcodeUrl: '',
      expireSeconds: '',
      code: '',
      timeId: '',
      countdown: '',
      activityId: '',
      activityUrl: '',
      isShowCodeImg: false,
      h5Url: `${this.$url}/cube_web_api/auth/authWeChatUrl?userId=`, // 微信扫码进入H5页面
      form: {
        phone: '',
        code: ''
      },
      rules: {
        phone: [{ required: true, validator: validatePass, trigger: 'blur' }],
        code: [{ required: true, validator: validateCode, trigger: 'blur' }]
      },
      // payWayList: ['微信支付', '支付宝'],
      payWayList: ['微信支付'],
      current: 0,
      typeList: ['开宝箱', '九宫格', '砸金蛋', '大转盘', '小兔兔奔月记', '红旗护卫', '画金蛋', '幸运大翻转', '欢乐抽奖机', '阅读有礼'],
      paymentInfo: {},
      payID: null,
      systemCode: ''
    }
  },
  mounted () {
    this.systemCode = getLocal('systemCode')
  },
  watch: {
    code (val, oldval) {
      //  console.log(val)
      if (val === 0) {
        this.$message.success('绑定成功')
        this.release()
        // this.$router.push('/OwnWorkbench/OwnActivity')
      } else if (val === 666) {
        this.$message('该二维码已过期，请点击二维码刷新！')
      }
      clearInterval(this.timeId)
    },
    visible1 (val) {
      if (!val) {
        clearInterval(this.timeId)
      }
    },
    visiblePay (val) {
      if (!val) {
        clearInterval(this.payID)
      }
    }
  },
  components: {
    ActiveQrcode
  },
  computed: {
    ...mapState('edit', ['editModuleList']),
    ...mapGetters('edit', [
      'revokedAbled',
      'redoAbled',
      'drapAbled',
      'lockAbled'
    ]),
    ...mapState('market', ['marketingActivityData', 'basicValidate', 'awardValidate', 'reportValidate'])
  },
  beforeDestroy () {
    this.$bus.$off('editActive')
    this.$bus.$off('validateFirst')
    this.$bus.$off('validateThree')
    if (this.marketingActivityData.activity.templeType == 15) {
      this.$bus.$off('validateSeventh')
    }
  },
  methods: {
    ...mapMutations('market', ['setMonitors']),
    // 马上发布
    async publish () {
      const res = await isPay({ templeId: this.marketingActivityData.activity.templeId })
      if (res.code === 8016) {
        this.getPayInfo(1)
      } else if (res.code === 200) {
        this.visible3 = true
      } else {
        this.$message.error(res.msg)
      }
    },
    // 获取支付信息 1:微信支付 2:支付宝支付
    async getPayInfo (payType) {
      const res = await payInfo({ templeId: this.marketingActivityData.activity.templeId, payType })
      if (res.code === 200) {
        this.paymentInfo = res.data
        this.visiblePay = true
        this.payID = setInterval(() => {
          this.getPayStatus()
        }, 3000)
      }
    },
    // 支付状态查询
    async getPayStatus () {
      const res = await payStatus({ orderNo: this.paymentInfo.orderNo})
      if (res.data === 1) {
        clearInterval(this.payID)
        this.payID = null
        this.$message.success('支付成功')
        this.visiblePay = false
        this.visible3 = true
      }
    },
    // 选择支付方式
    setPayWay (index) {
      // clearInterval(this.payID)
      // this.payID = null
      // if(this.current !== index) {
      //   this.current = index
      //   this.getPayInfo(index + 1)
      // }
      if (index === 1) {
        this.$message('暂不支持此支付方式')
      }
    },
    // 下载二维码
    downloadCode() {
      if (this.h5Url) {
        const imgURL = document
          .getElementsByTagName('canvas')[0]
          .toDataURL('image/jpg')
        downLoadFile(imgURL, 'png')
      }
    },
    cancel () {
      this.visible3 = false
      this.visible = false
    },
    handler () {
      const obj = new Object()
      let prizeMonitor1 = []
      let prizeMonitor2 = []
      if (this.marketingActivityData.monitor.monitorPrize.length >= this.marketingActivityData.monitor.valvePrize.length) {
        prizeMonitor1 = JSON.parse(JSON.stringify(this.marketingActivityData.monitor.monitorPrize))
        prizeMonitor2 = JSON.parse(JSON.stringify(this.marketingActivityData.monitor.valvePrize))
      } else {
        prizeMonitor1 = JSON.parse(JSON.stringify(this.marketingActivityData.monitor.valvePrize))
        prizeMonitor2 = JSON.parse(JSON.stringify(this.marketingActivityData.monitor.monitorPrize))
      }
      prizeMonitor1.forEach((item, index) => {
        obj[item.prizeId] = item
        prizeMonitor2.forEach((item2) => {
          if (item2.prizeId == item.prizeId) {
            obj[item.prizeId] = Object.assign(item, item2)
          } else {
            if (!obj.hasOwnProperty(item2.prizeId)) {
              obj[item2.prizeId] = item2
            }
          }
        })
      })
      let arr = []
      if (Object.values(obj).length) {
        arr = Object.values(obj).map((item) => {
          item.amountPrizeWarn *= 100
          item.amountPrizeAlarm *= 100
          item.amountCountWarn = this.marketingActivityData.monitor.amountCountWarn * 100
          item.amountCountAlarm = this.marketingActivityData.monitor.amountCountAlarm * 100
          item.amountDayCountWarn = this.marketingActivityData.monitor.amountDayCountWarn * 100
          item.amountDayCountAlarm = this.marketingActivityData.monitor.amountDayCountAlarm * 100
          item.noticeEmail = this.marketingActivityData.monitor.noticeEmail
          return item
        })
      } else {
        arr = [
          {
            activityId: this.$route.query.activityId,
            amountCountWarn: this.marketingActivityData.monitor.amountCountWarn * 100,
            amountCountAlarm: this.marketingActivityData.monitor.amountCountAlarm * 100,
            amountDayCountWarn: this.marketingActivityData.monitor.amountDayCountWarn * 100,
            amountDayCountAlarm: this.marketingActivityData.monitor.amountDayCountAlarm * 100,
            noticeEmail: this.marketingActivityData.monitor.noticeEmail,
            prizeNumWarn: null,
            inventoryWarn: null,
            amountPrizeWarn: null,
            populationWarn: null,
            prizeNumAlarm: null,
            inventoryAlarm: null,
            amountPrizeAlarm: null,
            populationAlarm: null,
          }
        ]
      }
      if (!this.marketingActivityData.monitor.isOpenMonitor) {
        arr.forEach((item) => {
          item.prizeNumWarn = null
          item.inventoryWarn = null
          item.amountPrizeWarn = null
          item.populationWarn = null
          item.amountCountWarn = null
          item.amountDayCountWarn = null
        })
      }
      if (!this.marketingActivityData.monitor.isOpenValve) {
        arr.forEach((item) => {
          item.prizeNumAlarm = null
          item.inventoryAlarm = null
          item.amountPrizeAlarm = null
          item.populationAlarm = null
          item.amountCountAlarm = null
          item.amountDayCountAlarm = null
        })
      }
      if (!this.marketingActivityData.monitor.isOpenValve && !this.marketingActivityData.monitor.isOpenMonitor) {
        arr = []
      }
      this.setMonitors(arr)
    },
    // 保存
    async save (preview) {
      if (this.marketingActivityData.activity.templeType == 19){
        this.marketingActivityData.prize[0].quantityDay = this.marketingActivityData.prize[0].quantity
        this.marketingActivityData.prize[0].rate = 100
      }
      if (this.marketingActivityData.activity.templeType == 10 && this.$route.query.activityId) {
        await this.handler()
      }
      await this.$bus.$emit('validateFirst')
      await this.$bus.$emit('validateThree')
      if (this.marketingActivityData.activity.templeType == 15) {
        await this.$bus.$emit('validateSeventh')
      }
      // console.log('this.basicValidate:', this.basicValidate, 'this.awardValidate：', this.awardValidate)
      if (!this.basicValidate || !this.awardValidate || this.marketingActivityData.activity.templeType == 15 ? !this.reportValidate : false) {
        this.$message({
          type: 'error',
          message: '校验失败'
        })
        if (!this.basicValidate) {
          this.$emit('update:activeName', 'first')
        }
        if (!this.awardValidate) {
          this.$emit('update:activeName', 'third')
        }
        if (!this.reportValidate) {
          this.$emit('update:activeName', 'seventh')
        }
        return
      }
      loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      sessionStorage.setItem(
        'data',
        JSON.stringify(this.marketingActivityData)
      )
      const reg = /^(http(s)?:\/\/mp.weixin.qq.com\/).*$/
      if (this.saveDataType == 'market') {
        let chanceOf = new Big(0)
        this.marketingActivityData.prize.forEach((item) => {
          // console.log(item.rate)
          // chanceOf += parseFloat(item.rate);
          chanceOf = chanceOf.plus(item.rate)
        })
        chanceOf = chanceOf.toNumber()
        // console.log("chanceOf === ",chanceOf.toNumber())
        if (chanceOf > 100) {
          loading.close()
          this.$message.error('中奖率不能超过100%')
        } else if(!this.marketingActivityData.activity.custom.url && this.marketingActivityData.activity.templeType == 9) {
          loading.close()
          this.$message.error('请输入文章链接')
          this.$emit('update:activeName', 'fifth')
        } else if (!reg.test(this.marketingActivityData.activity.custom.url) && this.marketingActivityData.activity.templeType == 9) {
          loading.close()
          this.$message.error('请输入正确的微信公众号文章链接')
          this.$emit('update:activeName', 'fifth')
        } else if (this.marketingActivityData.activity.templeType == 10 && this.marketingActivityData.prize.length === 8 && chanceOf != 100) {
          loading.close()
          this.$message.error('设置最大奖项时概率之和为100%')
        } else {
          if (this.marketingActivityData.activity.templePage) {
            this.$nextTick(() => {
              this.$bus.$emit('validateFirst')
              this.$bus.$emit('validateThree')
            })
            let res
            let approval
            const data = JSON.parse(JSON.stringify(this.marketingActivityData))
            data.activity.custom = JSON.stringify(data.activity.custom)
            // if(data.activity.templeType == 9) {
            //   data.limit.userDayChance = 99999999
            // }
            // 更新
            if (this.marketingActivityData.activity.id || this.activityId) {
              data.activity.brandExposure = JSON.stringify(data.activity.brandExposure)
              data.activity.functionButton = JSON.stringify(data.activity.functionButton)
              data.activity.shareData = JSON.stringify(data.activity.shareData)
              data.prize.forEach(item => {
                item.imgUrl = JSON.stringify(item.imgUrl)
                item.prizeId = item.id ? item.id : ''
              })
              if (this.marketingActivityData.activity.id) {
                data.activityId = data.activity.id
                data.limit.limitId = data.limit.id
                data.map.mapId = data.map.id
                if (!data.monitors) {
                  data.monitors = []
                }
                // if(this.marketingActivityData.activity.templeType == 10){
                approval = await isApproval(data)
                // }
                if (approval.code == 200) {
                  res = await updateActiveData(data)
                  this.showResult(res, preview)
                } else if (approval.code == 5000) {
                  this.$prompt('请输入钉钉审批说明', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^[^\s]+[\s]*.*$/,
                    inputErrorMessage: '审批说明不能为空'
                  }).then(({ value }) => {
                    data.processRemark = value
                    startApproval(data).then(res => {
                      if (res.code == 200) {
                        this.$message.success(res.msg)
                      }
                      this.showResult(res, preview)
                    })
                  }).catch(() => {
                    this.$message({
                      type: 'info',
                      message: '取消输入'
                    })
                    loading.close()
                  })
                } else {
                  this.$message.error(approval.msg)
                }
              } else if (this.activityId) {
                const result = await getIdActiveData({ id: this.activityId })
                if (result.code === 200) {
                  data.activityId = result.data.activity.id
                  data.limit.limitId = result.data.limit.id
                  data.map.mapId = result.data.map.id
                  data.prize.forEach((item, index) => {
                    result.data.prize.forEach((ele, num) => {
                      if (index === num) {
                        item.updateDTO.prizeId = ele.id
                      }
                    })
                  })
                  const approval = await isApproval(data)
                  console.log('approval2:', approval)
                  res = await updateActiveData(data)
                  this.showResult(res, preview)
                }
              }
            } else {
              // 新建
              res = await dataSave(data)
              this.showResult(res, preview)
            }
          }
        }
      }
    },
    showResult (res, preview) {
      loading.close()
      if (res.code === 200) {
        if (preview == 'preview') {
          this.visible = true
          if (res.data) {
            this.activityId = res.data
          }
          this.activityUrl =
            this.h5Url +
            getRoleIds().userId +
            '&redirectUrl=' +
            encodeURIComponent(
              `${process.env.VUE_APP_WECHAT}/#/pages/shareIn/shareIn?activity=` +
                this.activityId
            )
          this.$bus.$emit('editActive', this.activityId)
        } else {
          this.$message.success('保存成功')
          this.$router.push( this.systemCode == 'tai_woo_wxys' ? '/marketingActivity/OwnActivity' : '/OwnWorkbench/OwnActivity')
        }
      } else if (res.code == 999) {
        this.$message.error('请将活动信息填写完整')
        if (
          !this.marketingActivityData.activity.name ||
          !this.marketingActivityData.activity.beginTime ||
          !this.marketingActivityData.activity.endTime ||
          !this.marketingActivityData.activity.description
        ) {
          this.$emit('update:activeName', 'first')
        } else {
          this.$emit('update:activeName', 'third')
        }
      } else {
        this.$message.error(res.msg)
      }
    },
    // 复制成功
    onCopy (e) {
      this.$message({
        message: '复制成功！',
        type: 'sucess'
      })
    },
    // 复制失败
    onError (e) {
      this.$message({
        message: '复制失败！',
        type: 'error'
      })
    },
    // 预览发布弹框
    preview () {
      this.save('preview');
    },
    // 发布
    async release () {
      let res
      if (this.marketingActivityData.activity.templeType == 10) {
        this.$prompt('请输入钉钉审批说明', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[^\s]+[\s]*.*$/,
          inputErrorMessage: '审批说明不能为空'
        }).then(async ({ value }) => {
          res = await process2Publish({ id: this.activityId, processRemark: value })
          this.result(res)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          })
        })
      } else {
        res = await updateStatus({ id: this.activityId })
        this.result(res)
      }
    },
    result (res) {
      if (res.code == 200) {
        this.$message.success('发布成功')
        this.$router.push(this.systemCode == 'tai_woo_wxys' ? '/marketingActivity/OwnActivity' : '/OwnWorkbench/OwnActivity')
      } else if (res.code == 800) {
        this.$message.error(res.msg)
        this.visible3 = false
      }
    },
    // 获取uuid
    getUuid (uuid, isWeb) {
      const idArr = []
      const hexDigits = '0123456789abcdef'
      for (let i = 0; i < 32; i++) {
        idArr[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
      }
      if (isWeb) {
        return (uuid = idArr.join(''))
      } else {
        return (uuid = 'web_' + idArr.join(''))
      }
    },
    // 获取二维码
    async getQrcode () {
      this.uuid = this.getUuid(this.uuid)
      const res = await getPublicUrl({ sceneId: this.uuid })
      if (res && res.code === 0) {
        this.expireSeconds =
          new Date().getTime() + res.data.expireSeconds * 1000
        this.qrcodeUrl = res.data.qrCode
        this.sweepCode()
      }
    },
    // 扫码
    async sweepCode () {
      this.timeId = setInterval(async () => {
        const res = await bindWx({ sceneId: this.uuid })
        if (res.code == 0) {
          this.code = 0
        } else if (res.code == 500) {
          this.$message.error('该微信已绑定其他账号')
          this.refresh()
        } else {
          if (new Date().getTime() > this.expireSeconds) {
            this.code = 666
          }
        }
      }, 5000)
    },
    // 刷新二维码
    refresh () {
      clearInterval(this.timeId)
      this.getQrcode()
    },
    // 发送验证码
    async sendCode () {
      const params = JSON.stringify(this.form)
      if (!this.form.phone) {
        this.$message('请输入正确的手机号')
      } else {
        const res = await getPhoneCode(params)
        if (res && res.code === 0) {
          this.isSend = false
          this.countdown = 60
          const codeId = setInterval(() => {
            if (this.countdown === 0) {
              clearInterval(codeId)
              this.isSend = true
            }
            this.countdown--
          }, 1000)
        }
      }
    },
    // 手机绑定
    toBind () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const res = await bindPhone(this.form)
          if (res.code == 0) {
            this.$message.success('绑定成功')
            this.visible2 = false
            this.release()
          } else {
            this.$message.error(res.msg)
          }
        }
      })
    },
    // 获取用户信息
    async getInfo () {
      const res = await getUserInfo()
      if (res.code == 0) {
        this.userInfo = res.data
        this.getBindType(this.userInfo.userAuths)
      }
    },
    // 获取绑定信息
    getBindType (userAuths) {
      const identityArr = []
      userAuths.forEach((item) => {
        identityArr.push(item.identityType)
      })
      if (identityArr.includes(5)) {
        this.release()
        return
      }
      if (!identityArr.includes(1)) {
        this.visible2 = true
      } else if (!identityArr.includes(3)) {
        // if(identityArr.includes(5)){
        // this.release()
        // } else {
        // }
        this.getQrcode()
        this.visible1 = true
      } else {
        this.release()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 24px;
}
.edit-head {
  width: 100vw;
  height: 64px;
  display: flex;
  align-items: center;
  .logo {
    width: 200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img {
      width: 145px;
      height: 34px;
    }
  }
  .header-logo {
    width: 30px;
    height: 34px;
    background: #ffffff;
    margin: 12px 7px 18px 26px;
  }
  .header-title {
    width: 68px;
    height: 17px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    font-family: Microsoft YaHei;
    margin-bottom: 2px;
  }
  .header-describe {
    width: 108px;
    font-size: 12px;
    // transform: scale(0.83, 0.83);
    font-family: Hiragino Sans GB;
    font-weight: normal;
    color: #9da2ad;
  }
  .file {
    margin-left: 66px;
    color: #ffffff;
    .file-text {
      width: 28px;
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      margin-right: 8px;
    }
  }
  .operation-way {
    height: 64px;
    padding-left: 56px;
    width: 1355px;
    .way-show {
      height: 64px;
      display: flex;
      align-items: center;
      text-align: center;
    }
    .undo,
    .way-btn,
    .redo {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
    }
    // .redo {
    //   color: #5d6269;
    // }
    .redo-btn {
      margin: 0 434px 0 22px;
    }
    .btn-item {
      margin-left: 27px;
      // color: #ffffff;
      position: relative;
      &:nth-of-type(3) {
        margin-left: 0;
      }
    }
  }
  .save {
    text-align: center;
    display: flex;
    align-items: center;
    height: 64px;
    color: #ffffff;
    margin: 0 30px 0 88%;
    .save-btn {
      cursor: pointer;
    }
    .save-text,
    .down-text {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-align: center;
    }
    .down-text {
      margin: 0 36px 0 40px;
    }
  }
  .printing {
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: #2468f2;
    border-radius: 20px;
    padding: 0 18px;
    width: 116px;
    .printing-text {
      width: 27px;
      height: 14px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #e2ebfd;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .previewSave {
    flex: 1;
    display: flex;
    align-items: center;
  }
}

.layers {
  width: 80px;
  height: 135px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  .layer-item {
    height: 25%;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgb(212, 212, 212);
    }
  }
}
.aligns {
  width: 160px;
  background: #ffffff;
  border-radius: 4px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99;
  .align-item {
    width: 50%;
    height: 33px;
    float: left;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: rgb(212, 212, 212);
    }
  }
}

.fileOperItem {
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #000000;
  &:hover {
    background-color: #6d6d6d57;
  }
}
.editAbled {
  color: white;
}
.unEditAbled {
  color: #5d6269;
  pointer-events: none;
}
.preview {
  /deep/ .el-dialog__body {
    overflow: hidden;
  }
  .rightImg:hover .codeImg {
    display: block !important;
  }
  .rightImg {
    position: relative;
    float: left;
    width: 45%;
    height: 630px;
    .codeImg {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 96%;
      background-color: rgba(0, 0, 0, 0.3);
      text-align: center;
      .tryActive {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 220px;
        height: 250px;
        background-color: #fff;
        text-align: center;
        .codeUrl {
          display: inline-block;
          margin: 20px 0 10px 0;
        }
      }
    }
    .contentImg {
      width: 100%;
      height: 88%;
      margin-top: 21px;
    }
    .titleImg {
      width: 100%;
    }
    .title-name {
      position: absolute;
      top: 4%;
      left: 0;
      width: 100%;
      height: 30px;
      display: flex;
      font-size: 12px;
      align-items: center;
      background-color: #f2f2f2;
      span {
        flex: 1;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .leftCode {
    float: right;
    width: 45%;
    height: 630px;
    .code {
      height: 50%;
      text-align: center;
      .codeImg {
        display: inline-block;
        width: 220px;
        height: 250px;
        border: 1px solid #ccc;
        text-align: center;
        .codeUrl {
          display: inline-block;
          margin: 20px 0 10px 0;
        }
        /deep/ .el-button {
          padding: 0;
        }
      }
    }
    .code-text {
      height: 50%;
      .activeUrl {
        width: 70%;
      }
      p {
        margin-top: 20px;
      }
      .btn {
        margin-top: 70px;
        text-align: center;
      }
    }
  }
}
.activeTime {
  margin: 20px 0;
}
.activeTips {
  margin: 20px 0;
  color: #999;
}
.activeBtn {
  text-align: center;
}
.pay {
  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog {
    width: 520px;
    height: 729px;
    border-radius: 16px;
    .el-dialog__body {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
  .pay-header {
    position: relative;
    padding-top: 24px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    // background-color: #f60;
    height: 100px;
    background: url("../assets/img/marketing/bg.png");
    background-size: 100% 100%;
    .pay-title {
      font-size: 18px;
      color: #fff;
      font-weight: 500;
      text-align: center;
    }
    .cancel {
      position: absolute;
      top: 16px;
      right: 16px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      padding: 4px;
      background-color: #fff;
      color: #ff7539;
      line-height: 14px;
      cursor: pointer;
    }
    .pay-tips {
      width: 244px;
      height: 30px;
      background: rgba(255, 255, 255, 0.15);
      border: 1px solid #fff;
      border-radius: 15px;
      margin: 8px auto 15px;
      text-align: center;
      line-height: 30px;
      color: #fff;
    }
  }
  .pay-info {
    padding: 59px 30px 50px;
    .pay-text {
      width: 460px;
      height: 240px;
      background: #FAFAFA;
      padding: 10px 20px;
      .pay-item {
        border-bottom: 1px solid #E7E7E7;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        color: #666;
        padding: 0 10px;
        .pay-key {
          float: left;
        }
        .pay-value {
          float: right;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
        }
        .pay-coupon {
          width: 63px;
          height: 30px;
          background: url('../assets/img/marketing/card_bg.png');
          background-size: 100% 100%;
          line-height: 30px;
          text-align: center;
          margin-right: 10px;
        }
        &:last-child {
          height: 60px;
          line-height: 60px;
          border: none;
        }
        .pay-real {
          float: left;
          font-weight: 500;
          color: #181818;
        }
        .pay-num {
          float: right;
          color: #EE0C19;
        }
      }
    }
  }
  .code {
    text-align: center;
    font-size: 12px;
    .code-content {
      width: 160px;
      height: 160px;
      background: url("../assets/img/QR_bg.png");
      background-size: 100% 100%;
      margin: 12px auto;
      padding: 12px;
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    .btn-item {
      width: 80px;
      height: 30px;
      background: #E9F0FE;
      border: 1px solid #FAFAFA;
      border-radius: 4px 0px 0px 4px;
      line-height: 30px;
      cursor: pointer;
    }
    .active {
      border: 1px solid #2468F2;
      color: #2468F2;
    }
  }
}
</style>
