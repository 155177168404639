var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activity-edit" }, [
    _c(
      "header",
      [
        _c("edit-head", {
          ref: "editHead",
          attrs: {
            editField: false,
            saveDataType: "market",
            activeName: _vm.activeName
          },
          on: {
            "update:activeName": function($event) {
              _vm.activeName = $event
            },
            "update:active-name": function($event) {
              _vm.activeName = $event
            }
          }
        })
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [
      _c(
        "div",
        { staticClass: "active-nav" },
        [
          _c("el-scrollbar", { staticStyle: { height: "100%" } }, [
            _c(
              "ul",
              _vm._l(_vm.navList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: { active: _vm.currentNav == index },
                    on: {
                      click: function($event) {
                        return _vm.checkoutNav(item, index)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(item.label) + " ")]
                )
              }),
              0
            )
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "nav-show" },
        [
          _vm.currentPage == "homePage"
            ? _c("home-page", { ref: "homePage" })
            : _vm._e(),
          _vm.currentPage == "prizeDetails"
            ? _c("prize-details", { ref: "prizeDetails" })
            : _vm._e(),
          _vm.currentPage == "prizePage" ||
          _vm.currentPage == "notWinning" ||
          _vm.currentPage == "prizeAfter"
            ? _c("prize", {
                ref: "prize",
                attrs: { currentPage: _vm.currentPage }
              })
            : _vm._e(),
          _vm.currentPage == "shareResults"
            ? _c("img", {
                attrs: {
                  src: require("../../assets/img/marketing/u16510.png"),
                  alt: ""
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "setting" },
        [
          _c(
            "el-scrollbar",
            { staticStyle: { height: "100%" } },
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "基础设置", name: "first" } },
                    [_c("basic-setting", { ref: "basicSetting" })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "抽奖配置", name: "second" } },
                    [_c("send-way", { ref: "sendWay" })],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "奖项设置", name: "third" } },
                    [
                      _c("award-setting", {
                        ref: "awardSetting",
                        attrs: { templeType: _vm.templeType }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "高级设置", name: "fourth" } },
                    [_c("senior-setting", { ref: "seniorSetting" })],
                    1
                  ),
                  _vm.templeType == 5 ||
                  _vm.templeType == 9 ||
                  _vm.templeType == 10
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "个性化设置", name: "fifth" } },
                        [
                          _c("personalized-Setting", {
                            ref: "personalizedSetting",
                            attrs: { templeType: _vm.templeType }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.templeType == 10 || _vm.systemCode == "tai_woo_wxys"
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "监控与阀值", name: "sixth" } },
                        [_c("Monitor-Setting", { ref: "monitorSetting" })],
                        1
                      )
                    : _vm._e(),
                  _vm.templeType == 15
                    ? _c(
                        "el-tab-pane",
                        { attrs: { label: "添加海报图片", name: "seventh" } },
                        [_c("Upload-Report", { ref: "uploadReport" })],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }