var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "selfBuiltPrize" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "prizeList",
          attrs: { title: "自建奖品", visible: _vm.visible, width: "60%" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "tableShow" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.dataList,
                    border: "",
                    "header-cell-style": {
                      background: "#f5f7fa",
                      width: "64px",
                      fontWeight: "400",
                      color: "#000000"
                    }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      "header-align": "center",
                      align: "center",
                      label: "奖品名称"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "奖品图片"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.img
                              ? _c("img", {
                                  staticStyle: {
                                    width: "50px",
                                    height: "50px"
                                  },
                                  attrs: { src: scope.row.img, alt: "" }
                                })
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "创建时间"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "12px" } },
                              [_vm._v(_vm._s(scope.row.createTime))]
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.usableStock",
                      "header-align": "center",
                      align: "center",
                      label: "剩余库存",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.lockupStock",
                      "header-align": "center",
                      align: "center",
                      label: "冻结数量",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "stock.usableStock",
                      "header-align": "center",
                      align: "center",
                      label: "可用数量",
                      width: "120"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      "header-align": "center",
                      align: "center",
                      label: "状态",
                      width: "100"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.status == 1
                              ? _c("span", [_vm._v("启用")])
                              : scope.row.status == 0
                              ? _c("span", [_vm._v("禁用")])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: {
                      fixed: "right",
                      "header-align": "center",
                      align: "center",
                      width: "200",
                      label: "操作"
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled:
                                    (!!_vm.marketingActivityData.prize[0]
                                      .activityId &&
                                      _vm.marketingActivityData.activity
                                        .isPublish) ||
                                    scope.row.status == 0
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.setPrize(scope.row)
                                  }
                                }
                              },
                              [_vm._v("设置奖品")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.getIdData(scope.row.id)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            ),
                            scope.row.status == 0
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setStatus(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("启用")]
                                )
                              : scope.row.status == 1
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { color: "red" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.setStatus(scope.row)
                                      }
                                    }
                                  },
                                  [_vm._v("禁用")]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addData } },
                [_vm._v("新增奖品")]
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pageIndex,
                  "page-size": _vm.pageSize,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.totalPage
                },
                on: {
                  "size-change": _vm.sizeChangeHandle,
                  "current-change": _vm.currentChangeHandle,
                  "update:currentPage": function($event) {
                    _vm.pageIndex = $event
                  },
                  "update:current-page": function($event) {
                    _vm.pageIndex = $event
                  }
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "新增奖品", visible: _vm.isVisible, width: "30%" },
          on: {
            "update:visible": function($event) {
              _vm.isVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "120px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "奖品名称：", prop: "name" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "输入奖品名称" },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "库存：", prop: "stock.usableStock" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "输入奖品数量",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                      maxlength: "8"
                    },
                    model: {
                      value: _vm.form.stock.usableStock,
                      callback: function($$v) {
                        _vm.$set(_vm.form.stock, "usableStock", $$v)
                      },
                      expression: "form.stock.usableStock"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { ref: "img", attrs: { label: "奖品图片：", prop: "img" } },
                [
                  _c("upload-com", {
                    ref: "uploadCom",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addImgEvent }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.isVisible = false
                    }
                  }
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }