<template>
  <!-- 首页 -->
  <div class="homePage">
    <div class="rightImg">
      <img src="../../../assets/img/marketing/title.png" alt="" width="100%" class="titleImg">
      <div class="title-name">
        <i class="el-icon-close"></i>
        <span>{{ marketingActivityData.activity.name }}</span>
        <i class="el-icon-more"></i>
      </div>
      <!-- 品牌曝光显示 -->
      <div class="brand" v-if="marketingActivityData.activity.brandExposure.isShow">
        <img :src="marketingActivityData.activity.brandExposure.logo" alt="" class="brandLogo" v-if="marketingActivityData.activity.brandExposure.logo">
        <div class="slogan">
          <div class="slogan-text" :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.enterpriseSlogan ? JSON.parse(marketingActivityData.activity.templePage).paperWork.enterpriseSlogan.routineColor : '#fff'}">
            <span style="display: inline-block; min-width: 250px;">
              {{ marketingActivityData.activity.brandExposure.slogan }}
              <span style="display: inline-block; min-width: 250px"></span>
            </span>
            <span style="display: inline-block; min-width: 250px;">
              {{ marketingActivityData.activity.brandExposure.slogan }}
              <span style="display: inline-block; min-width: 250px"></span>
            </span>
            <!-- <marquee behavior="" direction="" width="300px">{{ marketingActivityData.activity.brandExposure.slogan }}</marquee> -->
          </div>
          </div>
      </div>
      <draw-mode ref="drawMode"></draw-mode>
    </div>
    <div class="draw-text"
      v-if="marketingActivityData.activity.templePage &&
      JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType === '' &&
      marketingActivityData.activity.templeType < 10">
      <p :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.joinPeople.routineColor}" v-if="marketingActivityData.activity.isJoinPeopleShow">
        已有 <span :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.joinPeople.numberColor}">0</span> 人参与
      </p>
      <p :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.dayDrawChance.routineColor}">
        你今天还有 <span :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.dayDrawChance.numberColor}">{{ marketingActivityData.limit.userDayChance }}</span> 次抽奖机会
      </p>
    </div>
    <div class="draw-text" v-else-if="marketingActivityData.activity.templePage && JSON.parse(marketingActivityData.activity.templePage).activityBase.relateTempleType !== ''">
      <p :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor, fontSize: '14px'}">
        您已邀请0名好友参与阅读，额外获0次抽奖机会
      </p>
      <p :style="{color: JSON.parse(marketingActivityData.activity.templePage).paperWork.totalDrawChance.routineColor, fontSize: '14px'}">
        您已获得0次抽奖机会，剩余0次抽奖机会
      </p>
    </div>
    <!-- <img src="../../../assets/img/marketing/u15864.png" alt="" class="showImg connectImg"> -->
    <div class="draw-content"
      v-if="marketingActivityData.activity.templeType < 10">
      <el-tabs v-model="type" class="tabs">
        <el-tab-pane label="活动说明" name="first">
          <div class="active-that">
            <h5>活动奖品</h5>
            <ul>
              <li v-for="(item, index) in marketingActivityData.prize" :key="index" class="award-item">
                <div class="award-img">
                  <img src="../../../assets/img/marketing/gift.png" alt="">
                </div>
                <div class="award-text">
                  <h3>{{ wordList[index] + '等奖' }}</h3>
                  <h3>{{ item.name }}</h3>
                </div>
              </li>
            </ul>
            <h5>活动时间</h5>
            <p>{{ marketingActivityData.activity.beginTime ? marketingActivityData.activity.beginTime + ' ~ ' : ''}}{{ marketingActivityData.activity.endTime }}</p>
            <h5>主办单位</h5>
            <p>{{ marketingActivityData.activity.companyName }}</p>
            <h5>活动规则</h5>
            <p v-if="marketingActivityData.activity.templeType == 9">您已获得0次抽奖机会，剩余0次抽奖机会</p>
            <!-- <p v-else>活动期间每人总抽奖机会{{ marketingActivityData.limit.userChance == -1 ? '不限制，' : `共${marketingActivityData.limit.userChance}次，` }}{{ `每天只能抽${marketingActivityData.limit.userDayChance }次` }}</p> -->
            <div v-else>
              <template v-if="marketingActivityData.limit.userChance > -1">
                <p>活动期间每人总抽奖机会共{{marketingActivityData.limit.userChance}}次</p>
              </template>
              <p>每天只能抽{{ marketingActivityData.limit.userDayChance }}次</p>
            </div>
            <h5>活动说明</h5>
            <p v-html="marketingActivityData.activity.description.replace(/\n/g, '<br>')"></p>
            <h5>技术支持</h5>
            <p>无限立方</p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="我的奖品" name="second">
          <div class="myAward">
            <!-- <ul>
              <li v-for="item in 3" :key="item" class="award-item">
                <img src="../../../assets/img/marketing/u18898.jpg" alt="">
                <div class="award-text">
                  <h3>一等奖</h3>
                  <p>价值100元礼品</p>
                  <p class="award-time">兑奖期限：2020-12-14 至 2020-12-14</p>
                  <div class="status">未兑奖</div>
                </div>
              </li>
            </ul> -->
            <p>暂无抽奖信息</p>
            <div class="myAward-btn">关注我们</div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DrawMode from './DrawMode.vue'

export default {
  data () {
    return {
      type: 'first',
      wordList: ['一', '二', '三', '四', '五', '六'],
    }
  },
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
  components: {
    DrawMode
  }
}
</script>

<style lang="scss" scoped>
  @keyframes move { // 企业宣传语滚动
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  .homePage {
    position: relative;
    width: 375px;
    height: 717px;
    overflow: auto;
    .draw-text {
      position: absolute;
      top: 88%;
      color: #fff;
      width: 100%;
      text-align: center;
    }
    .showImg {
      width: 100%;
    }
    .connectImg {
      position: absolute;
      left: 0;
      top: 88%;
    }
    .draw-content {
      position: absolute;
      left: 0;
      top: 95%;
      width: 100%;
      padding: 20px;
      background-color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      p {
        color: #666;
        font-size: 14px;
      }
      .award-item {
        height: 86px;
        // background: #ccc;
        border-radius: 8px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        padding: 10px;
        .award-img {
          padding: 5px 20px;
          border: 1px solid #ccc;
          border-radius: 5px;
        }
        img {
          width: 60px;
          height: 60px;
        }
        .award-text {
          position: relative;
          margin-left: 10px;
          h3 {
            font-weight: 400;
            font-size: 16px;
          }
        }
        .award-time {
          font-size: 12px;
        }
        .status {
          position: absolute;
          top: 5%;
          right: 0;
          padding: 2px 10px;
          font-size: 14px;
          background: #FF2F2F;
          border-radius: 10px;
        }
      }
    }
    .active-that {
      padding: 0 10px;
      h5 {
        margin: 10px 0;
      }
    }
    .myAward-btn {
      width: 100%;
      height: 50px;
      line-height: 50px;
      margin-top: 50px;
      text-align: center;
      background:#e6ae65;
      border-radius: 25px;
      color: #fff;
    }
    .rightImg {
      position: relative;
      float: left;
      width: 100%;
      height: 717px;
      .contentImg {
        width: 100%;
        height: 100%;
        margin-top: 21px;
      }
      .titleImg {
        width: 100%;
      }
      .title-name {
        position: absolute;
        top: 4%;
        left: 0;
        width: 100%;
        height: 30px;
        display: flex;
        font-size: 12px;
        align-items: center;
        background-color: #f2f2f2;
        z-index: 2;
        span {
          flex: 1;
          text-align: center;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
      .brand {
        position: absolute;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 22px;
        padding: 0 20px;
        background-color: rgba(0, 0, 0, .1);
        z-index: 200;
        .brandLogo {
          width: 20px;
          height: 20px;
        }
        .slogan {
          position: relative;
          width: 250px;
          height: 100%;
          margin-left: 20px;
          overflow: hidden;
          font-size: 12px;
          font-weight: 700;
          white-space: nowrap;
          .slogan-text {
            position: absolute;
            top: 10px;
            left: 0;
            animation: move 5s linear infinite;
          }
        }
      }
    }
  }
</style>
