var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "basic-setting" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.marketingActivityData.activity,
            "label-width": "220px",
            rules: _vm.rules
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "活动标题：", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "title",
                attrs: { maxlength: "50" },
                model: {
                  value: _vm.marketingActivityData.activity.name,
                  callback: function($$v) {
                    _vm.$set(_vm.marketingActivityData.activity, "name", $$v)
                  },
                  expression: "marketingActivityData.activity.name"
                }
              })
            ],
            1
          ),
          _vm.marketingActivityData.activity.templeType == 16
            ? _c(
                "el-form-item",
                { attrs: { label: "集卡活动主题：", prop: "themeId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.marketingActivityData.activity.themeId,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.activity,
                            "themeId",
                            $$v
                          )
                        },
                        expression: "marketingActivityData.activity.themeId"
                      }
                    },
                    _vm._l(_vm.themeList, function(item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "活动时间：", required: true } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "beginTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "time",
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择开始时间",
                          "picker-options": _vm.startDatePicker,
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.marketingActivityData.activity.beginTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.activity,
                              "beginTime",
                              $$v
                            )
                          },
                          expression: "marketingActivityData.activity.beginTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", { staticClass: "time-text" }, [_vm._v("至")])
              ]),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "endTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "time",
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择结束时间",
                          "picker-options": _vm.endDatePicker,
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value: _vm.marketingActivityData.activity.endTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.activity,
                              "endTime",
                              $$v
                            )
                          },
                          expression: "marketingActivityData.activity.endTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "参与人数：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.activity.isJoinPeopleShow,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.activity,
                        "isJoinPeopleShow",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.activity.isJoinPeopleShow"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("隐藏")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "获取玩家号码：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.marketingActivityData.activity.isGetPhone,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.activity,
                        "isGetPhone",
                        $$v
                      )
                    },
                    expression: "marketingActivityData.activity.isGetPhone"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("关闭")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("开启")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "活动说明：", prop: "description" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入活动说明",
                  resize: "none",
                  maxlength: "200"
                },
                model: {
                  value: _vm.marketingActivityData.activity.description,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.activity,
                      "description",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.activity.description"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }