var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "redTree",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .redTree.background.pictureUrl +
          ")"
      }
    },
    [
      _c("div", {
        staticClass: "title",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.title.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "goldCoin",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.goldCoin.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "btn btn1",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.rulesBtn.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "btn btn2",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.myPrize.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "pitLarge",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.pitLarge.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "treeLarge",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.treeLarge.pictureUrl +
            ")"
        }
      }),
      _c("div", {
        staticClass: "start",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
              .redTree.start.pictureUrl +
            ")"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }