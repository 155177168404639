<template>
<div class="myPets" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.background.pictureUrl})`}">
<!--  规则-->
  <div class="btn btn1" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.rulesBtn.pictureUrl})`}"></div>
<!--  奖品-->
  <div class="btn btn2" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.myPrize.pictureUrl})`}"></div>
<!--  标题-->
  <div class="title" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.title.pictureUrl})`}"></div>
<!--  兔子-->
  <div class="rabbit"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.rabbit.pictureUrl})`}"></div>
<!--  宝箱-->
  <div class="boxGray" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.boxGray.pictureUrl})`}">
  </div>
<!--  我的任务-->
  <div class="myTask" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.myTask.pictureUrl})`}">
  </div>
<!--  喂兔子-->
  <div class="feed" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.myPets.feed.pictureUrl})`}">
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.myPets{
  height: 682px;
  background-size: 100% 100%;
  .btn{
    width: 40px;
    height: 46px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    right: 20px;
    &.btn1{
      top: 75px;
    }
    &.btn2{
      top: 130px;
    }
  }
  .title{
    width: 70%;
    height: 80px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin: auto;
    transform: translateY(100px);
  }
  .rabbit{
    width: 200px;
    height: 300px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 360px;
  }
  .boxGray{
    width: 96px;
    height: 82px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    left: 20px;
    bottom: 42px;
  }
  .myTask{
    width: 60px;
    height: 66px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    right: 85px;
    bottom: 40px;
  }
  .feed{
    width: 70px;
    height: 76px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    right: 8px;
    bottom: 40px;
  }
}
</style>
