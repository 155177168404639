<template>
  <div class="throwPot" :style="{'backgroundImage': `url(${throwPot.background.pictureUrl})`}">
    <div class="throwPot-title">
      <img :src="throwPot.title.pictureUrl" alt="">
    </div>
    <div class="pot">
      <img :src="throwPot.pot.pictureUrl" alt="">
    </div>
    <div class="arrowMiss">
      <img :src="throwPot.arrow.pictureUrl" alt="">
    </div>
    <div class="bow">
      <img :src="throwPot.bow.pictureUrl" alt="">
    </div>
    <div class="start">
      <img :src="throwPot.start.pictureUrl" alt="">
    </div>
    <div class="chance">
      <span class="chance-text num">3次</span>
      <span class="chance-text">投壶机会</span>
      <img :src="throwPot.chance.pictureUrl" alt="">
    </div>
    <div class="rules">
      <img :src="throwPot.rulesBtn.pictureUrl" alt="">
    </div>
    <div class="rules myPrizeBtn">
      <img :src="throwPot.myPrizeBtn.pictureUrl" alt="">
    </div>
    <div class="arrow">
      <img :src="throwPot.arrow.pictureUrl" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData']),
    throwPot() {
      return JSON.parse(this.marketingActivityData.activity.templePage).drawPage.throwPot
    }
  }
}
</script>
  
<style lang="scss" scoped>
.throwPot {
  position: relative;
  width: 100%;
  height: 615px;
  background-size: 100% 100%;
  margin-top: 22px;
  img {
    width: 100%;
  }
  .throwPot-title {
      width: 370px;
      text-align: center;
    img {
      width: 65%;
      margin-top: 50px;
    }
  }
  .start {
    position: absolute;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%);
    width: 20%;
    z-index: 10;
  }
  .pot {
    width: 10%;
    margin: 100px auto 40px;
  }
  .rules {
    position: absolute;
    top: 170px;
    right: 20px;
    width: 12%;
  }
  .myPrizeBtn {
    top: 240px;
  }
  .arrowMiss {
    position: absolute;
    top: 300px;
    right: 150px;
    transform: rotate(-120deg);
    width: 4%;
  }
  .bow {
    width: 60%;
    margin: 0 auto;
  }
  .chance {
    position: relative;
    width: 40%;
    margin: 30px auto 0;
    .chance-text {
      position: absolute;
      left: 34%;
      top: 30%;
      font-size: 14px;
      color: #bc782d;
    }
    .num {
      left: 12%;
    }
  }
  .arrow {
    position: absolute;
    bottom: 90px;
    right: 150px;
    width: 6%;
    transform: rotate(30deg);
  }
}
</style>