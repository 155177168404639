var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "senior-setting" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.marketingActivityData.activity,
            "label-width": "220px"
          }
        },
        [
          _c("el-form-item", { attrs: { "label-width": "58px" } }, [
            _c("div", { staticClass: "basic-options" }, [_vm._v("企业信息")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "主办单位：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入单位名称", maxlength: "50" },
                model: {
                  value: _vm.marketingActivityData.activity.companyName,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.activity,
                      "companyName",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.activity.companyName"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客服电话：" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入客服电话", maxlength: "20" },
                model: {
                  value: _vm.marketingActivityData.activity.telephone,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.activity,
                      "telephone",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.activity.telephone"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "品牌曝光：" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.brand },
                  model: {
                    value:
                      _vm.marketingActivityData.activity.brandExposure.isShow,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.activity.brandExposure,
                        "isShow",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.activity.brandExposure.isShow"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("隐藏")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("显示")])
                ],
                1
              )
            ],
            1
          ),
          _vm.marketingActivityData.activity.brandExposure.isShow
            ? _c(
                "el-form-item",
                { attrs: { label: "企业logo：" } },
                [
                  _c("upload-com", {
                    ref: "uploadLogo",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addBrandWx }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.activity.brandExposure.isShow
            ? _c(
                "el-form-item",
                { attrs: { label: "宣传语：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入宣传语", maxlength: "50" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.brandExposure.slogan,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.brandExposure,
                          "slogan",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.brandExposure.slogan"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "功能按钮：" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.funBtnSet },
                  model: {
                    value:
                      _vm.marketingActivityData.activity.functionButton.isShow,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.activity.functionButton,
                        "isShow",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.activity.functionButton.isShow"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("隐藏")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("公众号")]),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("页面跳转")])
                ],
                1
              )
            ],
            1
          ),
          _vm.marketingActivityData.activity.functionButton.isShow == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "按钮名称：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入按钮名称，如：关注我们",
                      maxlength: "50"
                    },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.functionButton
                          .weChatButton,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.functionButton,
                          "weChatButton",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.functionButton.weChatButton"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.activity.functionButton.isShow == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "微信公众号：" } },
                [
                  _c("upload-com", {
                    ref: "uploadCom",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addImgWx }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.activity.functionButton.isShow == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "按钮名称：" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入按钮名称，如：关注我们",
                      maxlength: "50"
                    },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.functionButton
                          .pageButton,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.functionButton,
                          "pageButton",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.functionButton.pageButton"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.activity.functionButton.isShow == 2
            ? _c(
                "el-form-item",
                { attrs: { label: "按钮链接：" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入网址" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.functionButton
                          .pageButtonUrl,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.functionButton,
                          "pageButtonUrl",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.functionButton.pageButtonUrl"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "58px" } }, [
            _c("div", { staticClass: "basic-options" }, [_vm._v("分享设置")])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "分享活动：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled:
                      _vm.marketingActivityData.activity.templeType === 9
                  },
                  model: {
                    value: _vm.marketingActivityData.activity.shareData.isShare,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.activity.shareData,
                        "isShare",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.activity.shareData.isShare"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: true } }, [
                    _vm._v("开启分享")
                  ]),
                  _c("el-radio", { attrs: { label: false } }, [
                    _vm._v("关闭分享")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.marketingActivityData.activity.shareData.isShare
            ? _c(
                "el-form-item",
                { attrs: { label: "分享图标：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.sharePicture },
                      model: {
                        value:
                          _vm.marketingActivityData.activity.shareData
                            .isDefaultIcon,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.activity.shareData,
                            "isDefaultIcon",
                            $$v
                          )
                        },
                        expression:
                          "marketingActivityData.activity.shareData.isDefaultIcon"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("默认")
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("自定义")
                      ])
                    ],
                    1
                  ),
                  _c("upload-com", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.marketingActivityData.activity.shareData
                          .isDefaultIcon,
                        expression:
                          "!marketingActivityData.activity.shareData.isDefaultIcon"
                      }
                    ],
                    ref: "uploadCom1",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addImgShare }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.activity.shareData.isShare
            ? _c(
                "el-form-item",
                { attrs: { label: "分享内容：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.shareContent },
                      model: {
                        value:
                          _vm.marketingActivityData.activity.shareData
                            .isDefaultContent,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.activity.shareData,
                            "isDefaultContent",
                            $$v
                          )
                        },
                        expression:
                          "marketingActivityData.activity.shareData.isDefaultContent"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v("默认")
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v("自定义")
                      ])
                    ],
                    1
                  ),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.marketingActivityData.activity.shareData
                          .isDefaultContent,
                        expression:
                          "!marketingActivityData.activity.shareData.isDefaultContent"
                      }
                    ],
                    attrs: { placeholder: "请输入分享内容", maxlength: "200" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.shareData
                          .customContent,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.shareData,
                          "customContent",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.shareData.customContent"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }