var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "homePage" }, [
    _c(
      "div",
      { staticClass: "rightImg" },
      [
        _c("img", {
          staticClass: "titleImg",
          attrs: {
            src: require("../../../assets/img/marketing/title.png"),
            alt: "",
            width: "100%"
          }
        }),
        _c("div", { staticClass: "title-name" }, [
          _c("i", { staticClass: "el-icon-close" }),
          _c("span", [_vm._v(_vm._s(_vm.marketingActivityData.activity.name))]),
          _c("i", { staticClass: "el-icon-more" })
        ]),
        _vm.marketingActivityData.activity.brandExposure.isShow
          ? _c("div", { staticClass: "brand" }, [
              _vm.marketingActivityData.activity.brandExposure.logo
                ? _c("img", {
                    staticClass: "brandLogo",
                    attrs: {
                      src:
                        _vm.marketingActivityData.activity.brandExposure.logo,
                      alt: ""
                    }
                  })
                : _vm._e(),
              _c("div", { staticClass: "slogan" }, [
                _c(
                  "div",
                  {
                    staticClass: "slogan-text",
                    style: {
                      color: JSON.parse(
                        _vm.marketingActivityData.activity.templePage
                      ).paperWork.enterpriseSlogan
                        ? JSON.parse(
                            _vm.marketingActivityData.activity.templePage
                          ).paperWork.enterpriseSlogan.routineColor
                        : "#fff"
                    }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "min-width": "250px"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.marketingActivityData.activity.brandExposure
                                .slogan
                            ) +
                            " "
                        ),
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            "min-width": "250px"
                          }
                        })
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "min-width": "250px"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.marketingActivityData.activity.brandExposure
                                .slogan
                            ) +
                            " "
                        ),
                        _c("span", {
                          staticStyle: {
                            display: "inline-block",
                            "min-width": "250px"
                          }
                        })
                      ]
                    )
                  ]
                )
              ])
            ])
          : _vm._e(),
        _c("draw-mode", { ref: "drawMode" })
      ],
      1
    ),
    _vm.marketingActivityData.activity.templePage &&
    JSON.parse(_vm.marketingActivityData.activity.templePage).activityBase
      .relateTempleType === "" &&
    _vm.marketingActivityData.activity.templeType < 10
      ? _c("div", { staticClass: "draw-text" }, [
          _vm.marketingActivityData.activity.isJoinPeopleShow
            ? _c(
                "p",
                {
                  style: {
                    color: JSON.parse(
                      _vm.marketingActivityData.activity.templePage
                    ).paperWork.joinPeople.routineColor
                  }
                },
                [
                  _vm._v(" 已有 "),
                  _c(
                    "span",
                    {
                      style: {
                        color: JSON.parse(
                          _vm.marketingActivityData.activity.templePage
                        ).paperWork.joinPeople.numberColor
                      }
                    },
                    [_vm._v("0")]
                  ),
                  _vm._v(" 人参与 ")
                ]
              )
            : _vm._e(),
          _c(
            "p",
            {
              style: {
                color: JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .paperWork.dayDrawChance.routineColor
              }
            },
            [
              _vm._v(" 你今天还有 "),
              _c(
                "span",
                {
                  style: {
                    color: JSON.parse(
                      _vm.marketingActivityData.activity.templePage
                    ).paperWork.dayDrawChance.numberColor
                  }
                },
                [_vm._v(_vm._s(_vm.marketingActivityData.limit.userDayChance))]
              ),
              _vm._v(" 次抽奖机会 ")
            ]
          )
        ])
      : _vm.marketingActivityData.activity.templePage &&
        JSON.parse(_vm.marketingActivityData.activity.templePage).activityBase
          .relateTempleType !== ""
      ? _c("div", { staticClass: "draw-text" }, [
          _c(
            "p",
            {
              style: {
                color: JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .paperWork.totalDrawChance.routineColor,
                fontSize: "14px"
              }
            },
            [_vm._v(" 您已邀请0名好友参与阅读，额外获0次抽奖机会 ")]
          ),
          _c(
            "p",
            {
              style: {
                color: JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .paperWork.totalDrawChance.routineColor,
                fontSize: "14px"
              }
            },
            [_vm._v(" 您已获得0次抽奖机会，剩余0次抽奖机会 ")]
          )
        ])
      : _vm._e(),
    _vm.marketingActivityData.activity.templeType < 10
      ? _c(
          "div",
          { staticClass: "draw-content" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "tabs",
                model: {
                  value: _vm.type,
                  callback: function($$v) {
                    _vm.type = $$v
                  },
                  expression: "type"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "活动说明", name: "first" } },
                  [
                    _c("div", { staticClass: "active-that" }, [
                      _c("h5", [_vm._v("活动奖品")]),
                      _c(
                        "ul",
                        _vm._l(_vm.marketingActivityData.prize, function(
                          item,
                          index
                        ) {
                          return _c(
                            "li",
                            { key: index, staticClass: "award-item" },
                            [
                              _c("div", { staticClass: "award-img" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("../../../assets/img/marketing/gift.png"),
                                    alt: ""
                                  }
                                })
                              ]),
                              _c("div", { staticClass: "award-text" }, [
                                _c("h3", [
                                  _vm._v(_vm._s(_vm.wordList[index] + "等奖"))
                                ]),
                                _c("h3", [_vm._v(_vm._s(item.name))])
                              ])
                            ]
                          )
                        }),
                        0
                      ),
                      _c("h5", [_vm._v("活动时间")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.marketingActivityData.activity.beginTime
                              ? _vm.marketingActivityData.activity.beginTime +
                                  " ~ "
                              : ""
                          ) + _vm._s(_vm.marketingActivityData.activity.endTime)
                        )
                      ]),
                      _c("h5", [_vm._v("主办单位")]),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.marketingActivityData.activity.companyName)
                        )
                      ]),
                      _c("h5", [_vm._v("活动规则")]),
                      _vm.marketingActivityData.activity.templeType == 9
                        ? _c("p", [
                            _vm._v("您已获得0次抽奖机会，剩余0次抽奖机会")
                          ])
                        : _c(
                            "div",
                            [
                              _vm.marketingActivityData.limit.userChance > -1
                                ? [
                                    _c("p", [
                                      _vm._v(
                                        "活动期间每人总抽奖机会共" +
                                          _vm._s(
                                            _vm.marketingActivityData.limit
                                              .userChance
                                          ) +
                                          "次"
                                      )
                                    ])
                                  ]
                                : _vm._e(),
                              _c("p", [
                                _vm._v(
                                  "每天只能抽" +
                                    _vm._s(
                                      _vm.marketingActivityData.limit
                                        .userDayChance
                                    ) +
                                    "次"
                                )
                              ])
                            ],
                            2
                          ),
                      _c("h5", [_vm._v("活动说明")]),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.marketingActivityData.activity.description.replace(
                              /\n/g,
                              "<br>"
                            )
                          )
                        }
                      }),
                      _c("h5", [_vm._v("技术支持")]),
                      _c("p", [_vm._v("无限立方")])
                    ])
                  ]
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "我的奖品", name: "second" } },
                  [
                    _c("div", { staticClass: "myAward" }, [
                      _c("p", [_vm._v("暂无抽奖信息")]),
                      _c("div", { staticClass: "myAward-btn" }, [
                        _vm._v("关注我们")
                      ])
                    ])
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }