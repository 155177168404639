var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bigReverse",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .bigReverse.background.pictureUrl +
          ")"
      }
    },
    [
      _c("div", { staticClass: "bigReverse-title" }, [
        _c("img", {
          attrs: {
            src: JSON.parse(_vm.marketingActivityData.activity.templePage)
              .drawPage.bigReverse.title.pictureUrl,
            alt: ""
          }
        })
      ]),
      _c(
        "ul",
        { staticClass: "bigReversePrize" },
        _vm._l(
          _vm.bigReverseData[_vm.marketingActivityData.prize.length],
          function(item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "prize-item",
                style: {
                  backgroundImage:
                    "url(" +
                    JSON.parse(_vm.marketingActivityData.activity.templePage)
                      .drawPage.bigReverse.prizeBackground.pictureUrl +
                    ")"
                }
              },
              [
                _vm.marketingActivityData.prize[item.img]
                  ? _c("img", {
                      attrs: {
                        src: JSON.parse(
                          _vm.marketingActivityData.prize[item.img]
                            .prizePictureUrl
                        ).url,
                        alt: ""
                      }
                    })
                  : item.img == "xx"
                  ? _c("img", {
                      attrs: {
                        src: JSON.parse(
                          _vm.marketingActivityData.activity.templePage
                        ).drawPage.defaultPrize.welcome.pictureUrl,
                        alt: ""
                      }
                    })
                  : item.img == ""
                  ? _c("img", {
                      staticClass: "bigReverse-button",
                      attrs: {
                        src: JSON.parse(
                          _vm.marketingActivityData.activity.templePage
                        ).drawPage.bigReverse.button.pictureUrl,
                        alt: ""
                      }
                    })
                  : _vm._e()
              ]
            )
          }
        ),
        0
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }