<template>
  <!-- 中奖/没中奖/奖品派完页 -->
  <div class="prize">
    <img src="../../../assets/img/marketing/title.png" alt="" width="100%" class="titleImg">
    <div class="title-name">
      <i class="el-icon-close"></i>
      <span>{{ marketingActivityData.activity.name }}</span>
      <i class="el-icon-more"></i>
    </div>
    <div class="prize-content"
    :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).activityBase.cover})`}">
      <div class="prize-show">
        <img :src="marketingActivityData.prize[0].imgUrl.defaultImg" v-if="marketingActivityData.prize[0].imgUrl.type == 'default'" alt="">
        <img :src="marketingActivityData.prize[0].imgUrl.productImg" v-else-if="marketingActivityData.prize[0].imgUrl.type == 'product'" alt="">
        <img :src="marketingActivityData.prize[0].imgUrl.uploadImg" v-else-if="marketingActivityData.prize[0].imgUrl.type == 'upload'" alt="">
        <div v-if="currentPage == 'prizePage'">
          <!-- <h3>一等奖</h3> -->
          <p>{{ marketingActivityData.prize[0].name }}</p>
        </div>
        <div v-else-if="currentPage == 'notWinning'">
          <p>很遗憾！与奖品擦肩而过！</p>
        </div>
        <div v-else-if="currentPage == 'prizeAfter'">
          <p>奖品已派完，下次请早哦</p>
        </div>
        <div v-if="currentPage == 'prizePage'">
          <div class="prize-btn">查看奖品详情</div>
          <div class="prize-btn back">返回首页</div>
        </div>
        <div v-else-if="currentPage == 'notWinning'">
          <div class="prize-btn back">再来一次</div>
        </div>
        <div v-else-if="currentPage == 'prizeAfter'">
          <div class="prize-btn back">再来一次</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: ['currentPage'],
  computed:{
    ...mapState('market',['marketingActivityData'])
  }
}
</script>

<style lang="scss" scoped>
  .prize {
    position: relative;
    width: 375px;
    height: 717px;
    overflow: auto;
    .prize-content {
      width: 100%;
      height: 92%;
      // background: url("../../../assets/img/marketing/u15794.png") no-repeat;
      background-size: 100% 100%;
      .prize-show {
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(0, 0, 0, 0.7);
        img {
          margin: 200px 0 20px;
          width: 100px;
          height: 100px;
        }
        h3 {
          color: #f6d311;
        }
        p {
          color: #fff;
          margin: 10px 0 30px;
        }
        .prize-btn {
          width: 60%;
          height: 40px;
          line-height: 40px;
          border-radius: 20px;
          text-align: center;
          margin: 0 20% 10px;
          background-color: #ef3869;
        }
        .back {
          background-color: #eab36c;
        }
      }
    }
    .titleImg {
        width: 100%;
      }
      .title-name {
        position: absolute;
        top: 4%;
        left: 0;
        width: 100%;
        height: 30px;
        display: flex;
        font-size: 12px;
        align-items: center;
        background-color: #f2f2f2;
        span {
          flex: 1;
          text-align: center;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
      }
  }
</style>