<template>
  <!--  画金蛋 -->
  <div class="paintGoldenEgg" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.background.pictureUrl})`}">
    <div :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.frame.pictureUrl})`}" class="frame">
      <h5>{{ marketingActivityData.activity.activityName }}</h5>
      <p class="tips">请选择画框</p>
      <ul class="select">
        <li>
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_01_s.pictureUrl" alt="">
        </li>
        <li>
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_02_s.pictureUrl" alt="">
        </li>
        <li>
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_03_s.pictureUrl" alt="">
        </li>
        <li>
          <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_04_s.pictureUrl" alt="">
        </li>
      </ul>
      <div class="bord">
        <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_01_b.pictureUrl" alt="">
      </div>
      <p class="describe">{{ JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.bord_01_b.description }}</p>
    </div>
    <ul class="btn">
      <li :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.eggBtn.pictureUrl})`}">画好了</li>
      <li :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.paintGoldenEgg.eggBtn.pictureUrl})`}">重新画</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
}
</script>

<style lang="scss" scoped>
  .paintGoldenEgg {
    position: relative;
    width: 100%;
    height: 615px;
    background-size: 100% 100%;
    margin-top: 22px;
    padding: 10px;
    .frame {
      width: 100%;
      height: 80%;
      background-size: 100% 100%;
      text-align: center;
      padding: 30px 20px;
      .tips {
        font-size: 14px;
        margin: 10px 0 20px 0;
      }
      .select {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 20px;
        li {
          width: 22%;
          height: 50px;
          border-radius: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
          }
        }
      }
      .bord {
        width: 100%;
        height: 210px;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }
      }
      .describe {
        background-color: #fdf5ed;
        margin-top: 20px;
        font-size: 14px;
        padding: 10px;
        border-radius: 5px;
      }
    }
    .btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      width: 100%;
      height: 40px;
      margin-top: 10px;
      li {
        width: 45%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-size: 100% 100%;
        color: #fff;
        border-radius: 5px;
      }
    }
  }
</style>