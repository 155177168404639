<template>
<div class="sign" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.background.pictureUrl})`}">
  <div class="title"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.title.pictureUrl})`}"></div>
  <div class="btn btn1"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.rulesBtn.pictureUrl})`}"></div>
  <div class="btn btn2" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.myPrize.pictureUrl})`}"></div>
  <div class="prizeBox"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.prizeBox.pictureUrl})`}"></div>
  <div class="signBtn"
       :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.sign.signBtn.pictureUrl})`}"></div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  }
}
</script>

<style lang="scss" scoped>
.sign {
  height: 682px;
  background-size: 100% 100%;
  position: relative;
  *{
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
  }
  .title{
    width: 100%;
    height: 100px;
    right: 0px;
    left: 0;
    margin: auto;
    top: 100px;
  }
  .btn{
    width: 50px;
    height: 80px;
    position: absolute;
    right: 20px;
    &.btn1{
      top: 60px;
    }
    &.btn2{
      top: 130px;
    }
  }
  .prizeBox{
    width: 100%;
    height: 250px;
    position: absolute;
    top: 220px;
  }
  .signBtn{
    width: 200px;
    height: 60px;
    left: 0;
    right: 0;
    top: 540px;
    margin: auto;
  }
}
</style>
