<template>
<div class="goHome" :style="{backgroundImage: `url(${typeData.background.pictureUrl})`}">
  <div class="music" :style="{backgroundImage: `url(${typeData.music.pictureUrl})`}"></div>
  <div class="btnBgimg" :style="{backgroundImage: `url(${typeData.btnBgimg.pictureUrl})`}">参与活动</div>
  <div style="height: 44px"></div>
  <div class="text">
    <p>此刻，</p>
    <p>你与家乡的距离是...</p>
  </div>
  <div class="text">
    <p>温暖回家路，为爱加油</p>
    <p>测试回家路程，赢取回家好礼</p>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  data () {
    return {
      typeData: {}
    }
  },
  mounted () {
    this.typeData = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.goHome
  },
  methods: {}
}
</script>

<style lang="scss"  scoped>
.goHome{
  height: 682px;
  background-size: 100% 100%;
  position: relative;
  .text{
    margin-left: 20px;
    color: #fff;
    font-size: 22px;
    font-weight: 500;
    &:nth-of-type(4){
      margin-bottom: 20px;
    }
  }
  .music{
    width: 40px;
    height: 40px;
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
    top: 50px;
    right: 20px;
  }
  .btnBgimg{
    width: 255px;
    height: 50px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: auto;
    color: #fff;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
  }
}
</style>
