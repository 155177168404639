<template>
  <div class="collectCrad" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.collectCard.entryBackgroundImg.pictureUrl})`}">

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  mounted () {
    // console.log('collectCrad的this.marketingActivityData:', this.marketingActivityData)
  }
}
</script>

<style lang="scss" scoped>
.collectCrad{
  height: 684px;
  background-size: 100% 100%;
}
</style>
