var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "scratchTicket",
      style: {
        backgroundImage: "url(" + _vm.scratchTicket.startBgc.pictureUrl + ")"
      }
    },
    [
      _c("div", { staticClass: "scratchTicket-title" }, [
        _c("img", {
          attrs: { src: _vm.scratchTicket.title.pictureUrl, alt: "" }
        }),
        _vm.marketingActivityData.activity.beginTime &&
        _vm.marketingActivityData.activity.endTime
          ? _c("p", { staticClass: "time" }, [
              _vm._v(
                " " +
                  _vm._s(
                    "活动时间：" +
                      _vm.marketingActivityData.activity.beginTime.split(
                        /[-' ']/
                      )[1] +
                      "月" +
                      _vm.marketingActivityData.activity.beginTime.split(
                        /[-' ']/
                      )[2] +
                      "日-" +
                      _vm.marketingActivityData.activity.endTime.split(
                        /[-' ']/
                      )[1] +
                      "月" +
                      _vm.marketingActivityData.activity.endTime.split(
                        /[-' ']/
                      )[2] +
                      "日"
                  ) +
                  " "
              )
            ])
          : _vm._e()
      ]),
      _c("div", { staticClass: "start" }, [
        _c("img", {
          attrs: { src: _vm.scratchTicket.start.pictureUrl, alt: "" }
        })
      ]),
      _c("div", { staticClass: "rules" }, [
        _c("img", {
          attrs: { src: _vm.scratchTicket.rulesBtn.pictureUrl, alt: "" }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }