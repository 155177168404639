<template>
  <div class="monitor-setting">
    <!-- 监控与阀值 -->
    <el-form :model="marketingActivityData.monitor" :disabled="$route.query.id" label-width="220px">
      <el-form-item label-width="40px">
        <div class="lucky-draw">监控配置</div>
      </el-form-item>
      <el-form-item label="是否开启监控报警：">
        <el-radio-group v-model="marketingActivityData.monitor.isOpenMonitor">
          <el-radio v-for="(radio,index) of radioList"
            :disabled="$route.query.activityStatus == 0"
            :label="radio.value">{{radio.label}}</el-radio>
        </el-radio-group>
			<el-tag type="danger" style="margin-left: 10px;" v-if="$route.query.activityStatus == 0">活动发布后可配置监控</el-tag>
      </el-form-item>
      <template v-if="marketingActivityData.monitor.isOpenMonitor">
        <el-form-item label="报警设置：">
          <el-checkbox-group v-model="marketingActivityData.monitor.monitorIndex" @change="changeMonitorPrize">
           <el-checkbox v-for="(prize,index) of marketingActivityData.prize"
                         :disabled="!marketingActivityData.prize[index].name"
                         :title="!marketingActivityData.prize[index].name ? `您尚未配置奖项${ wordList[index] }` : ''"
                         :key="index" :label="prize.id">
              奖项{{ wordList[index] }}
            </el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <template v-for="(mI,i) of marketingActivityData.monitor.monitorIndex">
          <template v-for="(item,index) of marketingActivityData.prize">
            <template v-if="item.id == mI">
              <el-form-item :label="`奖项${wordList[index]}各项阀值：`" :key="index"
                            v-if="marketingActivityData.monitor.monitorPrize.length == marketingActivityData.monitor.monitorIndex.length">
                <div class="inputFLex">
                  已发放奖品数量：<el-input oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.monitorPrize[i].prizeNumWarn"></el-input>
                  库存：<el-input
				  oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.monitorPrize[i].inventoryWarn"></el-input>
				  <template v-if="item.productType == 'redpacket'">
					  总金额：<el-input
					  oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
					  v-model="marketingActivityData.monitor.monitorPrize[i].amountPrizeWarn"></el-input>
				  </template>
                  中奖人数：<el-input
				  oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.monitorPrize[i].populationWarn"></el-input>
                </div>
              </el-form-item>
            </template>
          </template>
        </template>
		 <!-- v-if="marketingActivityData.monitor.monitorMoney" -->
		<template>
			<el-form-item label="总金额：">
			  <el-input v-model="marketingActivityData.monitor.amountCountWarn"
				   oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
				   placeholder="请输入总金额"></el-input>
			</el-form-item>
			<el-form-item label="单日总金额：">
			  <el-input v-model="marketingActivityData.monitor.amountDayCountWarn"
				   oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
				   placeholder="请输入单日总金额"></el-input>
			</el-form-item>
		</template>
      </template>
      <el-form-item label-width="40px">
        <div class="lucky-draw">阀值配置</div>
      </el-form-item>
      <el-form-item label="是否开启阈值处理：">
        <el-radio-group v-model="marketingActivityData.monitor.isOpenValve">
		  <el-radio v-for="(radio,index) of radioList"
		  	:disabled="$route.query.activityStatus == 0"
		  	:label="radio.value">{{radio.label}}</el-radio>
        </el-radio-group>
		<el-tag type="danger" style="margin-left: 10px;" v-if="$route.query.activityStatus == 0">活动发布后可配置监控</el-tag>
      </el-form-item>
      <template v-if="marketingActivityData.monitor.isOpenValve">
        <el-form-item label="触发阀值处理：">
          <el-checkbox-group v-model="marketingActivityData.monitor.handle">
            <el-checkbox :label="1" title="暂不可选">暂停抽奖</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="报警设置：">
          <el-checkbox-group v-model="marketingActivityData.monitor.valveIndex" @change="changeValvePrize">
            <el-checkbox v-for="(prize,index) of marketingActivityData.prize"
                         :disabled="!marketingActivityData.prize[index].name"
                         :title="!marketingActivityData.prize[index].name ? `您尚未配置奖项${ wordList[index] }` : ''"
                         :key="index" :label="prize.id">
						 奖项{{ wordList[index] }}
			</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <template v-for="(mI,i) of marketingActivityData.monitor.valveIndex">
          <template v-for="(item,index) of marketingActivityData.prize">
            <template v-if="item.id == mI">
              <el-form-item :label="`奖项${wordList[index]}各项阀值：`" :key="index+8"
                            v-if="marketingActivityData.monitor.valvePrize.length == marketingActivityData.monitor.valveIndex.length">
                <div class="inputFLex">
                  已发放奖品数量：<el-input
				  oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.valvePrize[i].prizeNumAlarm"></el-input>
                  库存：<el-input
				  oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.valvePrize[i].inventoryAlarm"></el-input>
				  <template v-if="item.productType == 'redpacket'">
					  总金额：<el-input
					  oninput="value=value.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')"
					  v-model="marketingActivityData.monitor.valvePrize[i].amountPrizeAlarm"></el-input>
				  </template>
                  中奖人数：<el-input
				  oninput="value=value.replace(/^0|[^0-9]/g, '')"
				  v-model="marketingActivityData.monitor.valvePrize[i].populationAlarm"></el-input>
                </div>
              </el-form-item>
            </template>
          </template>
        </template>
		 <!-- v-if="marketingActivityData.monitor.monitorMoney" -->
		<template>
			<el-form-item label="总金额：">
			  <el-input v-model="marketingActivityData.monitor.amountCountAlarm" placeholder="请输入总金额"></el-input>
			</el-form-item>
			<el-form-item label="单日总金额：">
			  <el-input v-model="marketingActivityData.monitor.amountDayCountAlarm" placeholder="请输入单日总金额"></el-input>
			</el-form-item>
		</template>
      </template>
      <el-form-item label-width="40px">
        <div class="lucky-draw">通知邮箱</div>
      </el-form-item>
      <el-form-item label="通知邮箱：">
        <el-input type="text" :disabled="$route.query.activityStatus == 0" v-model="marketingActivityData.monitor.noticeEmail" placeholder="请输入邮箱"></el-input>
		<el-tag type="danger" v-if="$route.query.activityStatus == 0">活动发布后可配置监控</el-tag>
	  </el-form-item>
    </el-form>
  </div>

</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'monitorSetting',
  data () {
    return {
		radioList: [
			{
				label: '是',
				value: 1
			},
			{
				label: '否',
				value: 0
			}
		],
      wordList: ['一', '二', '三', '四', '五', '六', '七', '八'],
      monitorPrize: [],
      handlerMonitor: {},
	  handlerValve: {}
    }
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  mounted () {
	  if(this.marketingActivityData.monitors) {
		  this.handlerMonitor = {}
		  this.marketingActivityData.monitors.forEach((item)=>{
		  		  let { prizeNumWarn, inventoryWarn, amountPrizeWarn, populationWarn } = item
		  		  if(prizeNumWarn || inventoryWarn || amountPrizeWarn || populationWarn){
					  if(amountPrizeWarn){
						  item.amountPrizeWarn = item.amountPrizeWarn/100
					  }
		  			    this.handlerMonitor[item.prizeId] = item
		  		  }
		  })
		  this.handlerValve = {}
		  this.marketingActivityData.monitors.forEach((item)=>{
		  		 let { prizeNumAlarm,inventoryAlarm,amountPrizeAlarm,populationAlarm } = item
		  		 if(prizeNumAlarm || inventoryAlarm || amountPrizeAlarm || populationAlarm){
					 if(amountPrizeAlarm){
						 item.amountPrizeAlarm = item.amountPrizeAlarm/100
					 }
		  			 this.handlerValve[item.prizeId] = item
		  		  }
		  })
	  }

  },
  methods: {
    ...mapMutations('market', ['setPrizeMonitorConfig','setPrizeValveConfig']),
    changeMonitorPrize (val) {
		console.log(val, this.marketingActivityData)
      for (const item of val) {
        if (!this.handlerMonitor.hasOwnProperty(item)) {
          const obj = {
            prizeNumWarn: null,
            inventoryWarn: null,
            amountPrizeWarn: null,
            populationWarn: null,
            noticeEmail: '',
            activityId: this.$route.query.activityId || '',
            prizeId: item,
            id: ''
          }
          this.handlerMonitor[item] = obj
        }
      }
      for (const k in this.handlerMonitor) {
        if (!val.includes(k)) {
          delete this.handlerMonitor[k]
        }
      }
      this.setPrizeMonitorConfig(Object.values(this.handlerMonitor))
    },
    changeValvePrize (val) {
      for (const item of val) {
        if (!this.handlerValve.hasOwnProperty(item)) {
          const obj = {
            prizeNumAlarm: null,
            inventoryAlarm: null,
            amountPrizeAlarm: null,
            populationAlarm: null,
            noticeEmail: '',
            activityId: this.$route.query.activityId || '',
            prizeId: item,
            id: ''
          }
          this.handlerValve[item] = obj
        }
      }
      for (const k in this.handlerValve) {
        if (!val.includes(k)) {
          delete this.handlerValve[k]
        }
      }
      this.setPrizeValveConfig(Object.values(this.handlerValve))
    }
  }
}
</script>

<style lang="scss" scoped>
.monitor-setting{
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 34px;
  }
  .inputFLex {
    color: #606266;
    /deep/ .el-input{
      width: 100px;
      margin-right: 15px;
    }
    /deep/ .el-input__inner {
      width: 100px;
    }
  }
  .lucky-draw {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
}
</style>
