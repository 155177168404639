var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "redFlagGuard",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .redFlagGuard.background.pictureUrl +
          ")"
      }
    },
    [
      _c("div", { staticClass: "redFlagGuard-title" }, [
        _c("img", {
          attrs: {
            src: JSON.parse(_vm.marketingActivityData.activity.templePage)
              .drawPage.redFlagGuard.title.pictureUrl,
            alt: ""
          }
        })
      ]),
      _c("div", { staticClass: "redFlagGuard-start" }, [
        _c("img", {
          attrs: {
            src: JSON.parse(_vm.marketingActivityData.activity.templePage)
              .drawPage.redFlagGuard.buttonBgc.pictureUrl,
            alt: ""
          }
        }),
        _c("span", { staticClass: "toAnswer" }, [_vm._v("开始答题")])
      ]),
      _c("div", { staticClass: "redFlagGuard-secret" }, [
        _c("img", {
          attrs: {
            src: JSON.parse(_vm.marketingActivityData.activity.templePage)
              .drawPage.redFlagGuard.secret.pictureUrl,
            alt: ""
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }