var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "prize" }, [
    _c("img", {
      staticClass: "titleImg",
      attrs: {
        src: require("../../../assets/img/marketing/title.png"),
        alt: "",
        width: "100%"
      }
    }),
    _c("div", { staticClass: "title-name" }, [
      _c("i", { staticClass: "el-icon-close" }),
      _c("span", [_vm._v(_vm._s(_vm.marketingActivityData.activity.name))]),
      _c("i", { staticClass: "el-icon-more" })
    ]),
    _c(
      "div",
      {
        staticClass: "prize-content",
        style: {
          backgroundImage:
            "url(" +
            JSON.parse(_vm.marketingActivityData.activity.templePage)
              .activityBase.cover +
            ")"
        }
      },
      [
        _c("div", { staticClass: "prize-show" }, [
          _vm.marketingActivityData.prize[0].imgUrl.type == "default"
            ? _c("img", {
                attrs: {
                  src: _vm.marketingActivityData.prize[0].imgUrl.defaultImg,
                  alt: ""
                }
              })
            : _vm.marketingActivityData.prize[0].imgUrl.type == "product"
            ? _c("img", {
                attrs: {
                  src: _vm.marketingActivityData.prize[0].imgUrl.productImg,
                  alt: ""
                }
              })
            : _vm.marketingActivityData.prize[0].imgUrl.type == "upload"
            ? _c("img", {
                attrs: {
                  src: _vm.marketingActivityData.prize[0].imgUrl.uploadImg,
                  alt: ""
                }
              })
            : _vm._e(),
          _vm.currentPage == "prizePage"
            ? _c("div", [
                _c("p", [
                  _vm._v(_vm._s(_vm.marketingActivityData.prize[0].name))
                ])
              ])
            : _vm.currentPage == "notWinning"
            ? _c("div", [_c("p", [_vm._v("很遗憾！与奖品擦肩而过！")])])
            : _vm.currentPage == "prizeAfter"
            ? _c("div", [_c("p", [_vm._v("奖品已派完，下次请早哦")])])
            : _vm._e(),
          _vm.currentPage == "prizePage"
            ? _c("div", [
                _c("div", { staticClass: "prize-btn" }, [
                  _vm._v("查看奖品详情")
                ]),
                _c("div", { staticClass: "prize-btn back" }, [
                  _vm._v("返回首页")
                ])
              ])
            : _vm.currentPage == "notWinning"
            ? _c("div", [
                _c("div", { staticClass: "prize-btn back" }, [
                  _vm._v("再来一次")
                ])
              ])
            : _vm.currentPage == "prizeAfter"
            ? _c("div", [
                _c("div", { staticClass: "prize-btn back" }, [
                  _vm._v("再来一次")
                ])
              ])
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }