<template>
  <!-- 抽奖配置 -->
  <div class="send-way">
    <el-form
      ref="form"
      :model="marketingActivityData.limit"
      :disabled="[19,23,24].includes(marketingActivityData.activity.templeType)"
      label-width="220px"
    >
      <el-tag v-if="[19,23,24].includes(marketingActivityData.activity.templeType)" style="margin-left: 40px" type="danger">此模板类型不支持下列配置</el-tag>
      <!-- 抽奖限制 -->
      <el-form-item label-width="40px">
        <div class="lucky-draw">抽奖限制</div>
      </el-form-item>
      <el-form-item label="参与人数限制：">
        <el-radio-group v-model="marketingActivityData.limit.userJoin">
          <el-radio :label="-1">不限制</el-radio>
          <el-radio :label="userJoin" class="limit">限制</el-radio>
          <el-input
            v-model="userJoin"
            v-if="marketingActivityData.limit.userJoin != -1"
            @input="marketingActivityData.limit.userJoin = userJoin"
            maxlength="8"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
        </el-radio-group>
        <span
          class="limit-text"
          v-if="marketingActivityData.limit.userJoin != -1"
          >人</span
        >
      </el-form-item>
      <el-form-item label="可参与地区：" prop="region">
        <el-radio-group v-model="region">
          <el-radio :label="0">全部</el-radio>
          <el-radio :label="1">部分</el-radio>
          <el-button type="text" v-if="region == 1" @click="visible = true"
            >设置</el-button
          >
        </el-radio-group>
      </el-form-item>
      <!-- 抽奖次数 -->
      <template
        v-if="
          marketingActivityData.activity.templeType != 9 &&
          marketingActivityData.activity.templeType != 5
        "
      >
        <el-form-item label-width="40px">
          <div class="lucky-draw">抽奖次数</div>
        </el-form-item>
        <el-form-item label="活动总抽奖机会：">
          <el-radio-group v-model="marketingActivityData.limit.totalChance">
            <el-radio :label="-1">不限制</el-radio>
            <el-radio :label="totalChance">限制</el-radio>
          </el-radio-group>
          <div
            v-if="marketingActivityData.limit.totalChance != -1"
            style="display: inline-block"
          >
            <span class="limit-text"> 本次活动最多有</span>
            <el-input
              v-model="totalChance"
              @input="marketingActivityData.limit.totalChance = totalChance"
              maxlength="8"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <span class="limit-text">次抽奖机会</span>
          </div>
        </el-form-item>
        <el-form-item label="每人总抽奖机会：" v-if="[10,21,22].includes(marketingActivityData.activity.templeType) == false && systemCode != 'tai_woo_wxys'">
          <el-radio-group v-model="marketingActivityData.limit.userChance">
            <el-radio :label="-1">不限制</el-radio>
            <el-radio :label="userChance">限制</el-radio>
          </el-radio-group>
          <div
            v-if="marketingActivityData.limit.userChance != -1"
            style="display: inline-block"
          >
            <span class="limit-text"> 每人最多有</span>
            <el-input
              v-model="userChance"
              @input="marketingActivityData.limit.userChance = userChance"
              maxlength="8"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <span class="limit-text">次抽奖机会</span>
          </div>
        </el-form-item>
        <el-form-item label="每人每日抽奖机会：">
          <!-- v-if="systemCode != 'tai_woo_wxys'" -->
          <span class="limit-text">每人每日有</span>
          <el-input
            v-model="marketingActivityData.limit.userDayChance"
            maxlength="8"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
          <span class="limit-text">次抽奖机会</span>
        </el-form-item>
      </template>
      <!-- 中奖率 -->
      <el-form-item label-width="40px">
        <div class="lucky-draw">中奖次数</div>
      </el-form-item>
      <el-form-item label="每人中奖次数：">
        <span class="limit-text">每人最多可中奖</span>
        <el-input
          v-model="marketingActivityData.limit.userWin"
          maxlength="8"
          oninput="value=value.replace(/[^\d]/g,'')"
        ></el-input>
        <span class="limit-text">次</span>
      </el-form-item>
      <el-form-item label="每人每日中奖次数：">
        <el-radio-group v-model="marketingActivityData.limit.userDayWin">
          <el-radio :label="-1">不限制</el-radio>
          <el-radio :label="userDayWin">限制</el-radio>
        </el-radio-group>
        <div
          v-if="marketingActivityData.limit.userDayWin != -1"
          style="display: inline-block"
        >
          <span class="limit-text"> 每人每日最多可中奖</span>
          <el-input
            v-model="userDayWin"
            @input="marketingActivityData.limit.userDayWin = userDayWin"
            maxlength="8"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
          <span class="limit-text">次</span>
        </div>
      </el-form-item>
      <template v-if="systemCode == 'tai_woo_wxys'">
        <!-- 此仅针对云溯用户配置 -->
        <template v-if="marketingActivityData.activity.templeType != 16">
          <el-form-item label-width="40px">
            <div class="lucky-draw">扫码抽奖次数</div>
          </el-form-item>
          <el-form-item label="扫码抽奖次数：">
            <el-input
              v-model="marketingActivityData.limit.barcodeChance"
              maxlength="8"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <span class="limit-text">次</span>
          </el-form-item>
        </template>
        <template v-if="marketingActivityData.activity.templeType == 21">
          <el-form-item label-width="40px">
            <div class="lucky-draw">扫码获得萝卜</div>
          </el-form-item>
          <el-form-item label="获得萝卜根数：">
            <el-input
              v-model="marketingActivityData.limit.barcodeChance"
              oninput="value=value.replace(/[^\d]/g,'')"
            ></el-input>
            <span class="limit-text">根</span>
          </el-form-item>
        </template>
      </template>
      <template v-if="marketingActivityData.activity.templeType == 16">
        <el-form-item label-width="40px">
          <div class="lucky-draw">扫码获得卡片</div>
        </el-form-item>
        <el-form-item label="获得卡片张数：">
          <el-input
            v-model="marketingActivityData.limit.barcodeChance"
            oninput="value=value.replace(/[^\d]/g,'')"
          ></el-input>
          <span class="limit-text">张</span>
<!--          <el-tag type="danger" style="margin-left: 10px;">云溯用户请将“每人总抽奖机会”设为“限制”，次数为：0</el-tag>-->
        </el-form-item>
      </template>
    </el-form>
    <!-- 地区选择 -->
    <el-dialog
      title="选择可参与地区"
      :visible.sync="visible"
      width="40%"
      class="city"
    >
      <div class="cityContent">
        <div class="select">
          <h3>选择地区</h3>
          <div class="selectCity">
            <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
              collapse-tags
              :props="{ multiple: true }"
            >
            </el-cascader>
          </div>
        </div>
        <div class="select">
          <h3>可参与地区</h3>
          <div class="selectCity">
            <el-cascader
              size="large"
              :options="options"
              v-model="selectedOptions"
              @change="handleChange"
              :props="{ multiple: true }"
              collapse-tags
              class="showCity"
            >
            </el-cascader>
          </div>
        </div>
      </div>
      <el-button type="primary" class="saveCity" @click="visible = false"
        >保存</el-button
      >
    </el-dialog>
  </div>
</template>

<script>
import { regionData, CodeToText } from 'element-china-area-data'
import { mapState, mapMutations } from 'vuex'
export default {
  data () {
    return {
      visible: false,
      userJoin: '', // 限制人数,
      totalChance: '', // 活动总抽奖机会
      userChance: '', // 每人总抽奖机会
      userDayWin: '', // 每人每日中奖次数
      region: 0, // 参与地区
      options: regionData,
      selectedOptions: []
    }
  },
  created () {
    this.setActivityMapCodeParamDTO([
      {
        code: '100000',
        name: '中华人民共和国'
      }
    ])
  },
  computed: {
    ...mapState('market', ['marketingActivityData', 'systemCode'])
  },
  watch: {
    region: {
      handler (newVal, oldVal) {
        if (newVal === 0) {
          this.setActivityMapCodeParamDTO([
            {
              code: '100000',
              name: '中华人民共和国'
            }
          ])
        }
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations('market', ['setActivityMapCodeParamDTO']),
    handleChange (value) {
      console.log(value)
      const mapCodeDTO = []
      for (let i = 0; i < value.length; i++) {
        const nameList = []
        for (let j = 0; j < value[i].length; j++) {
          nameList.push(CodeToText[value[i][j]])
        }
        mapCodeDTO.push({ code: value[i][value[i].length - 1], name: nameList.toString() })
      }
      this.setActivityMapCodeParamDTO(mapCodeDTO)
    }
  }
}
</script>

<style lang="scss" scoped>
.send-way {
  .lucky-draw {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
  .el-input {
    width: 120px;
  }
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 120px;
    height: 34px;
  }
  .percent {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    margin-left: 9px;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
  .limit-text {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #606266;
    margin-left: 9px;
  }
  .city {
    text-align: center;
    .cityContent {
      display: flex;
      justify-content: space-between;
      height: 500px;
      .select {
        width: 45%;
        border: 1px solid #ccc;
        h3 {
          padding: 10px;
          border-bottom: 1px solid #ccc;
        }
      }
    }
  }
  .cityContent {
    .selectCity {
      margin-top: 30px;
    }
    /deep/ .el-input__inner {
      width: auto;
      background-color: #fff;
      border: 2px solid #ccc;
    }
  }
}
// .showCity {
//   height: 350px;
//   overflow: auto;
// }
.saveCity {
  width: 30%;
  margin-top: 30px;
}
</style>
