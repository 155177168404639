<template>
  <div class="reportList">
    <div class="award-list">
      <ul>
        <li v-for="(item, index) in awardList" :key="index" @click="checkoutAward(index)" :class="{'active': index == awardNum}">海报{{ wordList[index] }}</li>
      </ul>
      <div class="add-btn">
        <i class="el-icon-minus" @click="sub"></i>
        <i class="el-icon-plus" @click="add"></i>
      </div>
    </div>
    <el-form v-if="marketingActivityData.posterDTOList.length" ref="form" :model="marketingActivityData.posterDTOList[awardNum]" :rules="rules" label-width="170px">
      <el-form-item></el-form-item>
      <el-form-item label="海报名称：" prop="posterName">
        <el-input v-model="marketingActivityData.posterDTOList[awardNum].posterName"
                  placeholder="请输入海报名称"></el-input>
      </el-form-item>
      <el-form-item label="海报排序：" prop="posterSort">
        <el-input v-model="marketingActivityData.posterDTOList[awardNum].posterSort"
                  placeholder="请输入海报序号"></el-input>
      </el-form-item>
      <el-form-item label="上传海报：" prop="posterUrl">
        <upload-com @addImg="addReportImg" showImg="showImg" sizeLimit="200" unit="k" ref="uploadCom"></upload-com>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import UploadCom from '@/components/upload/uploadCom.vue'
const report = {
  posterName: '',
  posterSort: '',
  posterUrl: ''
}
export default {
  name: 'uploadReport',
  components: {
    UploadCom
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  data () {
    return {
      awardNum: this.$route.query.level || 0,
      awardList: [0, 1, 2],
      wordList: ['一', '二', '三', '四'],
      rules: {
        posterName: [
          { required: true, message: '请输入海报名称', trigger: 'blur' }
        ],
        posterSort: [
          { required: true, message: '请输入海报序号', trigger: 'blur' }
        ],
        posterUrl: [
          { required: true, message: '请上传海报', trigger: 'change' }
        ]
      }
    }
  },
  created () {
    const that = this
    this.$bus.$on('validateSeventh', () => {
      this.$refs.form.validate((valid) => {
        that.setUploadReport(valid)
        if (valid) {
          return true
        } else {
          return false
        }
      })
    })
  },
  methods: {
    ...mapMutations('market', ['addReport', 'subReport', 'setReportImg', 'setUploadReport']),
    init () {
      for (let i = 0; i < this.awardList.length; i++) {
        const index = i + 1
        report.posterSort = index
        this.addReport(JSON.parse(JSON.stringify(report)))
      }
    },
    sub () { // 减海报
      if (this.marketingActivityData.activity.isPublish) {
        this.$message('已发布活动禁止修改海报数量')
        return
      }
      if (this.marketingActivityData.posterDTOList.length > 1) {
        if (this.marketingActivityData.posterDTOList[this.awardList.length - 1].id) {
          this.$confirm('确定删除该海报吗？保存后无法恢复', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.awardList.pop()
            this.subReport()
          }).catch(() => {
            this.$message.info('已取消~')
          })
        } else {
          this.awardList.pop()
          this.subReport()
        }
      } else {
        this.$message('最少保留一张海报吧~')
      }
    },
    add () { // 加海报
      if (this.marketingActivityData.activity.isPublish) {
        this.$message('已发布活动禁止修改海报数量')
        return
      }
      if (this.marketingActivityData.posterDTOList.length >= 4) {
        this.$message('最多上传四张海报！')
      } else {
        this.awardList.push(this.awardList.length)
        report.posterSort = this.awardList.length
        this.addReport(JSON.parse(JSON.stringify(report)))
      }
    },
    checkoutAward (index) {
      this.awardNum = index
      this.setReportImgUrl()
    },
    addReportImg (data) { // 上传海报
      console.log(data)
      this.setReportImg({
        ...data,
        index: this.awardNum
      })
    },
    setReportImgUrl () {
      this.$nextTick(() => {
        this.$refs.uploadCom.url = this.marketingActivityData.posterDTOList[this.awardNum].posterUrl
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reportList{
  .award-list {
    height: 45px;
    background-color: #232427;
    margin: 0 74px 0 58px;
    ul {
      float: left;
      width: 92%;
      li {
        float: left;
        width: 112px;
        height: 45px;
        line-height: 45px;
        text-align: center;
        background: #353944;
        border: 1px solid #353944;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #7E838E;
        cursor: pointer;
      }
      .active {
        width: 112px;
        height: 45px;
        background: #1D1E23;
        border: 1px solid #353944;
        font-weight: bold;
        color: #FFFFFF;
      }
    }
    .add-btn {
      float: right;
      height: 45px;
      line-height: 45px;
      i {
        border: 1px dashed #7E838E;
        font-size: 18px;
        padding: 2px;
        color: #7E838E;
        margin-right: 14px;
        cursor: pointer;
      }
    }
  }
  .basic-options {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 34px;
  }
}

</style>
