<template>
  <div class="scratchTicket" :style="{'backgroundImage': `url(${scratchTicket.startBgc.pictureUrl})`}">
    <div class="scratchTicket-title">
      <img :src="scratchTicket.title.pictureUrl" alt="">
      <p class="time" v-if="marketingActivityData.activity.beginTime && marketingActivityData.activity.endTime">
        {{ 
          `活动时间：${marketingActivityData.activity.beginTime.split(/[-' ']/)[1]}月${marketingActivityData.activity.beginTime.split(/[-' ']/)[2]}日-${marketingActivityData.activity.endTime.split(/[-' ']/)[1]}月${marketingActivityData.activity.endTime.split(/[-' ']/)[2]}日`
        }}
      </p>
    </div>
    <div class="start">
      <img :src="scratchTicket.start.pictureUrl" alt="">
    </div>
    <div class="rules">
      <img :src="scratchTicket.rulesBtn.pictureUrl" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData']),
    scratchTicket() {
      return JSON.parse(this.marketingActivityData.activity.templePage).drawPage.scratchTicket
    }
  }
}
</script>
  
<style lang="scss" scoped>
.scratchTicket {
  position: relative;
  width: 100%;
  height: 615px;
  background-size: 100% 100%;
  margin-top: 22px;
  .scratchTicket-title {
      width: 370px;
      text-align: center;
    img {
      width: 65%;
      margin-top: 50px;
    }
  }
  .time {
    color: #fff9db;
    margin-top: 20px;
  }
  .start {
    width: 40%;
    margin: 300px auto 20px;
    img {
      width: 100%;
    }
  }
  .rules {
    position: absolute;
    top: 20px;
    right: 0;
    width: 6%;
    img {
      width: 100%;
    }
  }
}
</style>