var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "paintGoldenEgg",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .paintGoldenEgg.background.pictureUrl +
          ")"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "frame",
          style: {
            backgroundImage:
              "url(" +
              JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
                .paintGoldenEgg.frame.pictureUrl +
              ")"
          }
        },
        [
          _c("h5", [
            _vm._v(_vm._s(_vm.marketingActivityData.activity.activityName))
          ]),
          _c("p", { staticClass: "tips" }, [_vm._v("请选择画框")]),
          _c("ul", { staticClass: "select" }, [
            _c("li", [
              _c("img", {
                attrs: {
                  src: JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .drawPage.paintGoldenEgg.bord_01_s.pictureUrl,
                  alt: ""
                }
              })
            ]),
            _c("li", [
              _c("img", {
                attrs: {
                  src: JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .drawPage.paintGoldenEgg.bord_02_s.pictureUrl,
                  alt: ""
                }
              })
            ]),
            _c("li", [
              _c("img", {
                attrs: {
                  src: JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .drawPage.paintGoldenEgg.bord_03_s.pictureUrl,
                  alt: ""
                }
              })
            ]),
            _c("li", [
              _c("img", {
                attrs: {
                  src: JSON.parse(_vm.marketingActivityData.activity.templePage)
                    .drawPage.paintGoldenEgg.bord_04_s.pictureUrl,
                  alt: ""
                }
              })
            ])
          ]),
          _c("div", { staticClass: "bord" }, [
            _c("img", {
              attrs: {
                src: JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .drawPage.paintGoldenEgg.bord_01_b.pictureUrl,
                alt: ""
              }
            })
          ]),
          _c("p", { staticClass: "describe" }, [
            _vm._v(
              _vm._s(
                JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .drawPage.paintGoldenEgg.bord_01_b.description
              )
            )
          ])
        ]
      ),
      _c("ul", { staticClass: "btn" }, [
        _c(
          "li",
          {
            style: {
              backgroundImage:
                "url(" +
                JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .drawPage.paintGoldenEgg.eggBtn.pictureUrl +
                ")"
            }
          },
          [_vm._v("画好了")]
        ),
        _c(
          "li",
          {
            style: {
              backgroundImage:
                "url(" +
                JSON.parse(_vm.marketingActivityData.activity.templePage)
                  .drawPage.paintGoldenEgg.eggBtn.pictureUrl +
                ")"
            }
          },
          [_vm._v("重新画")]
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }