<template>
<div class="ticket" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.ticket.background.pictureUrl})`}">
  <div class="title" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.ticket.title.pictureUrl})`}"></div>
  <div class="btn rulesBtn" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.ticket.rulesBtn.pictureUrl})`}"></div>
  <div class="btn myPrize" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.ticket.myPrize.pictureUrl})`}"></div>
  <div class="upload" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.ticket.upload.pictureUrl})`}"></div>
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'ticket',
  data () {
    return {}
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.ticket{
  height: 684px;
  background-size: 100% 100%;
  background-position: top 24px center;
  position: relative;
  *{
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
  }
  .title{
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    margin: auto;
  }
  .rulesBtn,.myPrize{
    width: 50px;
    height: 55px;
    position: absolute;
    right: 10px;
  }

  .rulesBtn{
    top: 34px;
  }
  .myPrize{
    top: 94px;
  }
  .upload{
    width: 300px;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 64px;
  }
}
</style>
