<template>
  <div class="activityShare">
    <img class="report" :src="`${JSON.parse(marketingActivityData.activity.templePage).drawPage.activityShare.report.pictureUrl}`" >
    <div class="text">长按或者截图保存图片分享给好友</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState('market', ['marketingActivityData'])
  }
}
</script>

<style lang="scss" scoped>
.activityShare{
  height: 684px;
  background-color: #B61720;
  background-image: url(../../../assets/img/marketing/ruleTopBgimg.jpg),url(../../../assets/img/marketing/ruleBottomBgimg.jpg);
  background-size: 100% auto, 100% auto;
  background-repeat: no-repeat no-repeat;
  background-position: left top,left bottom;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .report{
    width: 280px;
    box-shadow: 3px 3px 13px #333;
    margin-top: 20px;
  }
  .text{
    text-align: center;
    color: #B61720;
    font-size: 14px;
    margin-top: 80px;
  }
}
</style>
