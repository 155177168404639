<template>
  <div class="numberList" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.background.pictureUrl})`}">
    <div style="height: 420px"></div>
    <div class="title" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.title.pictureUrl})`}"></div>
    <div class="btn rulesBtn" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.rulesBtn.pictureUrl})`}"></div>
    <div class="btn myPrize" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.myPrize.pictureUrl})`}"></div>
    <div class="start" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.start.pictureUrl})`}"></div>
    <div class="scene4" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.numberList.scene4.pictureUrl})`}"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  },
  mounted () {}
}
</script>
<style lang="scss" scoped>
.numberList{
  height: 682px;
  background-size: 100% 100%;
  background-position: top 24px center;
  position: relative;
  *{
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
  }
  .title{
    width: 100%;
    height: 300px;
    position: absolute;
    left: 0;
    right: 0;
    top: 44px;
    margin: auto;
  }
  .rulesBtn,.myPrize{
    width: 50px;
    height: 55px;
    position: absolute;
    right: 10px;
  }

  .rulesBtn{
    top: 34px;
  }
  .myPrize{
    top: 94px;
  }
  .start{
    width: 200px;
    height: 60px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 64px;
  }
  .scene4{
    width: 100%;
    height: 50px;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
</style>
