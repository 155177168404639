var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "personalizedSetting" },
    [
      _vm.templeType == 10
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.marketingActivityData.activity,
                "label-width": "220px",
                rules: _vm.rules
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "活动规则：", prop: "custom.rules" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入活动规则",
                      resize: "none",
                      maxlength: "2500"
                    },
                    model: {
                      value: _vm.marketingActivityData.activity.custom.rules,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "rules",
                          $$v
                        )
                      },
                      expression: "marketingActivityData.activity.custom.rules"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "温馨提示：", prop: "custom.tips" } },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "请输入温馨提示" },
                    model: {
                      value: _vm.marketingActivityData.activity.custom.tips,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "tips",
                          $$v
                        )
                      },
                      expression: "marketingActivityData.activity.custom.tips"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中奖消息显示数量：",
                    prop: "custom.winShowNum"
                  }
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "8",
                      oninput: "value=value.replace(/[^\\d]/g,'')",
                      placeholder: "请输入中奖消息显示数量"
                    },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.custom.winShowNum,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "winShowNum",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.custom.winShowNum"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "中额外的奖品：", prop: "custom.productId" }
                },
                [
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: false,
                        expression: "false"
                      }
                    ],
                    staticClass: "ext-name",
                    attrs: { disabled: "" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.custom.productId,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "productId",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.custom.productId"
                    }
                  }),
                  _c("el-input", {
                    staticClass: "ext-name",
                    attrs: { disabled: "" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.custom.productName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "productName",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.custom.productName"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.prizeCenter }
                    },
                    [_vm._v("选择奖品")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default", size: "mini" },
                      on: { click: _vm.clearSelectPrize }
                    },
                    [_vm._v("清除")]
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "中烟平台标识：",
                    prop: "custom.thirdSystemCode"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { maxlength: "200", placeholder: "" },
                    model: {
                      value:
                        _vm.marketingActivityData.activity.custom
                          .thirdSystemCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.activity.custom,
                          "thirdSystemCode",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.activity.custom.thirdSystemCode"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.templeType == 9
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.marketingActivityData.activity,
                "label-width": "220px",
                rules: _vm.rules
              }
            },
            [
              _c("el-form-item", { attrs: { "label-width": "58px" } }, [
                _c("div", { staticClass: "basic-options" }, [
                  _vm._v("抽奖限制")
                ])
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "文章链接：", prop: "custom.url" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "50",
                      placeholder:
                        "请输入文章链接，如：https://mp.weixin.qq.com/xxx"
                    },
                    model: {
                      value: _vm.custom.url,
                      callback: function($$v) {
                        _vm.$set(_vm.custom, "url", $$v)
                      },
                      expression: "custom.url"
                    }
                  })
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "抽奖规则：" } }, [
                _c(
                  "div",
                  { staticClass: "draw-rules" },
                  [
                    _c("span", { staticClass: "limit-text" }, [
                      _vm._v("每邀请")
                    ]),
                    _c("el-input", {
                      attrs: {
                        maxlength: "8",
                        oninput: "value=value.replace(/[^\\d]/g,'')"
                      },
                      model: {
                        value: _vm.custom.inviteNum,
                        callback: function($$v) {
                          _vm.$set(_vm.custom, "inviteNum", $$v)
                        },
                        expression: "custom.inviteNum"
                      }
                    }),
                    _c("span", { staticClass: "limit-text" }, [
                      _vm._v("名好友参与阅读，额外获得")
                    ]),
                    _c("el-input", {
                      attrs: {
                        maxlength: "8",
                        oninput: "value=value.replace(/[^\\d]/g,'')"
                      },
                      model: {
                        value: _vm.custom.chance,
                        callback: function($$v) {
                          _vm.$set(_vm.custom, "chance", $$v)
                        },
                        expression: "custom.chance"
                      }
                    }),
                    _c("span", { staticClass: "limit-text" }, [
                      _vm._v("次抽奖机会")
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.templeType == 5
        ? _c(
            "el-form",
            {
              ref: "form",
              staticClass: "hoiseflag",
              attrs: {
                model: _vm.marketingActivityData.activity,
                "label-width": "220px"
              }
            },
            [
              _c("el-form-item", { attrs: { "label-width": "58px" } }, [
                _c("div", { staticClass: "basic-options" }, [
                  _vm._v("抽奖限制")
                ])
              ]),
              _c(
                "el-form-item",
                {
                  staticClass: "hoiseflag-item",
                  attrs: {
                    label: "每人答题总次数",
                    prop: "custom.userAnswerChance"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", maxlength: "10" },
                    on: { change: _vm.customChangeEvent },
                    model: {
                      value: _vm.custom.userAnswerChance,
                      callback: function($$v) {
                        _vm.$set(_vm.custom, "userAnswerChance", _vm._n($$v))
                      },
                      expression: "custom.userAnswerChance"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "hoiseflag-item",
                  attrs: {
                    label: "每人每天答题总次数",
                    prop: "custom.userDayAnswerChance"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", maxlength: "10" },
                    on: { change: _vm.customChangeEvent },
                    model: {
                      value: _vm.custom.userDayAnswerChance,
                      callback: function($$v) {
                        _vm.$set(_vm.custom, "userDayAnswerChance", _vm._n($$v))
                      },
                      expression: "custom.userDayAnswerChance"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "hoiseflag-item",
                  attrs: {
                    label: "答题正确指标数",
                    prop: "custom.rightAnswerChance"
                  }
                },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", maxlength: "10" },
                    on: { change: _vm.customChangeEvent },
                    model: {
                      value: _vm.custom.rightAnswerChance,
                      callback: function($$v) {
                        _vm.$set(_vm.custom, "rightAnswerChance", _vm._n($$v))
                      },
                      expression: "custom.rightAnswerChance"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "hoiseflag-item",
                  attrs: { label: "H5用户答题数", prop: "custom.questionNum" }
                },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", maxlength: "10" },
                    on: { change: _vm.customChangeEvent },
                    model: {
                      value: _vm.custom.questionNum,
                      callback: function($$v) {
                        _vm.$set(_vm.custom, "questionNum", _vm._n($$v))
                      },
                      expression: "custom.questionNum"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("prize-center", { ref: "prizeCenter", attrs: { extAward: 1 } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }