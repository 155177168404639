<template>
  <div class="runForward" :style="{'backgroundImage': `url(${runForward.background.pictureUrl})`}">
    <div class="runForward-title">
      <img :src="runForward.title.pictureUrl" alt="">
    </div>
    <div class="start">
      <img :src="runForward.start.pictureUrl" alt="">
    </div>
    <div class="rules">
      <img :src="runForward.rulesBtn.pictureUrl" alt="">
    </div>
    <div class="rules prize">
      <img :src="runForward.myPrizeBtn.pictureUrl" alt="">
    </div>
    <p :style="{color: paperWork.dayDrawChance.routineColor}" class="chance">
        今天还有 <span :style="{color: paperWork.dayDrawChance.numberColor}">{{ marketingActivityData.limit.userDayChance }}</span> 次抽奖机会
      </p>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData']),
    runForward() {
      return JSON.parse(this.marketingActivityData.activity.templePage).drawPage.runForward
    },
    paperWork() {
      return JSON.parse(this.marketingActivityData.activity.templePage).paperWork
    },
  }
}
</script>
  
<style lang="scss" scoped>
.runForward {
  position: relative;
  width: 100%;
  height: 615px;
  background-size: 100% 100%;
  margin-top: 22px;
  .runForward-title {
      width: 370px;
      text-align: center;
    img {
      width: 65%;
      margin-top: 120px;
    }
  }
  .start {
    width: 40%;
    margin: 300px auto 10px;
    img {
      width: 100%;
    }
  }
  .rules {
    position: absolute;
    top: 80px;
    right: 20px;
    width: 15%;
    img {
      width: 100%;
    }
  }
  .prize {
    right: auto;
    left: 20px;
  }
  .chance {
    text-align: center;
    font-size: 14px;
  }
}
</style>