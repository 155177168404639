<template>
  <!-- 高级设置 -->
  <div class="senior-setting">
    <el-form ref="form" :model="marketingActivityData.activity" label-width="220px">
      <el-form-item label-width="58px">
        <div class="basic-options">企业信息</div>
      </el-form-item>
      <el-form-item label="主办单位：">
        <el-input v-model="marketingActivityData.activity.companyName" placeholder="请输入单位名称" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="客服电话：">
        <el-input v-model="marketingActivityData.activity.telephone" placeholder="请输入客服电话" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="品牌曝光：">
        <el-radio-group v-model="marketingActivityData.activity.brandExposure.isShow" @change="brand">
          <el-radio :label="false">隐藏</el-radio>
          <el-radio :label="true">显示</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="企业logo：" v-if="marketingActivityData.activity.brandExposure.isShow">
        <upload-com @addImg="addBrandWx"  showImg="showImg" ref="uploadLogo"></upload-com>
      </el-form-item>
      <el-form-item label="宣传语：" v-if="marketingActivityData.activity.brandExposure.isShow">
        <el-input placeholder="请输入宣传语" v-model="marketingActivityData.activity.brandExposure.slogan" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="功能按钮：">
        <el-radio-group v-model="marketingActivityData.activity.functionButton.isShow" @change="funBtnSet">
          <el-radio :label="0">隐藏</el-radio>
          <el-radio :label="1">公众号</el-radio>
          <el-radio :label="2">页面跳转</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="按钮名称：" v-if="marketingActivityData.activity.functionButton.isShow == 1">
        <el-input placeholder="请输入按钮名称，如：关注我们" v-model="marketingActivityData.activity.functionButton.weChatButton" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="微信公众号：" v-if="marketingActivityData.activity.functionButton.isShow == 1">
        <upload-com @addImg="addImgWx"  showImg="showImg" ref="uploadCom"></upload-com>
      </el-form-item>
      <el-form-item label="按钮名称：" v-if="marketingActivityData.activity.functionButton.isShow == 2">
        <el-input placeholder="请输入按钮名称，如：关注我们" v-model="marketingActivityData.activity.functionButton.pageButton" maxlength="50"></el-input>
      </el-form-item>
      <el-form-item label="按钮链接：" v-if="marketingActivityData.activity.functionButton.isShow == 2">
        <el-input placeholder="请输入网址" v-model="marketingActivityData.activity.functionButton.pageButtonUrl"></el-input>
      </el-form-item>
      <el-form-item label-width="58px">
        <div class="basic-options">分享设置</div>
      </el-form-item>
      <el-form-item label="分享活动：">
        <el-radio-group v-model="marketingActivityData.activity.shareData.isShare" :disabled="marketingActivityData.activity.templeType === 9">
          <el-radio :label="true">开启分享</el-radio>
          <el-radio :label="false">关闭分享</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="分享图标：" v-if="marketingActivityData.activity.shareData.isShare">
        <el-radio-group v-model="marketingActivityData.activity.shareData.isDefaultIcon" @change="sharePicture">
          <el-radio :label="true">默认</el-radio>
          <el-radio :label="false">自定义</el-radio>
        </el-radio-group>
         <upload-com @addImg="addImgShare" v-show="!marketingActivityData.activity.shareData.isDefaultIcon"  showImg="showImg" ref="uploadCom1"></upload-com>
      </el-form-item>
      <el-form-item label="分享内容：" v-if="marketingActivityData.activity.shareData.isShare">
        <el-radio-group v-model="marketingActivityData.activity.shareData.isDefaultContent" @change="shareContent">
          <el-radio :label="true">默认</el-radio>
          <el-radio :label="false">自定义</el-radio>
        </el-radio-group>
        <el-input v-show="!marketingActivityData.activity.shareData.isDefaultContent" v-model="marketingActivityData.activity.shareData.customContent" placeholder="请输入分享内容" maxlength="200"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import UploadCom from '@/components/upload/uploadCom.vue'

export default {
  components: {
    UploadCom
  },
  data() {
    return {

    }
  },
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
  watch: {

  },
  methods: {
    ...mapMutations('market', ['setFunctionButton', 'setWeChatDTOPictureUrl', 'setDefaultSharePictureUrl', 'setDefaultShareContent', 'setBrandPictureUrl', 'resetBrand']),
    // 品牌曝光
    brand (val) {
      if(val) {
        this.$refs.uploadLogo.url = ''
      }
      this.resetBrand()
    },
    // 企业logo
    addBrandWx (data) {
      this.setBrandPictureUrl(data.fileUrl)
    },
    // 微信公众号二维码
    addImgWx (data) {
        this.setWeChatDTOPictureUrl(data.fileUrl)
    },
    // 分享图标
    addImgShare (data) {
      this.setDefaultSharePictureUrl(data.fileUrl)
    },
    // 分享图片/微信公众号二维码回显
    setImgShare () {
      this.$nextTick(() => {
        this.$bus.$emit('setImgUrl',this.marketingActivityData.activity.shareData.customIcon)
        if(this.$refs.uploadCom) {
          this.$refs.uploadCom.url = this.marketingActivityData.activity.functionButton.weChat
        }
        if(this.$refs.uploadLogo) {
          this.$refs.uploadLogo.url = this.marketingActivityData.activity.brandExposure.logo
        }
      })
    },
    // 分享图片选择
    sharePicture (val) {
      if(val) {
        this.$refs.uploadCom1.url = JSON.parse(this.marketingActivityData.activity.templePage).activityBase.cover
      }else {
        this.$refs.uploadCom1.url = ''
      }
    },
    // 分享内容选择
    shareContent (val) {
      if(val) {
        this.setDefaultShareContent(JSON.parse(this.marketingActivityData.activity.templePage).activityBase.description)
      }else {
        this.setDefaultShareContent('')
      }
    },
    // 功能按钮选择
    funBtnSet (val) {
      this.setFunctionButton(val)
    }
  }
};
</script>

<style lang="scss" scoped>
.senior-setting {
  /deep/ .el-input__inner {
    background-color: #1d1e23;
    border: 2px solid #2b2c2f;
    color: #fff;
    width: 830px;
    height: 34px;
  }
  /deep/ .el-radio__input.is-checked + .el-radio__label {
    color: #606266;
  }
  .basic-options {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #fff;
    border-bottom: 1px solid #2b2c2f;
  }
  /deep/ .el-upload--picture-card {
    width: 50px;
    height: 50px;
    line-height: 60px;
    background-color: transparent;
  }
}
</style>
