var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "award-setting" },
    [
      _c("div", { staticClass: "award-list" }, [
        _c(
          "ul",
          _vm._l(_vm.awardList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                class: { active: index == _vm.awardNum },
                on: {
                  click: function($event) {
                    return _vm.checkoutAward(index)
                  }
                }
              },
              [_vm._v("奖项" + _vm._s(_vm.wordList[index]))]
            )
          }),
          0
        ),
        _c("div", { staticClass: "add-btn" }, [
          _c("i", { staticClass: "el-icon-minus", on: { click: _vm.sub } }),
          _c("i", { staticClass: "el-icon-plus", on: { click: _vm.add } })
        ])
      ]),
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.marketingActivityData.prize[_vm.awardNum],
            "label-width": "220px",
            rules: _vm.rules
          }
        },
        [
          _c("el-form-item", { attrs: { "label-width": "58px" } }, [
            _c("div", { staticClass: "basic-options" }, [
              _vm._v("基本选项·奖项" + _vm._s(_vm.wordList[_vm.awardNum]))
            ])
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "奖项等级：", prop: "rank" } },
            [
              _c("el-input", {
                attrs: {
                  placeholder: _vm.wordList[_vm.awardNum] + "等奖",
                  disabled: ""
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖项类型：" } },
            [
              _c(
                "el-radio-group",
                {
                  attrs: {
                    disabled:
                      !!_vm.marketingActivityData.prize[0].activityId &&
                      _vm.marketingActivityData.activity.isPublish
                  },
                  model: {
                    value: _vm.marketingActivityData.prize[_vm.awardNum].type,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.prize[_vm.awardNum],
                        "type",
                        $$v
                      )
                    },
                    expression: "marketingActivityData.prize[awardNum].type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("自建奖品")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("奖品中心")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖项名称：", prop: "name", required: true } },
            [
              _c("el-input", {
                staticClass: "name",
                attrs: { disabled: "" },
                model: {
                  value: _vm.marketingActivityData.prize[_vm.awardNum].name,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.prize[_vm.awardNum],
                      "name",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.prize[awardNum].name"
                }
              }),
              _vm.marketingActivityData.prize[_vm.awardNum].type == 0
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.selfPrize }
                    },
                    [_vm._v("选择自建奖品")]
                  )
                : _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.prizeCenter }
                    },
                    [_vm._v("选择奖品")]
                  )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖项数量：", prop: "quantity" } },
            [
              _c("el-input", {
                staticClass: "chanceOf",
                attrs: {
                  disabled: _vm.marketingActivityData.activity.isPublish,
                  placeholder: "请输入奖项数量",
                  maxlength: "8"
                },
                on: { input: _vm.inventory },
                model: {
                  value: _vm.marketingActivityData.prize[_vm.awardNum].quantity,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.prize[_vm.awardNum],
                      "quantity",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.prize[awardNum].quantity"
                }
              }),
              _vm.marketingActivityData.prize[_vm.awardNum].issuedQty != null
                ? _c("span", { staticStyle: { color: "#606266" } }, [
                    _vm._v(
                      "已发放" +
                        _vm._s(
                          _vm.marketingActivityData.prize[_vm.awardNum]
                            .issuedQty
                        )
                    )
                  ])
                : _vm._e(),
              _vm.marketingActivityData.prize[_vm.awardNum].quantity != "" &&
              _vm.isShow
                ? _c("div", { staticClass: "error_tips" }, [
                    _vm._v("奖项数量大于库存数量")
                  ])
                : _vm.marketingActivityData.prize[_vm.awardNum].quantity < 1 &&
                  _vm.marketingActivityData.prize[_vm.awardNum].quantity != ""
                ? _c("div", { staticClass: "error_tips" }, [
                    _vm._v("请输入大于零的奖项数量")
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm.marketingActivityData.activity.isPublish
            ? _c(
                "el-form-item",
                { attrs: { label: "修改奖项数量" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      on: { change: _vm.computedIncQty },
                      model: {
                        value:
                          _vm.marketingActivityData.prize[_vm.awardNum]
                            .incChange,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.prize[_vm.awardNum],
                            "incChange",
                            $$v
                          )
                        },
                        expression:
                          "marketingActivityData.prize[awardNum].incChange"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("加")]),
                      _c("el-radio", { attrs: { label: -1 } }, [_vm._v("减")]),
                      _vm.marketingActivityData.prize[_vm.awardNum].incChange
                        ? _c("el-input", {
                            staticClass: "chanceOf",
                            attrs: {
                              oninput:
                                "value=value.replace(/^(0+)|[^\\d]+/g,'')",
                              placeholder:
                                "请输入要" +
                                (_vm.marketingActivityData.prize[_vm.awardNum]
                                  .incChange == 1
                                  ? "增加"
                                  : "减少") +
                                "的奖项数量",
                              maxlength: "8"
                            },
                            on: { blur: _vm.computedIncQty },
                            model: {
                              value:
                                _vm.marketingActivityData.prize[_vm.awardNum]
                                  .incQtyNum,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.marketingActivityData.prize[_vm.awardNum],
                                  "incQtyNum",
                                  $$v
                                )
                              },
                              expression:
                                "marketingActivityData.prize[awardNum].incQtyNum"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.marketingActivityData.activity.templeType != 19,
                  expression: "marketingActivityData.activity.templeType != 19"
                }
              ],
              attrs: { label: "每日中奖：", prop: "quantityDay" }
            },
            [
              _c("el-input", {
                staticClass: "chanceOf",
                attrs: {
                  placeholder: "请输入每日中奖数量",
                  oninput: "value=value.replace(/\\D|^-/g,'')",
                  maxlength: "50"
                },
                model: {
                  value:
                    _vm.marketingActivityData.prize[_vm.awardNum].quantityDay,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.prize[_vm.awardNum],
                      "quantityDay",
                      $$v
                    )
                  },
                  expression:
                    "marketingActivityData.prize[awardNum].quantityDay"
                }
              }),
              _vm.marketingActivityData.prize[_vm.awardNum].quantityDayPool !=
              null
                ? _c("span", { staticStyle: { color: "#606266" } }, [
                    _vm._v(
                      " 当前剩余" +
                        _vm._s(
                          _vm.marketingActivityData.prize[_vm.awardNum]
                            .quantityDayPool
                        )
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.marketingActivityData.activity.templeType != 19,
                  expression: "marketingActivityData.activity.templeType != 19"
                }
              ],
              attrs: { label: "中奖率：", prop: "rate" }
            },
            [
              _c("el-input", {
                staticClass: "chanceOf",
                attrs: {
                  oninput:
                    "if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}",
                  maxlength: "8"
                },
                model: {
                  value: _vm.marketingActivityData.prize[_vm.awardNum].rate,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.prize[_vm.awardNum],
                      "rate",
                      $$v
                    )
                  },
                  expression: "marketingActivityData.prize[awardNum].rate"
                }
              }),
              _c("span", { staticStyle: { color: "#606266" } }, [_vm._v(" %")])
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "首抽必中：" } },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.isFirstWin },
                  model: {
                    value:
                      _vm.marketingActivityData.prize[_vm.awardNum].isFirstWin,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.prize[_vm.awardNum],
                        "isFirstWin",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.prize[awardNum].isFirstWin"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "贵重奖品：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value:
                      _vm.marketingActivityData.prize[_vm.awardNum].isPrecious,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.prize[_vm.awardNum],
                        "isPrecious",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.prize[awardNum].isPrecious"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "奖项图片：" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value:
                      _vm.marketingActivityData.prize[_vm.awardNum].imgUrl.type,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.marketingActivityData.prize[_vm.awardNum].imgUrl,
                        "type",
                        $$v
                      )
                    },
                    expression:
                      "marketingActivityData.prize[awardNum].imgUrl.type"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: "default" } }, [
                    _vm._v("系统默认")
                  ]),
                  _c("el-radio", { attrs: { label: "product" } }, [
                    _vm._v("奖品图片")
                  ]),
                  _c("el-radio", { attrs: { label: "upload" } }, [
                    _vm._v("上传图片")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.marketingActivityData.prize[_vm.awardNum].imgUrl.type == "upload"
            ? _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("upload-com", {
                    ref: "uploadCom",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addImgPrize }
                  })
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { "label-width": "58px" } }, [
            _c("div", { staticClass: "basic-options" }, [
              _vm._v("兑换方式·奖项" + _vm._s(_vm.wordList[_vm.awardNum]))
            ])
          ]),
          _vm.marketingActivityData.prize[_vm.awardNum].type == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "兑换方式：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.marketingActivityData.prize[_vm.awardNum]
                            .exchangeType,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.prize[_vm.awardNum],
                            "exchangeType",
                            $$v
                          )
                        },
                        expression:
                          "marketingActivityData.prize[awardNum].exchangeType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("线下兑换")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("客服兑换")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.prize[_vm.awardNum].type == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "操作提示：", prop: "operationTips" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入操作提示，如：xxxx",
                      maxlength: "50"
                    },
                    model: {
                      value:
                        _vm.marketingActivityData.prize[_vm.awardNum]
                          .operationTips,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.prize[_vm.awardNum],
                          "operationTips",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.prize[awardNum].operationTips"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.prize[_vm.awardNum].type == 0 &&
          _vm.marketingActivityData.prize[_vm.awardNum].exchangeType == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "兑换地址：", prop: "exchangeAddress" } },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "200",
                      placeholder: "请输入兑换详细地址"
                    },
                    model: {
                      value:
                        _vm.marketingActivityData.prize[_vm.awardNum]
                          .exchangeAddress,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.prize[_vm.awardNum],
                          "exchangeAddress",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.prize[awardNum].exchangeAddress"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.marketingActivityData.prize[_vm.awardNum].exchangeType == 1
            ? _c(
                "el-form-item",
                { attrs: { label: "客服微信：", prop: "wechatUrl" } },
                [
                  _c("upload-com", {
                    ref: "uploadCom1",
                    attrs: { showImg: "showImg" },
                    on: { addImg: _vm.addWechatUrl }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "兑换时间：", required: true } },
            [
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "exchangeBeginTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "time",
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择开始时间",
                          "picker-options": _vm.startDatePicker,
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value:
                            _vm.marketingActivityData.prize[_vm.awardNum]
                              .exchangeBeginTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.prize[_vm.awardNum],
                              "exchangeBeginTime",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.prize[awardNum].exchangeBeginTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-col", { attrs: { span: 2 } }, [
                _c("span", { staticClass: "time-text" }, [_vm._v("至")])
              ]),
              _c(
                "el-col",
                { attrs: { span: 11 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "exchangeEndTime" } },
                    [
                      _c("el-date-picker", {
                        staticClass: "time",
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "datetime",
                          placeholder: "选择结束时间",
                          "picker-options": _vm.endDatePicker,
                          "value-format": "yyyy-MM-dd HH:mm:ss"
                        },
                        model: {
                          value:
                            _vm.marketingActivityData.prize[_vm.awardNum]
                              .exchangeEndTime,
                          callback: function($$v) {
                            _vm.$set(
                              _vm.marketingActivityData.prize[_vm.awardNum],
                              "exchangeEndTime",
                              $$v
                            )
                          },
                          expression:
                            "marketingActivityData.prize[awardNum].exchangeEndTime"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "兑换须知：" } },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入兑换说明",
                  resize: "none",
                  maxlength: "200"
                },
                model: {
                  value:
                    _vm.marketingActivityData.prize[_vm.awardNum].exchangeTips,
                  callback: function($$v) {
                    _vm.$set(
                      _vm.marketingActivityData.prize[_vm.awardNum],
                      "exchangeTips",
                      $$v
                    )
                  },
                  expression:
                    "marketingActivityData.prize[awardNum].exchangeTips"
                }
              })
            ],
            1
          ),
          _vm.marketingActivityData.prize[_vm.awardNum].exchangeType == 0
            ? _c(
                "el-form-item",
                { attrs: { label: "兑奖通知：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value:
                          _vm.marketingActivityData.prize[_vm.awardNum]
                            .exchangeNotice,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.marketingActivityData.prize[_vm.awardNum],
                            "exchangeNotice",
                            $$v
                          )
                        },
                        expression:
                          "marketingActivityData.prize[awardNum].exchangeNotice"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("关闭")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("开启")])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("self-built-prize", {
        ref: "selfBuiltPrize",
        attrs: { awardNum: _vm.awardNum }
      }),
      _c("prize-center", {
        ref: "prizeCenter",
        attrs: { awardNum: _vm.awardNum }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }