var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "goHome",
      style: {
        backgroundImage: "url(" + _vm.typeData.background.pictureUrl + ")"
      }
    },
    [
      _c("div", {
        staticClass: "music",
        style: { backgroundImage: "url(" + _vm.typeData.music.pictureUrl + ")" }
      }),
      _c(
        "div",
        {
          staticClass: "btnBgimg",
          style: {
            backgroundImage: "url(" + _vm.typeData.btnBgimg.pictureUrl + ")"
          }
        },
        [_vm._v("参与活动")]
      ),
      _c("div", { staticStyle: { height: "44px" } }),
      _vm._m(0),
      _vm._m(1)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("此刻，")]),
      _c("p", [_vm._v("你与家乡的距离是...")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "text" }, [
      _c("p", [_vm._v("温暖回家路，为爱加油")]),
      _c("p", [_vm._v("测试回家路程，赢取回家好礼")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }