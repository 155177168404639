<template>
  <!-- 奖品中心 -->
  <div class="prizeCenter">
    <el-dialog :visible.sync="visible" width="51%" class="popup">
      <el-tabs v-model="prizeType" class="tabs" @tab-click="handleClick">
        <el-tab-pane label="礼品账户" name="first" class="gift">
          <ul class="giftList">
            <li v-for="(item, index) in list" :key="index" @click="getGiftType(index, item)" :class="{'active': index == current1}">{{ item.name }}</li>
          </ul>
          <el-divider direction="vertical"></el-divider>
          <div class="prizeList">
            <div v-for="(item, index) in userList" :key="index" class="prize-item">
              <prize-item ref="prizeItem" prizeType="giftAccount" :userItem="item" @setPrizeInfo="setPrizeInfo"></prize-item>
            </div>
            <!-- <p v-if="userList.length == 0" class="noData">暂无数据</p> -->
            <div  v-if="userList.length == 0" style="height: 500px">
              <listNull text="暂无数据~" />
            </div>
          </div>
          <el-pagination
            class="giftPage"
            @size-change="sizeChangeHandle1"
            @current-change="currentChangeHandle1"
            :current-page="pageIndex1"
            :page-size="pageSize1"
            :total="totalPage1"
            layout="total, prev, pager, next, jumper">
          </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="购买礼品" name="second" class="gift">
          <ul class="giftList">
            <li v-for="(item, index) in list" :key="index" @click="getDataType(index, item)" :class="{'active': index == current}">{{ item.name }}</li>
          </ul>
          <el-divider direction="vertical"></el-divider>
          <div class="prizeList">
            <div v-for="(item, index) in prizeList" :key="index" class="prize-item">
              <prize-item ref="prizeItem" :item="item" prizeType="buyGifts"></prize-item>
            </div>
            <!-- <p v-if="prizeList.length == 0" class="noData">暂无数据</p> -->
            <div  v-if="prizeList.length == 0" style="height: 500px">
              <listNull text="暂无数据~" />
            </div>
          </div>
          <el-pagination
              class="giftPage"
              @size-change="sizeChangeHandle"
              @current-change="currentChangeHandle"
              :current-page="pageIndex"
              :page-size="pageSize"
              :total="totalPage"
              layout="total, prev, pager, next, jumper">
            </el-pagination>
        </el-tab-pane>
        <el-tab-pane label="购物车" name="third">
          <shopping-cart ref="shoppingCart"></shopping-cart>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>

    <!-- 添加购物车 -->
    <cart @currentChangeHandle="currentChangeHandle" ></cart>
  </div>
</template>

<script>
import PrizeItem from '@/components/PrizeItem.vue'
import listNull from '@/components/listNull.vue'
import ShoppingCart from './ShoppingCart.vue'
import { getResourcesType, getGiftData, getPrizeList } from '@/api/market'
import { mapState, mapMutations } from 'vuex'
import cart from '@/components/cart.vue'
export default {
  components: {
    PrizeItem,
    ShoppingCart,
    cart,
    listNull
  },
  props: ['awardNum', 'extAward'],
  data () {
    return {
      visible: false,
      current: 0,
      current1: 0,
      pageIndex: 1,
      pageSize: 8,
      totalPage: 0,
      pageIndex1: 1,
      pageSize1: 8,
      totalPage1: 0,
      prizeList: [],
      taxonomyId: '',
      taxonomyId1: '',
      prizeType: 'first',
      list: [],
      userList: []
    }
  },
  created () {
    this.$bus.$on('getType', () => {
      this.getType()
    })
    this.$bus.$on('setPrizeType', (num) => {
      this.prizeType = num
      this.pageIndex1 = 1
      this.getGiftUserList(this.current1, this.taxonomyId1)
    })
    console.log('prizeCenter ... ')
  },
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  methods: {
    ...mapMutations('market', ['setPrizePictureUrl', 'setPrizedata', 'setPrizeImg', 'setExtPrize']),
    handleClick (tab, event) {
      if (tab.name == 'first') {
        this.pageIndex1 = 1
        this.getGiftUserList(this.current1, this.taxonomyId1)
      } else if (tab.name == 'second') {
        this.pageIndex = 1
        this.getDataList(this.current, this.taxonomyId)
      } else if (tab.name == 'third') {
        this.$refs.shoppingCart.pageIndex = 1
        this.$refs.shoppingCart.getCarList()
      }
    },
    // 获取礼品分类
    async getType () {
      const res = await getResourcesType({ pageNo: 1, pageSize: 100000000 })
      if (res.code == 200) {
        this.list = res.data.list
        this.getDataList(0, res.data.list[0].id)
        this.getGiftUserList(0, res.data.list[0].id)
      }
    },
    // ------------------------------------------购买礼品---------------------------------------------
    // 获取分类礼品数据
    async getDataList (index, taxonomyId) {
      this.current = index
      this.taxonomyId = taxonomyId
      const res = await getGiftData({ pageNo: this.pageIndex, pageSize: this.pageSize, classificationId: taxonomyId })
      if (res.code == 200) {
        this.prizeList = res.data.list
        this.totalPage = res.data.total
      }
    },
    getDataType (index, item) {
      this.pageIndex = 1
      this.getDataList(index, item.id)
    },
    // 每页数
    sizeChangeHandle (val) {
      this.pageSize = val
      this.pageIndex = 1
      this.getDataList(this.current, this.taxonomyId)
    },
    // 当前页
    currentChangeHandle (val) {
      this.pageIndex = val
      this.getDataList(this.current, this.taxonomyId)
    },
    // ------------------------------------------礼品账户---------------------------------------------
    // 获取礼品账户列表
    async getGiftUserList (index, taxonomyId) {
      this.current1 = index
      this.taxonomyId1 = taxonomyId
      const res = await getPrizeList({ pageNo: this.pageIndex1, pageSize: this.pageSize1, classificationId: taxonomyId })
      if (res.code == 200) {
        this.userList = res.data.list
        this.totalPage1 = res.data.total
      }
    },
    getGiftType (index, item) {
      this.pageIndex1 = 1
      this.getGiftUserList(index, item.id)
    },
    // 每页数
    sizeChangeHandle1 (val) {
      this.pageSize1 = val
      this.pageIndex1 = 1
      this.getGiftUserList(this.current1, this.taxonomyId1)
    },
    // 当前页
    currentChangeHandle1 (val) {
      this.pageIndex1 = val
      this.getGiftUserList(this.current1, this.taxonomyId1)
    },
    // 设置奖品
    setPrizeInfo (data) {
      // sessionStorage.setItem(`prize_${this.awardNum}`, JSON.stringify(data.usableStock))
      // this.setPrizePictureUrl({
      //   pictureUrl: data.img,
      //   index: this.awardNum,
      //   type: 1,
      //   goodsName: data.name,
      //   goodsId: data.id
      // })
      // this.setPrizedata({...data, index: this.awardNum})
      // this.marketingActivityData.prize[this.awardNum].awardPictureUrl = data.img
      // this.$parent.imageType = 1
      sessionStorage.setItem(`prize_${this.awardNum}`, JSON.stringify(data.stock.usableStock))
      // console.log({...data, index: this.awardNum})
      if (!this.extAward) {
        this.setPrizedata({ ...data, index: this.awardNum })
        this.setPrizeImg({ imgUrl: data.img, type: 'product', index: this.awardNum })
      } else {
        console.log('设置额外的奖品')
        this.setExtPrize({ ...data })
      }

      this.visible = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .prizeCenter {
    .popup {
      /deep/ .el-dialog {
        height: 750px;
        width: 980px !important;
      }
    }
    /deep/ .el-tabs__item {
      border: none !important;
      border-bottom: 2px solid #f2f2f2 !important;
      background-color: #fff !important;
      font-weight: 400 !important;
    }
    /deep/ .el-tabs__active-bar {
      height: 2px !important;
      background: #1890ff !important;
    }
    /deep/ .el-tabs__nav-wrap::after {
      height: 2px !important;
      background: #f2f2f2 !important;
    }
    .prize-item {
      display: inline-block;
      margin: 0 20px 20px 0;
    }
    .gift {
      display: flex;
      flex-wrap: wrap;
      .el-divider--vertical {
        height: auto;
        margin: 0 20px 0 10px;
      }
    }
    .giftList {
      width: 150px;
      // margin-right: 10px;
      li {
        height: 40px;
        line-height: 40px;
        width: 140px;
        text-align: center;
        cursor: pointer;
        border: 1px solid #E2E7F2;
        margin-bottom: 20px;
        border-radius: 5px;
        &:hover {
          background-color: #eee;
        }
      }
      .active {
        color: #77c5fe;
        background-color: #eee;
        border: 1px solid #77c5fe;
      }
    }

    .giftPage {
      width: 100%;
      text-align: right;
      height: 32px;
      line-height: 32px;
      border-top: 1px solid #E2E7F2;
      padding-top: 20px;
      margin: 20px 0;
      /deep/ .el-input__inner {
        background-color: #fff;
        border: 1px solid #dcdfe6;
        color: #606266;
        width: 100% !important;
        height: 28px;
      }
    }
    .prizeList {
      flex: 1;
      height: 500px;
    }
    // .noData {
    //   flex: 1;
    //   min-height: 250px;
    //   text-align: center;
    //   color: #ccc;
    // }
  }
</style>
