<template>
  <div class="giveThumbUp" :style="{backgroundImage:`url(${giveThumbUp.background.pictureUrl})`}">
    <div class="title" :style="{backgroundImage:`url(${giveThumbUp.title.pictureUrl})`}"></div>
    <div class="rulesBtn" :style="{backgroundImage:`url(${giveThumbUp.rulesBtn.pictureUrl})`}"></div>
    <div class="myPrize" :style="{backgroundImage:`url(${giveThumbUp.myPrize.pictureUrl})`}"></div>
    <div class="uploadPhoto" :style="{backgroundImage:`url(${giveThumbUp.uploadPhoto.pictureUrl})`}"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  },
  data () {
    return {
      giveThumbUp: {}
    }
  },
  created () {
    this.giveThumbUp = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.giveThumbUp
  }
}
</script>
<style lang="scss" scoped>
.giveThumbUp{
  height: 682px;
  background-size: 100% 100%;
  position: relative;
  *{
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: absolute;
  }
  .title{
    width: 70%;
    height: 130px;
    right: 0px;
    left: 0;
    margin: auto;
    top: 60px;
  }
  .rulesBtn,.myPrize{
    width: 30px;
    height: 100px;
    position: absolute;
    right: 0;
  }
  .rulesBtn{
    top: 40px;
  }
  .myPrize{
    top: 140px;
  }
  .uploadPhoto{
    width: 120px;
    height: 130px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 14px;
  }
}
</style>
