<template>
  <div class="jigsaw" :style="{backgroundImage:`url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.jigsaw.background.pictureUrl})`}">

  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData'])
  },
  mounted () {}
}
</script>

<style lang="scss" scoped>
.jigsaw{
  height: 682px;
  background-size: 100% 100%;
}
</style>
