<template>
	<div class="redpacketRain" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redpacketRain.background.pictureUrl})`}">
		<div class="countdown"  :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redpacketRain.countDown.pictureUrl})`}">倒计时：20s</div>
		<div class="redpacket" 
			v-for="i of 12"
			:key="i"
			:style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.redpacketRain.redpacket.pictureUrl})`}"></div>
	</div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
	...mapState('market',['marketingActivityData'])
  },
  mounted() {
  	console.log('this.marketingActivityData:',this.marketingActivityData)
  }
}
</script>
<style lang="scss" scoped>
	.redpacketRain{
		height: 684px;
		background-size: 100% auto;
		background-repeat: no-repeat;
		position: relative;
		.countdown{
			width: 150px;
			height: 50px;
			background-size: 100% auto;
			background-repeat: no-repeat;
			margin: auto;
			position: relative;
			top: 50px;
			text-align: center;
			line-height: 28px;
			color: #fff;
			padding-left: 25px;
			box-sizing: border-box;
			z-index: 20;
		}
		.redpacket{
			width: 30px;
			height: 52px;
			position: absolute;
			background-size: 100% auto;
			background-repeat: no-repeat;
			&:nth-of-type(2){
				left: 50px;
				top: 60px;
			}
			&:nth-of-type(3){
				left: 105px;
				top: 95px;
			}
			&:nth-of-type(4){
				left: 165px;
				top: 185px;
			}
			&:nth-of-type(5){
				left: 305px;
				top: 255px;
			}
			&:nth-of-type(6){
				left: 75px;
				top: 205px;
			}
			&:nth-of-type(7){
				left: 145px;
				top: 355px;
			}
			&:nth-of-type(8){
				left: 190px;
				top: 444px;
			}
			&:nth-of-type(9){
				left: 230px;
				top: 300px;
			}
			&:nth-of-type(10){
				left: 249px;
				top: 110px;
			}
			&:nth-of-type(11){
				left: 40px;
				top: 344px;
			}
			&:nth-of-type(12){
				left: 260px;
				top: 410px;
			}
			&:nth-of-type(13){
				left: 50px;
				top: 480px;
			}
			
			// @for $i from 2 through 13 {
			// 	&:nth-of-type(#{$i}){
			// 		@if($i < 6) {
			// 			left: $i * random() *10 + ($i * 60) + px;
			// 			top: $i * random() *10 + ($i * 15) + px;
			// 		} @else if($i < 10) {
			// 			left: $i * random() *10 + (($i - 4) * 60) + px;
			// 			top: $i * random() *10 + ($i * 15) + px;
			// 		} @else {
			// 			left: $i * random() *10 + (($i - 8) * 60) + px;
			// 			top: $i * random() *10 + ($i * 15) + px;
			// 		}
			// 	}
			// }
		}
	}
</style>
