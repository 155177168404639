var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "redpacketRain",
      style: {
        backgroundImage:
          "url(" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .redpacketRain.background.pictureUrl +
          ")"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "countdown",
          style: {
            backgroundImage:
              "url(" +
              JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
                .redpacketRain.countDown.pictureUrl +
              ")"
          }
        },
        [_vm._v("倒计时：20s")]
      ),
      _vm._l(12, function(i) {
        return _c("div", {
          key: i,
          staticClass: "redpacket",
          style: {
            backgroundImage:
              "url(" +
              JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
                .redpacketRain.redpacket.pictureUrl +
              ")"
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }