var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "activityShare" }, [
    _c("img", {
      staticClass: "report",
      attrs: {
        src:
          "" +
          JSON.parse(_vm.marketingActivityData.activity.templePage).drawPage
            .activityShare.report.pictureUrl
      }
    }),
    _c("div", { staticClass: "text" }, [
      _vm._v("长按或者截图保存图片分享给好友")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }