var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reportList" },
    [
      _c("div", { staticClass: "award-list" }, [
        _c(
          "ul",
          _vm._l(_vm.awardList, function(item, index) {
            return _c(
              "li",
              {
                key: index,
                class: { active: index == _vm.awardNum },
                on: {
                  click: function($event) {
                    return _vm.checkoutAward(index)
                  }
                }
              },
              [_vm._v("海报" + _vm._s(_vm.wordList[index]))]
            )
          }),
          0
        ),
        _c("div", { staticClass: "add-btn" }, [
          _c("i", { staticClass: "el-icon-minus", on: { click: _vm.sub } }),
          _c("i", { staticClass: "el-icon-plus", on: { click: _vm.add } })
        ])
      ]),
      _vm.marketingActivityData.posterDTOList.length
        ? _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.marketingActivityData.posterDTOList[_vm.awardNum],
                rules: _vm.rules,
                "label-width": "170px"
              }
            },
            [
              _c("el-form-item"),
              _c(
                "el-form-item",
                { attrs: { label: "海报名称：", prop: "posterName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入海报名称" },
                    model: {
                      value:
                        _vm.marketingActivityData.posterDTOList[_vm.awardNum]
                          .posterName,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.posterDTOList[_vm.awardNum],
                          "posterName",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.posterDTOList[awardNum].posterName"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "海报排序：", prop: "posterSort" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入海报序号" },
                    model: {
                      value:
                        _vm.marketingActivityData.posterDTOList[_vm.awardNum]
                          .posterSort,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.marketingActivityData.posterDTOList[_vm.awardNum],
                          "posterSort",
                          $$v
                        )
                      },
                      expression:
                        "marketingActivityData.posterDTOList[awardNum].posterSort"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "上传海报：", prop: "posterUrl" } },
                [
                  _c("upload-com", {
                    ref: "uploadCom",
                    attrs: { showImg: "showImg", sizeLimit: "200", unit: "k" },
                    on: { addImg: _vm.addReportImg }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }