var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-head" },
    [
      _c(
        "div",
        {
          staticClass: "logo",
          on: {
            click: function($event) {
              return _vm.$router.push("/")
            }
          }
        },
        [
          _c("img", {
            attrs: { src: require("../assets/img/editLogo.png"), alt: "" }
          })
        ]
      ),
      _c("div", { staticClass: "previewSave" }, [
        _c("div", { staticClass: "save" }, [
          _c(
            "div",
            {
              staticClass: "save-btn",
              on: {
                click: function($event) {
                  return _vm.save("save")
                }
              }
            },
            [
              _c("i", { staticClass: "iconfont icon-166991 icon-size" }),
              _c("div", { staticClass: "save-text" }, [_vm._v("保存")])
            ]
          )
        ]),
        _vm.isPreview
          ? _c("div", { staticClass: "printing" }, [
              _c(
                "span",
                { staticClass: "printing-text", on: { click: _vm.preview } },
                [_vm._v("预览和发布")]
              )
            ])
          : _vm._e()
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "preview",
          attrs: { title: "预览和发布", visible: _vm.visible, width: "40%" },
          on: {
            "update:visible": function($event) {
              _vm.visible = $event
            }
          }
        },
        [
          _c("div", { staticClass: "rightImg" }, [
            _c("img", {
              staticClass: "titleImg",
              attrs: {
                src: require("../assets/img/marketing/title.png"),
                alt: "",
                width: "100%"
              }
            }),
            _c("div", { staticClass: "title-name" }, [
              _c("i", { staticClass: "el-icon-close" }),
              _c("span", [
                _vm._v(_vm._s(_vm.marketingActivityData.activity.name))
              ]),
              _c("i", { staticClass: "el-icon-more" })
            ]),
            _vm.marketingActivityData.activity.templePage
              ? _c("img", {
                  staticClass: "contentImg",
                  attrs: {
                    src: JSON.parse(
                      _vm.marketingActivityData.activity.templePage
                    ).activityBase.cover,
                    alt: ""
                  }
                })
              : _vm._e(),
            _c("div", { staticClass: "codeImg" }, [
              _c(
                "div",
                { staticClass: "tryActive" },
                [
                  _c("active-qrcode", {
                    staticClass: "codeUrl",
                    attrs: { url: _vm.activityUrl, codeSize: "180" }
                  }),
                  _c("p", [_vm._v("扫二维码体验活动")])
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "leftCode" }, [
            _c("div", { staticClass: "code" }, [
              _c(
                "div",
                { staticClass: "codeImg" },
                [
                  _c("active-qrcode", {
                    staticClass: "codeUrl",
                    attrs: { url: _vm.activityUrl, codeSize: "180" }
                  }),
                  _c(
                    "p",
                    [
                      _vm._v(" 微信扫一扫 "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: { click: _vm.downloadCode }
                        },
                        [_vm._v("下载二维码")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "code-text" },
              [
                _c("p", [_vm._v("活动连接")]),
                _c("el-input", {
                  staticClass: "activeUrl",
                  model: {
                    value: _vm.activityUrl,
                    callback: function($$v) {
                      _vm.activityUrl = $$v
                    },
                    expression: "activityUrl"
                  }
                }),
                _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:error",
                        value: _vm.onError,
                        expression: "onError",
                        arg: "error"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:copy",
                        value: _vm.activityUrl,
                        expression: "activityUrl",
                        arg: "copy"
                      },
                      {
                        name: "clipboard",
                        rawName: "v-clipboard:success",
                        value: _vm.onCopy,
                        expression: "onCopy",
                        arg: "success"
                      }
                    ]
                  },
                  [_vm._v("复制")]
                ),
                _c(
                  "div",
                  { staticClass: "btn" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary" },
                        on: { click: _vm.publish }
                      },
                      [
                        _c("i", { staticClass: "el-icon-s-promotion" }),
                        _vm._v(" 马上发布 ")
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.visible = false
                          }
                        }
                      },
                      [
                        _c("i", { staticClass: "el-icon-edit-outline" }),
                        _vm._v(" 继续编辑 ")
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "确认发布", visible: _vm.visible3, width: "25%" },
          on: {
            "update:visible": function($event) {
              _vm.visible3 = $event
            }
          }
        },
        [
          _c("p", [
            _vm._v(
              " 活动开始时间: " +
                _vm._s(_vm.marketingActivityData.activity.beginTime) +
                " "
            )
          ]),
          _c("p", { staticClass: "activeTime" }, [
            _vm._v(
              " 活动结束时间: " +
                _vm._s(_vm.marketingActivityData.activity.endTime) +
                " "
            )
          ]),
          _c("p", { staticStyle: { "margin-left": "28px" } }, [
            _vm._v(
              " 活动名称: " +
                _vm._s(_vm.marketingActivityData.activity.name) +
                " "
            )
          ]),
          _c("p", { staticClass: "activeTips" }, [
            _vm._v("发布后奖项类型和生成券号将不能修改")
          ]),
          _c(
            "div",
            { staticClass: "activeBtn" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.getInfo } },
                [_vm._v("确认发布")]
              ),
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")])
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "绑定公众号", visible: _vm.visible1, width: "20%" },
          on: {
            "update:visible": function($event) {
              _vm.visible1 = $event
            }
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: { "text-align": "center", border: "1px solid #ccc" }
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "200px",
                    height: "200px",
                    "text-align": "center",
                    display: "inline-block"
                  }
                },
                [
                  _vm.qrcodeUrl
                    ? _c("img", {
                        staticClass: "qrcode",
                        attrs: { src: _vm.qrcodeUrl, alt: "", width: "200px" },
                        on: { click: _vm.refresh }
                      })
                    : _vm._e()
                ]
              ),
              _c("p", [_vm._v("微信扫一扫")]),
              _c("h6", { staticStyle: { margin: "10px 0" } }, [
                _vm._v("请先绑定无限立方公众号")
              ]),
              _c("p", [
                _vm._v(
                  "绑定后，可在微信中收取活动关键信息（参与人数、奖项数据）等"
                )
              ])
            ]
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "绑定手机",
            visible: _vm.visible2,
            width: "28%",
            center: ""
          },
          on: {
            "update:visible": function($event) {
              _vm.visible2 = $event
            }
          }
        },
        [
          _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
            _vm._v(
              " 为了您的账号安全，请绑定手机，同时该手机号也可以作为登录账号使用 "
            )
          ]),
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "phone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号码" },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入短信验证码" },
                            model: {
                              value: _vm.form.code,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "code", $$v)
                              },
                              expression: "form.code"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        [
                          _vm.isSend
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "sendCode",
                                  staticStyle: { width: "100%" },
                                  attrs: { type: "primary" },
                                  on: { click: _vm.sendCode }
                                },
                                [_vm._v("发送验证码")]
                              )
                            : _c(
                                "el-button",
                                {
                                  staticClass: "sendCode",
                                  staticStyle: { width: "100%" },
                                  attrs: { type: "primary", disabled: "" }
                                },
                                [_vm._v(_vm._s(this.countdown + "s"))]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("el-form-item", [
                _c(
                  "div",
                  { staticStyle: { "text-align": "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "toPhoneLogin",
                        attrs: { type: "primary", size: "medium" },
                        on: { click: _vm.toBind, keydown: _vm.toBind }
                      },
                      [_vm._v("立即绑定")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pay",
          attrs: { visible: _vm.visiblePay, "show-close": false },
          on: {
            "update:visible": function($event) {
              _vm.visiblePay = $event
            }
          }
        },
        [
          _c("div", { staticClass: "pay-header" }, [
            _c("div", { staticClass: "pay-title" }, [
              _vm._v("购买付费活动模板")
            ]),
            _c("div", { staticClass: "pay-tips" }, [
              _vm._v("一次购买，永久使用")
            ]),
            _c(
              "div",
              {
                staticClass: "cancel",
                on: {
                  click: function($event) {
                    _vm.visiblePay = false
                  }
                }
              },
              [_c("i", { staticClass: "el-icon-close" })]
            )
          ]),
          _vm.marketingActivityData.activity.templePage
            ? _c("div", { staticClass: "pay-info" }, [
                _c("ul", { staticClass: "pay-text" }, [
                  _c("li", { staticClass: "pay-item" }, [
                    _c("div", { staticClass: "pay-key" }, [_vm._v("模板类型")]),
                    _c("div", { staticClass: "pay-value" }, [
                      _vm._v(
                        _vm._s(
                          _vm.typeList[
                            _vm.marketingActivityData.activity.templeType
                          ]
                        )
                      )
                    ])
                  ]),
                  _c("li", { staticClass: "pay-item" }, [
                    _c("div", { staticClass: "pay-key" }, [_vm._v("模板名称")]),
                    _c("div", { staticClass: "pay-value" }, [
                      _vm._v(_vm._s(_vm.marketingActivityData.activity.name))
                    ])
                  ]),
                  _c("li", { staticClass: "pay-item" }, [
                    _c("div", { staticClass: "pay-key" }, [_vm._v("模板金额")]),
                    _c("div", { staticClass: "pay-value" }, [
                      _vm._v(
                        _vm._s(
                          parseFloat(
                            JSON.parse(
                              _vm.marketingActivityData.activity.templePage
                            ).activityBase.sellingPrice
                          ).toFixed(2) + "元"
                        )
                      )
                    ])
                  ]),
                  _c("li", { staticClass: "pay-item" }, [
                    _c("div", { staticClass: "pay-key" }, [_vm._v("优惠卷")]),
                    _c("div", { staticClass: "pay-value" }, [
                      _c("span", { staticClass: "pay-coupon" }, [_vm._v("无")]),
                      _c("i", { staticClass: "el-icon-arrow-right" })
                    ])
                  ]),
                  _c("li", { staticClass: "pay-item" }, [
                    _c("div", { staticClass: "pay-real" }, [
                      _vm._v("实付金额")
                    ]),
                    _c("div", { staticClass: "pay-num" }, [
                      _vm._v(
                        _vm._s(
                          parseFloat(_vm.paymentInfo.payAmount).toFixed(2) +
                            "元"
                        )
                      )
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "code" }, [
            _c("p", [_vm._v("请使用微信扫码支付")]),
            _c(
              "div",
              { staticClass: "code-content" },
              [
                _c("active-qrcode", {
                  attrs: { url: _vm.paymentInfo.codeUrl, codeSize: "136" }
                })
              ],
              1
            ),
            _c(
              "ul",
              { staticClass: "btn" },
              _vm._l(_vm.payWayList, function(item, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "btn-item",
                    class: { active: index == _vm.current },
                    on: {
                      click: function($event) {
                        return _vm.setPayWay(index)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item))]
                )
              }),
              0
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }