<template>
<div class="rotateEgg">
</div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('market', ['marketingActivityData', 'cover'])
  },
  data () {
    return {
      rotateEgg: {}
    }
  },
  created () {
    this.rotateEgg = JSON.parse(this.marketingActivityData.activity.templePage).drawPage.rotateEgg
  }
}
</script>

<style lang="scss" scoped>
.rotateEgg{
  height: 682px;
  background-image: url("../../../assets/img/marketing/ratateEgg/pic.png");
  background-size: 100% 100%;
  position: relative;
}
</style>
