<template>
  <!-- 小兔兔奔月记 -->
  <div class="rushes" :style="{'backgroundImage': `url(${JSON.parse(marketingActivityData.activity.templePage).drawPage.rushes.background.pictureUrl})`}">
    <div class="rushes-title">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.rushes.title.pictureUrl" alt="">
      <span class="time" v-if="marketingActivityData.activity.beginTime && marketingActivityData.activity.endTime">
        {{ 
          `${marketingActivityData.activity.beginTime.split(/[-' ']/)[1]}月${marketingActivityData.activity.beginTime.split(/[-' ']/)[2]}日 -
          ${marketingActivityData.activity.endTime.split(/[-' ']/)[1]}月${marketingActivityData.activity.endTime.split(/[-' ']/)[2]}日`
        }}
      </span>
    </div>
    <div class="rushes-start">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.rushes.start.pictureUrl" alt="">
    </div>
    <div class="rushes-secret">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.rushes.secret.pictureUrl" alt="">
    </div>
    <div class="rushes-animal">
      <img :src="JSON.parse(marketingActivityData.activity.templePage).drawPage.rushes.animal.pictureUrl" alt="">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed:{
    ...mapState('market',['marketingActivityData'])
  },
}
</script>

<style lang="scss" scoped>
  .rushes {
    position: relative;
    width: 100%;
    height: 615px;
    background-size: 100% 100%;
    margin-top: 22px;
    .rushes-title {
      width: 370px;
      img {
        width: 100%;
      }
    }
    .time {
      position: absolute;
      color: #fff9db;
      top: 265px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 700;
    }
    .rushes-start {
      width: 100%;
      text-align: center;
      img {
        width: 80%;
      }
    }
    .rushes-secret {
      position: absolute;
      left: 0;
      top: 65%;
      img {
        width: 80px;
      }
    }
    .rushes-animal {
      position: absolute;
      right: 8%;
      top: 70%;
      img {
        width: 80px;
      }
    }
  }
</style>